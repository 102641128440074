import React from "react";

// Styled components
import styled from "styled-components";

// Font size helpers
import fontSize from "../../lib/styling/font_size";

// Icons & Images
import info_icon from "../../assets/icons/info_icon.png";

function AlertTextBox(props) {
  return (
    <TextBox bgcolor={props.bgcolor}>
      <TextBoxIcon src={info_icon} width="20px" height="20px" alt="info_icon" />
      <TextBoxTitle>{props.title}</TextBoxTitle>
      <TextBoxText>
        {props.text.map((text, i) => {
          return <span key={i}>{text}</span>;
        })}
      </TextBoxText>
      <TextBoxText>{props.button}</TextBoxText>
    </TextBox>
  );
}

export default AlertTextBox;

const TextBox = styled.div`
  background-color: ${({ bgcolor }) => bgcolor || "#D7FFFF"};
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 30px;
  gap: 0.5vw;
`;
const TextBoxIcon = styled.img`
  object-fit: contain;
  margin-bottom: 10px;
`;
const TextBoxTitle = styled.p`
  font-size: ${fontSize.title3};
  font-weight: 800;
  margin-bottom: 5px;
`;
const TextBoxText = styled.p`
  font-size: ${fontSize.title3};
  font-weight: 500;
`;
