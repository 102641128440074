import React, { useEffect, useState } from "react";

// helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";
import getPdfCoverImage from "../helpers/get-pdf-cover-image";

const CourseRecap = ({ eachLesson, isEditPage }) => {
  const {
    lesson,
    duration_in_seconds,
    content_type,
    content_value: { content_to_save, content_to_view } = {},
  } = eachLesson || {};

  const [articlePreview, setArticlePreview] = useState(null);

  let durationValue = getTimeFromSeconds(duration_in_seconds);

  const getArticlePreview = async (file, fileUrl) => {
    const preview = await getPdfCoverImage(file, fileUrl);
    await setArticlePreview(preview);
  };

  useEffect(() => {
    if (content_type === "article") {
      if (content_to_save) {
        getArticlePreview(content_to_save);
      } else {
        if (content_to_view && content_to_view["url"]) {
          getArticlePreview(null, content_to_view["url"]);
        }
      }
    }
  }, [content_to_view, content_to_save, lesson["content_type"]]);

  return (
    <div className="showLessonImageContainer">
      {content_type &&
      content_type === "article" &&
      (content_to_save || articlePreview) ? (
        <div className="showLessonImageContainerImage">
          <div
            style={{
              backgroundColor: "#00bb99",
              width: "384px",
              height: "254px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
            }}
          >
            <img
              src={articlePreview}
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                maxWidth: "384px",
                maxHeight: "254px",
                objectFit: "cover",
              }}
              alt="courseImage"
            />
          </div>
        </div>
      ) : content_type &&
        content_type === "video" &&
        content_to_view &&
        content_to_view.url ? (
        <div className="showLessonImageContainerImage">
          <video
            style={{
              width: "384px",
              height: "254px",
              objectFit: "cover",
              borderRadius: "15px",
            }}
            controls={true}
          >
            <source
              src={content_to_view && content_to_view["url"]}
              type="video/mp4"
            />
          </video>
        </div>
      ) : null}
      <div className="showLessonImageContainerLessonName">
        {lesson ? lesson.charAt(0).toUpperCase() + lesson.slice(1) : ""}
      </div>
      {content_type &&
      content_type === "video" &&
      ((!isEditPage && content_to_save && content_to_view) ||
        (isEditPage && content_to_view)) ? (
        <div className="showLessonImageContainerLessonDuration">
          {`${
            durationValue.hours
              ? `${
                  durationValue.hours > 1
                    ? `${durationValue.hours} hours`
                    : `${durationValue.hours} hour`
                }`
              : ``
          } ${
            durationValue.minutes
              ? `${
                  durationValue.minutes > 1
                    ? `${durationValue.minutes} mins`
                    : `${durationValue.minutes} min`
                }`
              : ``
          } ${
            !durationValue.hours && durationValue.seconds
              ? `${
                  durationValue.seconds > 1
                    ? `${durationValue.seconds} secs`
                    : `${durationValue.seconds} sec`
                }`
              : ``
          }`}
        </div>
      ) : content_type &&
        content_type === "article" &&
        content_to_save &&
        content_to_view ? (
        <div className="showLessonImageContainerLessonDuration">
          {(content_to_view && content_to_view.fileName) || ""}
        </div>
      ) : null}
    </div>
  );
};

export default CourseRecap;
