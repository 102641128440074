import React from "react";
import styled from "styled-components";

function InternalDropdown({
  isOpen,
  approveHandler,
  declineHandler,
  approveText,
  declineText,
}) {
  return (
    <>
      {isOpen && (
        <DropdownContainer>
          <Option onClick={approveHandler}>{approveText}</Option>
          <Option onClick={declineHandler}>{declineText}</Option>
        </DropdownContainer>
      )}
    </>
  );
}

export default InternalDropdown;

const DropdownContainer = styled.div`
  position: absolute;
  margin-top: 0.5em;
  background: white;
  width: 100%;
  max-width: 190px;
  z-index: 9999;
  border: 1px solid #00bb99;
  border-radius: 10px;
`;

const Option = styled.p`
  margin: 1em 1em;
  transition: 0.3s ease;
  &:hover {
    font-weight: 700;
    font-size: 14.5px;
  }
`;
