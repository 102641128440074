module.exports = {
  heading1Sm: "25px",
  heading1Lg: "50px",
  heading2Sm: "20px",
  heading2Lg: "35px",
  heading3Sm: "17px",
  heading3Lg: "30px",
  heading4Sm: "14px",
  heading4Lg: "28px",
  bodySm: "15px",
  bodyLg: "20px",
};
