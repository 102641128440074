import Axios from "../../helpers/axios";

export const getAllSessions = () => {
  return Axios.get("/sessions");
};

export const getDetailInternalBooking = (booking_id) => {
  return Axios.get(`/sessions/${booking_id}`);
};

export const updateSession = (session_id, body) => {
  return Axios.patch(`/sessions/${session_id}`, body);
};
