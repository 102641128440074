import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { useMediaQuery } from "react-responsive";
import { Animated } from "react-animated-css";
import VisibilitySensor from "react-visibility-sensor";

// import HeadMeta from "../components/HeadMeta";
import SectionHeading from "../components/headings/SectionHeading";

// import CloudxierLogoSquare from "../assets/images/logo/Cloudxier-logofull-colored-05.png";

import fontSize from "../helpers/fontSize";
import { device } from "../helpers/device";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

function TermsOfService() {
  const navigate = useNavigate();
  const smallScreen = useMediaQuery({ query: "(max-width: 767px)" });
  const mediumScreen = useMediaQuery({ query: "(max-width: 1023px)" });

  const [isContent1Visible, setContent1Visible] = useState(false);
  const [isContent2Visible, setContent2Visible] = useState(false);
  const [isContent3Visible, setContent3Visible] = useState(false);
  const [isContent4Visible, setContent4Visible] = useState(false);
  const [isContent5Visible, setContent5Visible] = useState(false);
  const [isContent6Visible, setContent6Visible] = useState(false);
  const [isContent7Visible, setContent7Visible] = useState(false);
  const [isContent8Visible, setContent8Visible] = useState(false);
  const [isContent9Visible, setContent9Visible] = useState(false);
  const [isContent10Visible, setContent10Visible] = useState(false);
  const [isContent11Visible, setContent11Visible] = useState(false);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="loginRegisterContainer">
      <div className="loginRegisterAlertMainContainer">
        <Card
          style={{
            width: "55vw",
            minHeight: "75vh",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "32px 0",
            marginTop: "40px",
          }}
        >
          <MainContainer>
            <Container
              style={{
                padding: smallScreen
                  ? "0 5vw 30px 5vw"
                  : mediumScreen
                  ? "0 5vw 50px 5vw"
                  : "0 5vw 50px 5vw",
              }}
            >
              <div>
                <BackNavigation onClick={() => navigate(-1)}>
                  <img
                    src={arrow_left_icon_dark}
                    alt="arrow_left_icon_dark"
                    height={15}
                    width={15}
                    style={{
                      objectFit: "scale-down",
                      objectPosition: "left bottom",
                    }}
                  />
                  {`    Back`}
                </BackNavigation>
              </div>
              <VisibilitySensor
                onChange={(isVisible) => setContent1Visible(isVisible)}
                active={!isContent1Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInDown"
                  animationInDuration={2000}
                  isVisible={isContent1Visible}
                >
                  <SectionHeading title="Terms of Service" subtitle="" />
                  <BodyText>
                    Welcome to WhatsApp Business Technical Provider's Terms of
                    Service. Please read these terms carefully before using our
                    services.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent2Visible(isVisible)}
                active={!isContent2Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInLeft"
                  animationInDuration={2000}
                  isVisible={isContent2Visible}
                >
                  <Heading>Acceptance of Terms</Heading>
                  <BodyText>
                    By accessing or using WhatsApp Business Technical Provider's
                    services, you agree to be bound by these Terms of Service.
                    If you do not agree with any part of these terms, you may
                    not use our services.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent3Visible(isVisible)}
                active={!isContent3Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDuration={2000}
                  isVisible={isContent3Visible}
                >
                  <Heading>Description of Service</Heading>
                  <BodyText>
                    WhatsApp Business Technical Provider offers technical
                    support and solutions for businesses utilizing WhatsApp
                    Business API. Our services include but are not limited to
                    troubleshooting, maintenance, and integration assistance.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent4Visible(isVisible)}
                active={!isContent4Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInLeft"
                  animationInDuration={2000}
                  isVisible={isContent4Visible}
                >
                  <Heading>User Responsibilities</Heading>
                  <BodyText>
                    Users are responsible for maintaining the confidentiality of
                    their account credentials and for all activities that occur
                    under their account. Users must not use our services for any
                    unlawful or unauthorized purposes.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent5Visible(isVisible)}
                active={!isContent5Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDuration={2000}
                  isVisible={isContent5Visible}
                >
                  <Heading>Service Limitations</Heading>
                  <BodyText>
                    While we strive to provide reliable technical support,
                    WhatsApp Business Technical Provider does not guarantee
                    uninterrupted or error-free services. Users acknowledge that
                    our services may be subject to limitations or disruptions
                    beyond our control.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent6Visible(isVisible)}
                active={!isContent6Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInLeft"
                  animationInDuration={2000}
                  isVisible={isContent6Visible}
                >
                  <Heading>Privacy Policy</Heading>
                  <BodyText>
                    Our Privacy Policy governs the collection, use, and
                    disclosure of personal information provided by users. By
                    using our services, you consent to the terms outlined in our{" "}
                    <span
                      onClick={() => navigate(`/privacy-policy`)}
                      style={{ color: "blue", cursor: "pointer" }}
                    >
                      Privacy Policy.
                    </span>
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent7Visible(isVisible)}
                active={!isContent7Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDuration={2000}
                  isVisible={isContent7Visible}
                >
                  <Heading>Intellectual Property</Heading>
                  <BodyText>
                    All content and materials provided through WhatsApp Business
                    Technical Provider's services are the property of WhatsApp
                    Business Technical Provider and are protected by
                    intellectual property laws. Users may not reproduce,
                    distribute, or modify any content without prior written
                    consent.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent8Visible(isVisible)}
                active={!isContent8Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInLeft"
                  animationInDuration={2000}
                  isVisible={isContent8Visible}
                >
                  <Heading>Payment and Billing</Heading>
                  <BodyText>
                    Users agree to pay all fees associated with the services
                    provided by WhatsApp Business Technical Provider. Fees are
                    payable in accordance with the terms specified in our
                    billing agreements.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent9Visible(isVisible)}
                active={!isContent9Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDuration={2000}
                  isVisible={isContent9Visible}
                >
                  <Heading>Termination</Heading>
                  <BodyText>
                    WhatsApp Business Technical Provider reserves the right to
                    terminate or suspend access to our services at any time,
                    with or without cause. Upon termination, users must cease
                    all use of our services and may be subject to additional
                    terms and conditions.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent10Visible(isVisible)}
                active={!isContent10Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInLeft"
                  animationInDuration={2000}
                  isVisible={isContent10Visible}
                >
                  <Heading>Modification of Terms</Heading>
                  <BodyText>
                    WhatsApp Business Technical Provider reserves the right to
                    modify these Terms of Service at any time. Users will be
                    notified of any changes, and continued use of our services
                    constitutes acceptance of the modified terms.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
              <VisibilitySensor
                onChange={(isVisible) => setContent11Visible(isVisible)}
                active={!isContent11Visible}
                partialVisibility={true}
              >
                <Animated
                  animationIn="fadeInRight"
                  animationInDuration={2000}
                  isVisible={isContent11Visible}
                >
                  <Heading>Contact Us</Heading>
                  <BodyText>
                    If you have any questions or concerns about these Terms of
                    Service, please contact us at sayhello@cloudxier.com.
                  </BodyText>
                </Animated>
              </VisibilitySensor>
            </Container>
          </MainContainer>
        </Card>
      </div>
    </div>
  );
}

export default TermsOfService;

const MainContainer = styled.div`
  overflow: hidden;
  margin-top: 10px;
  background-color: #ffffff;
  @media ${device.tablet} {
    margin-top: 20px;
  }
  @media ${device.laptop} {
    margin-top: 20px;
  }
  @media ${device.laptopM} {
    margin-top: 40px;
  }
  @media ${device.laptopL} {
    margin-top: 40px;
  }
`;

const Heading = styled.div`
  font-size: ${fontSize.bodyLg};
  color: black;
  font-weight: 700;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 12px;
  @media ${device.tablet} {
    font-size: ${fontSize.heading1Sm};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.heading3Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
  @media ${device.laptopL} {
    font-size: ${fontSize.heading2Lg};
    margin-top: 48px;
    margin-bottom: 24px;
  }
`;

const BodyText = styled.p`
  text-align: justify;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 8px;
  @media ${device.tablet} {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptop} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopM} {
    font-size: ${fontSize.bodyLg};
    margin-top: 32px;
    margin-bottom: 16px;
  }
  @media ${device.laptopL} {
    font-size: 22px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
`;

const BackNavigation = styled.p`
  text-align: left;
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;
