function getTimeFromSeconds(totalSeconds) {
  // Calculate hours, minutes, and seconds
  var hours = Math.floor(totalSeconds / 3600);
  var remainingSeconds = totalSeconds % 3600;
  var minutes = Math.floor(remainingSeconds / 60);
  var seconds = remainingSeconds % 60;

  // Return the result as an object
  return { hours: hours, minutes: minutes, seconds: seconds };
}

export default getTimeFromSeconds;
