import React from 'react'
import Tippy from '@tippyjs/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai'
import 'tippy.js/dist/tippy.css';

function Tooltip({
   placement = 'right',
   content,
}) {
   return (
      <Tippy
         placement={placement}
         content={content}
      >
         <span
            style={{
               display: 'inline-flex',
               alignItems: 'center'
            }}
         >
            <AiOutlineQuestionCircle />
         </span>
      </Tippy>
   )
}

export default Tooltip;