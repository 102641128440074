import React, { useEffect } from "react";
import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

function ModalComponent({
  show,
  handleClose = () => {},
  title,
  subtitle,
  body,
  buttonPrimary,
  buttonSecondary,
  closeButton = true,
  onPrimaryClick,
  onSecondaryClick,
  maxWidth,
  bodyTextAlign = "center",
  buttonDirection = "row",
  primaryColor = "#00BB99",
  secondaryColor = "#AAAAAA",
  primaryTextColor = "#FFFFFF",
  secondaryTextColor = "#FFFFFF",
}) {
  useEffect(() => {
    // Max width render conditional
    let modalDialog = document.getElementsByClassName(
      "modal-dialog modal-dialog-centered"
    )[0];
    if (modalDialog) {
      modalDialog.style.maxWidth = maxWidth;
    }
  });

  return (
    <>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>
            {title}
            <h5>{subtitle}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: bodyTextAlign }}>
          {body?.map((bod, index) => (
            <span key={index}>{bod}</span>
          ))}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: buttonDirection,
          }}
        >
          {buttonSecondary && (
            <ButtonSecondary
              textColor={secondaryTextColor}
              color={secondaryColor}
              onClick={onSecondaryClick}
            >
              {buttonSecondary}
            </ButtonSecondary>
          )}
          {buttonPrimary && (
            <ButtonPrimary
              textColor={primaryTextColor}
              color={primaryColor}
              onClick={onPrimaryClick}
            >
              {buttonPrimary}
            </ButtonPrimary>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComponent;

const ButtonPrimary = styled.button`
  color: white;
  background-color: ${({ color }) => color};
  border-radius: 40px;
  border: none;
  font-weight: bold;
  :hover {
    background-color: ${({ color }) => color};
    opacity: 0.8;
  }
  color: ${({ textColor }) => textColor};
  padding: 0.5rem 1rem;
`;
const ButtonSecondary = styled.button`
  background-color: ${({ color }) => color};
  color: white;
  border-radius: 40px;
  border: none;
  font-weight: bold;
  :hover {
    background-color: ${({ color }) => color};
    opacity: 0.8;
  }
  color: ${({ textColor }) => textColor};
  padding: 0.5rem 1rem;
`;
