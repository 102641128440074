import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import styled from "styled-components";

// react-collapsible
import Collapsible from "react-collapsible";

// react bootstrap form
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// lib
import fontSize from "../lib/styling/font_size";

// images & icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";
import dropdown_green_icon from "../assets/icons/dropdown_green_icon.svg";
import upload_file_icon from "../assets/icons/upload_file_icon.svg";
import info_icon from "../assets/icons/info_icon.svg";

// styles
import "../styles/formCourse.css";

// sweetalert
import Swal from "sweetalert2";

// apis
import { getAllMentors } from "../apis/clientApis/mentors";
import { addTag, getAllTags } from "../apis/tags";
import { saveFileToServer, saveFileVideoToServer } from "../apis/files";
import {
  adminCreateNewCourse,
  adminEditCourse,
} from "../apis/internalApis/courses";
import { getDetailCourse } from "../apis/courses";

// helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";

// components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import DropdownMultipleValue from "../components/input/DropdownMultipleValue";
import TextEditor from "../components/input/TextEditor";
import InputCourseSection from "../components/input/Course/InputCourseSection";
import CourseRecap from "../components/CourseRecap";
import UploadingVideoProgressBar from "../components/UploadingVideoProgressBar";
import UploadingFileToServerModal from "../components/UploadingFileToServerModal";

// reducers
import { SET_ACCORDION_FORM } from "../store/actions";

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const CoursesForm = () => {
  const forceUpdate = useForceUpdate();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { course_id } = useParams();

  const cancelUpload = useRef(false);

  const courseFormAccordion1Value = useSelector(
    (state) => state.courseFormAccordion1Value
  );
  const courseFormAccordion2Value = useSelector(
    (state) => state.courseFormAccordion2Value
  );
  const courseFormAccordion3Value = useSelector(
    (state) => state.courseFormAccordion3Value
  );
  const courseFormAccordion4Value = useSelector(
    (state) => state.courseFormAccordion4Value
  );

  // dropdown options
  const [courseLevelOptions, setCourseLevelOptions] = useState([
    { name: "level 1", value: "level 1" },
    { name: "level 2", value: "level 2" },
    { name: "level 3", value: "level 3" },
  ]);

  const [courseLengthOptions, setCourseLengthOptions] = useState([
    { name: "length 1", value: "length 1" },
    { name: "length 2", value: "length 2" },
    { name: "length 3", value: "length 3" },
  ]);

  const [courseCategoryOptions, setCourseCategoryOptions] = useState([
    { name: "category 1", value: "category 1" },
    { name: "category 2", value: "category 2" },
    { name: "category 3", value: "category 3" },
  ]);

  const [courseLanguageOptions, setCourseLanguageOptions] = useState([
    { name: "language 1", value: "language 1" },
    { name: "language 2", value: "language 2" },
    { name: "language 3", value: "language 3" },
  ]);

  const [courseSubCategoryOptions, setCourseSubCategoryOptions] = useState([
    { name: "sub-category 1", value: "sub-category 1" },
    { name: "sub-category 2", value: "sub-category 2" },
    { name: "sub-category 3", value: "sub-category 3" },
  ]);

  const options = [
    { name: "opt1", value: "opt1" },
    { name: "opt2", value: "opt2" },
    { name: "opt3", value: "opt3" },
  ];

  const createableOptions = [
    { label: "opt1", value: "opt1" },
    { label: "opt2", value: "opt2" },
    { label: "opt3", value: "opt3" },
  ];

  const [tagOptions, setTagOptions] = useState([]);

  const [isLoading, setLoading] = useState(false);
  const [isOpenAccordion, setIsOpenAccordion] = useState({
    accordion_1: true,
    accordion_2: false,
    accordion_3: false,
    accordion_4: false,
  });

  // input accordion 1 state list
  const [courseType, setCourseType] = useState("");
  const [courseLevel, setCourseLevel] = useState(null);
  const [courseLength, setCourseLength] = useState(null);
  const [courseCategory, setCourseCategory] = useState(null);
  const [courseLanguage, setCourseLanguage] = useState(null);
  const [courseSubCategory, setCourseSubCategory] = useState(null);

  // error input validation accordion 1 list
  const [invalidCourseType, setInvalidCourseType] = useState(false);
  const [invalidCourseLevel, setInvalidCourseLevel] = useState(false);
  const [invalidCourseLength, setInvalidCourseLength] = useState(false);
  const [invalidCourseCategory, setInvalidCourseCategory] = useState(false);
  const [invalidCourseLanguage, setInvalidCourseLanguage] = useState(false);
  const [invalidCourseSubCategory, setInvalidCourseSubCategory] =
    useState(false);

  // input accordion 2 state list
  const [courseName, setCourseName] = useState("");
  const [courseSummary, setCourseSummary] = useState("");
  const [courseImageToView, setCourseImageToView] = useState(null);
  const [courseImageToSave, setCourseImageToSave] = useState(null);
  const [
    showCourseVideoHighlightProgressBar,
    setShowCourseVideoHighlightProgressBar,
  ] = useState(false);
  const [courseVideoHighlightToView, setCourseVideoHighlightToView] =
    useState(null);
  const [courseVideoHighlightToSave, setCourseVideoHighlightToSave] =
    useState(null);
  const [whatWillYouLearnInThisCourse, setWhatWillYouLearnInThisCourse] =
    useState(null);
  const [targetAudience, setTargetAudience] = useState(null);
  const [additionalDescription, setAdditionalDescription] = useState(null);
  const [tags, setTags] = useState([]);

  // error input validation accordion 2 list
  const [invalidCourseName, setInvalidCourseName] = useState(false);
  const [invalidCourseSummary, setInvalidCourseSummary] = useState(false);
  const [invalidCourseImage, setInvalidCourseImage] = useState(false);
  const [invalidCourseImageMsg, setInvalidCourseImageMsg] = useState("");
  const [invalidCourseVideoHighlight, setInvalidCourseVideoHighlight] =
    useState(false);
  const [invalidCourseVideoHighlightMsg, setInvalidCourseVideoHighlightMsg] =
    useState("");
  const [
    invalidWhatWillYouLearnInThisCourse,
    setInvalidWhatWillYouLearnInThisCourse,
  ] = useState(false);
  const [invalidTargetAudience, setInvalidTargetAudience] = useState(false);
  const [invalidAdditionalDescription, setInvalidAdditionalDescription] =
    useState(false);
  const [invalidTags, setInvalidTags] = useState(false);

  const [courseImageFailedToUploadMsg, setCourseImageFailedToUploadMsg] =
    useState("");
  const [
    courseVideoHighlightFailedToUploadMsg,
    setCourseVideoHighlightFailedToUploadMsg,
  ] = useState("");

  const [loadingUploadFile, setLoadingUploadFile] = useState(false);

  // input accordion 3 state list
  const [courseSections, setCourseSections] = useState([]);
  const [courseSectionCounter, setCourseSectionCounter] = useState([0]);

  // input accordion 4 state list
  const [mentorOptions, setMentorOptions] = useState([]);
  const [assignInstructor, setAssignInstructor] = useState(null);

  const [totalCourseDuration, setTotalCourseDuration] = useState(null);
  const [totalCourseSections, setTotalCourseSections] = useState(0);
  const [totalLessons, setTotalLessons] = useState(0);

  // error input validation accordion 4 list
  const [invalidAssignInstructor, setInvalidAssignInstructor] = useState(false);

  // uploading file to server state
  const [isOpenUploadFileToServer, setIsOpenUploadFileToServer] =
    useState(false);
  const [filesToUploadToServer, setFilesToUploadToServer] = useState([]);
  const [fileUploadedToServerCount, setFileUploadedToServerCount] =
    useState(null);

  const cancelUploadVideo = () => {
    cancelUpload.current = true;
  };

  function uploadVideo(
    file,
    keyName,
    sectionIndex,
    lessonIndex,
    finalArrFileToUploadToServer,
    fileToUploadToServerIndex
  ) {
    let finalCourseSections = courseSections;
    let finalFilesToUploadToServerToView = finalArrFileToUploadToServer;

    let fileSizeInMb = Math.floor(file.size / (1024 * 1024));
    let objVideoToView;

    let loaded = 0;
    let chunkSize = 5 * 1024 * 1024; // 5 MB
    let total = file.size;
    let reader = new FileReader();
    let slice = file.slice(0, chunkSize);

    // Reading a chunk to invoke the 'onload' event
    reader.readAsBinaryString(slice);

    objVideoToView = {
      fileName: file.name,
      fileSize: fileSizeInMb,
    };

    if (keyName === "courseVideoHighlight") {
      setShowCourseVideoHighlightProgressBar(true);
      setCourseVideoHighlightToView(objVideoToView);
    }
    if (keyName === "lesson") {
      // lesson video here
      finalCourseSections = finalCourseSections.map(
        (courseSection, sectionIdx) => {
          if (sectionIdx === sectionIndex) {
            let arrLessons = courseSection["lessons"];
            arrLessons = arrLessons.map((lesson, lessonIdx) => {
              if (lessonIdx === lessonIndex) {
                lesson["show_progress_bar"] = true;
                lesson["content_value"] = {
                  ...lesson["content_value"],
                  content_to_view: objVideoToView,
                };
              }
              return lesson;
            });
          }
          return courseSection;
        }
      );
      setCourseSections(finalCourseSections);
      forceUpdate();
    }
    if (keyName === "uploadVideoToServer") {
      handleSaveFileVideoToServer(
        file,
        finalFilesToUploadToServerToView,
        fileToUploadToServerIndex
      );
    }

    reader.onloadstart = function (e) {
      if (cancelUpload && cancelUpload.current) {
        reader.abort();
      }

      reader.onabort = function (e) {
        cancelUpload.current = false;
        if (keyName === "courseVideoHighlight") {
          setShowCourseVideoHighlightProgressBar(false);
          setCourseVideoHighlightToView(null);
          setCourseVideoHighlightToSave(null);
        }
        if (keyName === "uploadVideoToServer") {
          finalFilesToUploadToServerToView =
            finalFilesToUploadToServerToView.map((file, idx) => {
              if (fileToUploadToServerIndex === idx) {
                file["isUploaded"] = false;
                file["isCanceled"] = true;
              }
              return file;
            });
          setFilesToUploadToServer(finalFilesToUploadToServerToView);
          forceUpdate();
        }
        if (keyName === "lesson") {
          finalCourseSections = finalCourseSections.map(
            (courseSection, sectionIdx) => {
              if (sectionIdx === sectionIndex) {
                let arrLessons = courseSection["lessons"];
                arrLessons = arrLessons.map((lesson, lessonIdx) => {
                  if (lessonIdx === lessonIndex) {
                    lesson["content_value"] = {
                      content_to_save: null,
                      content_to_view: null,
                    };
                  }
                  return lesson;
                });
              }
              return courseSection;
            }
          );
          setCourseSections(finalCourseSections);
          forceUpdate();
        }
      };

      reader.onload = function (e) {
        loaded += chunkSize;
        let percentLoaded = Math.min((loaded / total) * 100, 100);
        percentLoaded = Math.floor(percentLoaded);

        objVideoToView = {
          ...objVideoToView,
          filePercentageLoaded: percentLoaded,
        };

        if (keyName === "courseVideoHighlight") {
          setCourseVideoHighlightToView(objVideoToView);
        }
        if (keyName === "uploadVideoToServer") {
          finalFilesToUploadToServerToView =
            finalFilesToUploadToServerToView.map((file, idx) => {
              if (fileToUploadToServerIndex === idx) {
                file = {
                  ...file,
                  filePercentageLoaded: percentLoaded,
                };
              }
              return file;
            });
          setFilesToUploadToServer(finalFilesToUploadToServerToView);
          forceUpdate();
        }
        if (keyName === "lesson") {
          finalCourseSections = finalCourseSections.map(
            (courseSection, sectionIdx) => {
              if (sectionIdx === sectionIndex) {
                let arrLessons = courseSection["lessons"];
                arrLessons = arrLessons.map((lesson, lessonIdx) => {
                  if (lessonIdx === lessonIndex) {
                    lesson["content_value"] = {
                      ...lesson["content_value"],
                      content_to_view: objVideoToView,
                    };
                  }
                  return lesson;
                });
              }
              return courseSection;
            }
          );
          setCourseSections(finalCourseSections);
          forceUpdate();
        }

        //Read the next chunk and call 'onload' event again
        if (loaded <= total) {
          slice = file.slice(loaded, loaded + chunkSize);
          reader.readAsBinaryString(slice);
        } else {
          cancelUpload.current = false;
          loaded = total;
          if (keyName === "courseVideoHighlight") {
            setShowCourseVideoHighlightProgressBar(false);
            setCourseVideoHighlightToSave(file);
            setCourseVideoHighlightToView({
              ...objVideoToView,
              fileKeyName: keyName,
              url: URL.createObjectURL(file),
            });
          }
          if (keyName === "lesson") {
            finalCourseSections = finalCourseSections.map(
              (courseSection, sectionIdx) => {
                if (sectionIdx === sectionIndex) {
                  let arrLessons = courseSection["lessons"];
                  arrLessons = arrLessons.map((lesson, lessonIdx) => {
                    if (lessonIdx === lessonIndex) {
                      lesson["content_value"] = {
                        content_to_save: file,
                        content_to_view: {
                          ...objVideoToView,
                          url: URL.createObjectURL(file),
                          sectionIndex,
                          lessonIndex,
                        },
                      };
                    }
                    return lesson;
                  });
                }
                return courseSection;
              }
            );
            setCourseSections(finalCourseSections);
            forceUpdate();
          }
        }
      };
    };
  }

  const handleOnInputChange = (value, setter, inputType, isCreateable) => {
    if (
      inputType === "text" ||
      inputType === "dropdown" ||
      inputType === "textarea" ||
      inputType === "text-editor"
    ) {
      // handle input text, dropdown, textarea & text-editor
      if (inputType === "dropdown" && isCreateable) {
        //  handle input createable dropdown
        const isSameProperty = (val, tagOptions) =>
          val.value === tagOptions.value;
        const onlyInLeft = (left, right, compareFunction) =>
          left.filter(
            (leftValue) =>
              !right.some((rightValue) =>
                compareFunction(leftValue, rightValue)
              )
          );
        const onlyInVal = onlyInLeft(value, tagOptions, isSameProperty);
        const onlyInTagOptions = onlyInLeft(tagOptions, value, isSameProperty);
        const result = [...onlyInVal, ...onlyInTagOptions];
        let arrTagOptions = tagOptions;
        result.forEach(async (el) => {
          if (el["__isNew__"]) {
            const { label, value } = el || {};
            const createdTag = await addTag({ tag_name: value });
            if (createdTag && createdTag.status === 201 && createdTag.data) {
              arrTagOptions.push({ label, value, id: createdTag.data.id });
              setTagOptions(arrTagOptions);
              setTags([...tags, { label, value, id: createdTag.data.id }]);
            }
          }
        });
      }
      setter(value);
    } else if (inputType === "image/video") {
      // handle input file
      let maxFileSize;
      if (value && value[0]) {
        const { size } = value[0] || {};
        if (setter === "courseImage") {
          maxFileSize = 3 * 1024 * 1024;
          if (size > maxFileSize) {
            setInvalidCourseImage(true);
            setInvalidCourseImageMsg("File too large (Max: 3 MB)");
          } else {
            setInvalidCourseImage(false);
            setInvalidCourseImageMsg("");
            setCourseImageToSave(value[0]);
            setCourseImageToView({
              fileName: value[0].name,
              url: URL.createObjectURL(value[0]),
            });
          }
        } else if (setter === "courseVideoHighlight") {
          maxFileSize = 500 * 1024 * 1024;
          if (size > maxFileSize) {
            setInvalidCourseVideoHighlight(true);
            setInvalidCourseVideoHighlightMsg("File too large (Max: 100 MB)");
          } else {
            setInvalidCourseVideoHighlight(false);
            setInvalidCourseVideoHighlightMsg("");
            uploadVideo(value[0], setter);
          }
        }
      }
    }
  };

  const handleOnInputCourseSectionChange = (
    value,
    sectionIndex,
    inputType,
    keyName,
    lessonIndex,
    lessonKeyName
  ) => {
    let finalCourseSections = courseSections;
    finalCourseSections = finalCourseSections.map(
      (courseSection, sectionIdx) => {
        if (sectionIdx === sectionIndex) {
          if (lessonKeyName) {
            let arrLessons = courseSection[keyName];
            arrLessons = arrLessons.map((lesson, lessonIdx) => {
              if (lessonIdx === lessonIndex) {
                if (
                  inputType === "text" ||
                  inputType === "text-editor" ||
                  inputType === "accordion" ||
                  inputType === "duration"
                ) {
                  lesson[lessonKeyName] = value;
                } else if (inputType === "content_type") {
                  lesson["show_progress_bar"] = false;
                  lesson["content_value"] = {
                    content_to_view: null,
                    content_to_save: null,
                  };
                  lesson[lessonKeyName] = value;
                } else {
                  // handle input file
                  if (value && value[0]) {
                    const { size } = value[0] || {};
                    let maxFileSize;
                    if (lesson["content_type"] === "video") {
                      maxFileSize = 500 * 1024 * 1024;
                      if (size > maxFileSize) {
                        lesson["invalid_content"] = true;
                        lesson["invalid_content_msg"] =
                          "File too large (Max: 100 MB)";
                      } else {
                        lesson["invalid_content"] = false;
                        lesson["invalid_content_msg"] = "";
                        uploadVideo(
                          value[0],
                          "lesson",
                          sectionIndex,
                          lessonIndex
                        );
                      }
                    } else {
                      maxFileSize = 5 * 1024 * 1024;
                      if (size > maxFileSize) {
                        lesson["invalid_content"] = true;
                        lesson["invalid_content_msg"] =
                          "File too large (Max: 5 MB)";
                      } else {
                        lesson["invalid_content"] = false;
                        lesson["invalid_content_msg"] = "";
                        lesson[lessonKeyName] = {
                          content_to_save: value[0],
                          content_to_view: {
                            fileName: value[0].name,
                            url: URL.createObjectURL(value[0]),
                          },
                        };
                      }
                    }
                  }
                }
              }
              return lesson;
            });
          } else {
            if (inputType === "text") {
              courseSection[keyName] = value;
            }
          }
        }
        return courseSection;
      }
    );
    setCourseSections(finalCourseSections);
    forceUpdate();
  };

  const handleAddNewSection = () => {
    let finalCourseSections = courseSections;
    let finalCourseSectionCounter = courseSectionCounter;

    let objNewSection = {
      course_section: "",
      what_will_students_be_able_to_do_at_the_end_of_this_section: "",
      lessons: [
        {
          is_open_accordion: true,
          lesson: "",
          content_type: "",
          content_value: {
            content_to_view: null,
            content_to_save: null,
          },
          show_progress_bar: false,
          additional_information: "",
          duration_in_seconds: 0,
          invalid_lesson: false,
          invalid_content: false,
          invalid_content_msg: "",
          invalid_additional_information: false,
        },
      ],
      total_course_duration_in_seconds: 0,
      invalid_course_section: false,
      invalid_what_will_students_be_able_to_do_at_the_end_of_this_section: false,
    };

    let invalid = false;

    finalCourseSections = finalCourseSections.map((courseSection) => {
      const {
        course_section,
        what_will_students_be_able_to_do_at_the_end_of_this_section,
        lessons,
      } = courseSection || {};

      if (!course_section) {
        courseSection["invalid_course_section"] = true;
        invalid = true;
      } else {
        courseSection["invalid_course_section"] = false;
      }

      if (!what_will_students_be_able_to_do_at_the_end_of_this_section) {
        courseSection[
          "invalid_what_will_students_be_able_to_do_at_the_end_of_this_section"
        ] = true;
        invalid = true;
      }

      lessons.map((eachLesson) => {
        const {
          lesson,
          content_type,
          content_value: { content_to_view, content_to_save } = {},
          additional_information,
        } = eachLesson || {};

        if (!lesson) {
          eachLesson["invalid_lesson"] = true;
          invalid = true;
        } else {
          eachLesson["invalid_lesson"] = false;
        }

        if (!content_type) {
          eachLesson["invalid_content"] = true;
          eachLesson["invalid_content_msg"] = "This field is required";
          invalid = true;
        } else {
          if (!content_to_view || !content_to_save) {
            eachLesson["invalid_content"] = true;
            eachLesson["invalid_content_msg"] = "This field is required";
            invalid = true;
          } else {
            eachLesson["invalid_content"] = false;
            eachLesson["invalid_content_msg"] = "";
          }
        }

        if (!additional_information) {
          eachLesson["invalid_additional_information"] = true;
          invalid = true;
        } else {
          eachLesson["invalid_additional_information"] = false;
        }

        return eachLesson;
      });

      return courseSection;
    });

    if (!invalid) {
      const currentCounter = courseSectionCounter.length;

      finalCourseSections.push(objNewSection);
      finalCourseSectionCounter.push(currentCounter);

      setCourseSectionCounter(finalCourseSectionCounter);
    }

    // if (currentCounter < 4) {
    setCourseSections(finalCourseSections);
    forceUpdate();
    // }
  };

  const handleAddNewLesson = (sectionIdx) => {
    const newLessonObj = {
      is_open_accordion: true,
      lesson: "",
      content_type: "",
      content_value: {
        content_to_view: null,
        content_to_save: null,
      },
      additional_information: "",
      invalid_lesson: false,
      invalid_content: false,
      invalid_content_msg: "",
      invalid_additional_information: false,
    };

    let finalCourseSections = courseSections;
    finalCourseSections = finalCourseSections.map(
      (courseSection, courseSectionIndex) => {
        if (courseSectionIndex === sectionIdx) {
          const { lessons } = courseSection;

          let invalid = false;
          lessons.map((eachLesson) => {
            const {
              lesson,
              content_type,
              content_value: { content_to_view, content_to_save } = {},
              additional_information,
            } = eachLesson || {};

            if (!lesson) {
              eachLesson["invalid_lesson"] = true;
              invalid = true;
            } else {
              eachLesson["invalid_lesson"] = false;
            }

            if (!content_type) {
              eachLesson["invalid_content"] = true;
              eachLesson["invalid_content_msg"] = "This field is required";
              invalid = true;
            } else {
              if (course_id) {
                // if edit page
                if (!content_to_view) {
                  eachLesson["invalid_content"] = true;
                  eachLesson["invalid_content_msg"] = "This field is required";
                  invalid = true;
                } else {
                  eachLesson["invalid_content"] = false;
                  eachLesson["invalid_content_msg"] = "";
                }
              } else {
                if (!content_to_view || !content_to_save) {
                  eachLesson["invalid_content"] = true;
                  eachLesson["invalid_content_msg"] = "This field is required";
                  invalid = true;
                } else {
                  eachLesson["invalid_content"] = false;
                  eachLesson["invalid_content_msg"] = "";
                }
              }
            }

            if (!additional_information) {
              eachLesson["invalid_additional_information"] = true;
              invalid = true;
            } else {
              eachLesson["invalid_additional_information"] = false;
            }

            return eachLesson;
          });

          if (!invalid) {
            courseSection["lessons"].push(newLessonObj);
          }
        }
        return courseSection;
      }
    );

    setCourseSections(finalCourseSections);
  };

  const handleRemoveLesson = (sectionIdx, lessonIdxToRemove) => {
    let finalCourseSections = courseSections;
    finalCourseSections = finalCourseSections.map((course_section, i) => {
      if (i === sectionIdx) {
        course_section["lessons"] = course_section["lessons"].filter(
          (_, j) => j !== lessonIdxToRemove
        );
      }
      return course_section;
    });
    setCourseSections(finalCourseSections);
  };

  const handleFetchMentors = async () => {
    setLoading(true);
    const result = await getAllMentors();
    if (result && result.data) {
      let finalMentorOptions = result.data;
      finalMentorOptions = finalMentorOptions.map((mentor) => {
        const mentorFullName = mentor
          ? mentor?.first_name + " " + mentor?.last_name
          : "Not available";
        const mentorUserId = mentor?.id;
        return {
          name: mentorFullName,
          value: mentorUserId,
        };
      });
      setMentorOptions(finalMentorOptions);
    }
    setLoading(false);
  };

  const checkAccordionInputValidation = (accordion_number) => {
    let isValid = false;
    if (accordion_number === 1) {
      isValid = true;
    } else if (accordion_number === 2) {
      // check accordion 1 before open accordion 2
      let invalidCourseType = false;
      let invalidCourseLevel = false;
      let invalidCourseLength = false;
      let invalidCourseCategory = false;
      let invalidCourseLanguage = false;
      let invalidCourseSubCategory = false;

      if (!courseType) {
        invalidCourseType = true;
      }
      if (!courseLevel) {
        invalidCourseLevel = true;
      }
      if (!courseLength) {
        invalidCourseLength = true;
      }
      if (!courseCategory) {
        invalidCourseCategory = true;
      }
      if (!courseLanguage) {
        invalidCourseLanguage = true;
      }
      if (!courseSubCategory) {
        invalidCourseSubCategory = true;
      }

      setInvalidCourseType(invalidCourseType);
      setInvalidCourseLevel(invalidCourseLevel);
      setInvalidCourseLength(invalidCourseLength);
      setInvalidCourseCategory(invalidCourseCategory);
      setInvalidCourseLanguage(invalidCourseLanguage);
      setInvalidCourseSubCategory(invalidCourseSubCategory);

      if (
        invalidCourseType !== true &&
        invalidCourseLevel !== true &&
        invalidCourseLength !== true &&
        invalidCourseCategory !== true &&
        invalidCourseLanguage !== true &&
        invalidCourseSubCategory !== true
      ) {
        isValid = true;
      }
    } else if (accordion_number === 3) {
      // check accordion 2 before open accordion 3
      let invalidCourseName = false;
      let invalidCourseSummary = false;
      let invalidWhatWillYouLearnInThisCourse = false;
      let invalidTargetAudience = false;
      let invalidAdditionalDescription = false;
      let invalidTags = false;
      let invalidCourseImage = false;
      let invalidCourseImageMsg = "";
      let invalidCourseVideoHighlight = false;
      let invalidCourseVideoHighlightMsg = "";

      if (!courseName) {
        invalidCourseName = true;
      }
      if (!courseSummary) {
        invalidCourseSummary = true;
      }
      if (!whatWillYouLearnInThisCourse) {
        invalidWhatWillYouLearnInThisCourse = true;
      }
      if (!targetAudience) {
        invalidTargetAudience = true;
      }
      if (!additionalDescription) {
        invalidAdditionalDescription = true;
      }
      if (tags.length <= 0) {
        invalidTags = true;
      }
      if (course_id) {
        // validation for edit page
        if (
          courseImageToView &&
          !courseImageToView["url"] &&
          !courseImageToSave
        ) {
          invalidCourseImage = true;
          invalidCourseImageMsg = "This field is required";
        }
        if (
          courseVideoHighlightToView &&
          !courseVideoHighlightToView["url"] &&
          !courseVideoHighlightToSave
        ) {
          invalidCourseVideoHighlight = true;
          invalidCourseVideoHighlightMsg = "This field is required";
        }
      } else {
        if (!courseImageToView || !courseImageToSave) {
          invalidCourseImage = true;
          invalidCourseImageMsg = "This field is required";
        }
        if (!courseVideoHighlightToView || !courseVideoHighlightToSave) {
          invalidCourseVideoHighlight = true;
          invalidCourseVideoHighlightMsg = "This field is required";
        }
      }

      setInvalidCourseName(invalidCourseName);
      setInvalidCourseSummary(invalidCourseSummary);
      setInvalidWhatWillYouLearnInThisCourse(
        invalidWhatWillYouLearnInThisCourse
      );
      setInvalidTargetAudience(invalidTargetAudience);
      setInvalidAdditionalDescription(invalidAdditionalDescription);
      setInvalidTags(invalidTags);
      setInvalidCourseImage(invalidCourseImage);
      setInvalidCourseImageMsg(invalidCourseImageMsg);
      setInvalidCourseVideoHighlight(invalidCourseVideoHighlight);
      setInvalidCourseVideoHighlightMsg(invalidCourseVideoHighlightMsg);

      if (
        invalidCourseName !== true &&
        invalidCourseSummary !== true &&
        invalidWhatWillYouLearnInThisCourse !== true &&
        invalidTargetAudience !== true &&
        invalidAdditionalDescription !== true &&
        invalidTags !== true &&
        invalidCourseImage !== true &&
        invalidCourseVideoHighlight !== true
      ) {
        isValid = true;
      }
    } else if (accordion_number === 4) {
      // check accordion 3 before open accordion 4
      let invalid = false;
      let finalTotalLessons = 0;
      let finalTotalCourseDurationInSeconds = 0;
      let finalTotalCourseDuration = 0;
      let finalCourseSections = courseSections;

      finalCourseSections = finalCourseSections.map((courseSection) => {
        let finalTotalCourseSectionDurationInSeconds = 0;
        const {
          course_section,
          what_will_students_be_able_to_do_at_the_end_of_this_section,
          lessons,
        } = courseSection || {};

        if (!course_section) {
          courseSection["invalid_course_section"] = true;
          invalid = true;
        } else {
          courseSection["invalid_course_section"] = false;
        }

        if (!what_will_students_be_able_to_do_at_the_end_of_this_section) {
          courseSection[
            "invalid_what_will_students_be_able_to_do_at_the_end_of_this_section"
          ] = true;
          invalid = true;
        }

        lessons.map((eachLesson) => {
          const {
            lesson,
            content_type,
            content_value: { content_to_view, content_to_save } = {},
            additional_information,
            duration_in_seconds,
          } = eachLesson || {};

          if (!lesson) {
            eachLesson["invalid_lesson"] = true;
            invalid = true;
          } else {
            eachLesson["invalid_lesson"] = false;
          }

          if (!content_type) {
            eachLesson["invalid_content"] = true;
            eachLesson["invalid_content_msg"] = "This field is required";
            invalid = true;
          } else {
            if (course_id) {
              // validation for edit page
              if (
                content_to_view &&
                !content_to_view["url"] &&
                !content_to_save
              ) {
                eachLesson["invalid_content"] = true;
                eachLesson["invalid_content_msg"] = "This field is required";
              }
            } else {
              if (!content_to_view || !content_to_save) {
                eachLesson["invalid_content"] = true;
                eachLesson["invalid_content_msg"] = "This field is required";
                invalid = true;
              } else {
                eachLesson["invalid_content"] = false;
                eachLesson["invalid_content_msg"] = "";
              }
            }
          }

          if (!additional_information) {
            eachLesson["invalid_additional_information"] = true;
            invalid = true;
          } else {
            eachLesson["invalid_additional_information"] = false;
          }

          if (duration_in_seconds) {
            finalTotalCourseSectionDurationInSeconds += duration_in_seconds;
            finalTotalCourseDurationInSeconds += duration_in_seconds;
          }

          return eachLesson;
        });

        finalTotalLessons += lessons.length;
        courseSection["total_course_duration_in_seconds"] =
          finalTotalCourseSectionDurationInSeconds;
        finalTotalCourseDuration += finalTotalCourseSectionDurationInSeconds;

        return courseSection;
      });

      if (!invalid) {
        isValid = true;
      }

      setTotalCourseDuration(getTimeFromSeconds(finalTotalCourseDuration));
      setTotalCourseSections(finalCourseSections.length);
      setTotalLessons(finalTotalLessons);
      setCourseSections(finalCourseSections);
      forceUpdate();
    } else if (accordion_number === 5) {
      // check accordion 4
      let invalidAssignInstructor = false;

      if (!assignInstructor) {
        invalidAssignInstructor = true;
      }

      setInvalidAssignInstructor(invalidAssignInstructor);

      if (invalidAssignInstructor !== true) {
        isValid = true;
      }
    }
    return isValid;
  };

  const handleSaveCourse = async (filesToUploadToServer) => {
    const tagIds = [];
    tags.forEach((tag) => {
      tagIds.push(tag.id);
    });
    let body = {
      // section 1
      course_type: courseType,
      course_length: courseLength,
      course_level: courseLevel,
      course_category: courseCategory,
      course_language: courseLanguage,
      course_sub_category: courseSubCategory,
      // section 2
      course_name: courseName,
      course_summary: courseSummary,
      course_image_url: "",
      course_video_highlight_url: "",
      what_you_will_learn: whatWillYouLearnInThisCourse,
      target_audience: targetAudience,
      additional_description: additionalDescription,
      course_tag_ids: tagIds,
      // section 3
      course_sections_arr: null,
      // section 4
      instructor_id: assignInstructor.value,
    };

    if (course_id) {
      body = {
        ...body,
        course_id,
      };
    }

    if (courseImageToSave) {
      // upload course image and get url
      const formData = new FormData();
      formData.append("file", courseImageToSave);
      const result = await saveFileToServer(formData);
      if (result && result.data && result.data.file_url) {
        body["course_image_url"] = result.data.file_url;
      }
    } else {
      if (course_id) {
        body["course_image_url"] =
          courseImageToView && courseImageToView["url"];
      }
    }

    let finalCourseSectionArr = courseSections;

    for (let csa = 0; csa < finalCourseSectionArr.length; csa++) {
      const { lessons } = finalCourseSectionArr[csa];
      for (let l = 0; l < lessons.length; l++) {
        const {
          content_type,
          content_value: { content_to_save, content_to_view },
        } = lessons[l];
        if (content_type === "article") {
          // handle content type article
          if (content_to_save) {
            const formData = new FormData();
            formData.append("file", content_to_save);
            const result = await saveFileToServer(formData);
            if (result && result.data && result.data.file_url) {
              finalCourseSectionArr[csa]["lessons"][l] = {
                ...finalCourseSectionArr[csa]["lessons"][l],
                ["uploaded_file_url"]: result.data.file_url,
              };
            }
          } else {
            if (course_id && content_to_view && content_to_view["url"]) {
              finalCourseSectionArr[csa]["lessons"][l] = {
                ...finalCourseSectionArr[csa]["lessons"][l],
                ["uploaded_file_url"]: content_to_view["url"],
              };
            }
          }
        } else {
          // handle content type video only for spesific edit page
          if (
            course_id &&
            !content_to_save &&
            content_to_view &&
            content_to_view["url"]
          ) {
            finalCourseSectionArr[csa]["lessons"][l] = {
              ...finalCourseSectionArr[csa]["lessons"][l],
              ["uploaded_file_url"]: content_to_view["url"],
              ["lesson_video_id"]: content_to_view["lesson_video_id"],
            };
          }
        }
      }
    }

    body = {
      ...body,
      ["course_sections_arr"]: finalCourseSectionArr,
    };

    if (
      filesToUploadToServer &&
      Array.isArray(filesToUploadToServer) &&
      filesToUploadToServer.length > 0
    ) {
      for (let ftuts = 0; ftuts < filesToUploadToServer.length; ftuts++) {
        const {
          fileKeyName,
          uploaded_file_url,
          video_id,
          sectionIndex,
          lessonIndex,
        } = filesToUploadToServer[ftuts];
        if (fileKeyName && fileKeyName === "courseVideoHighlight") {
          body["course_video_highlight_url"] = uploaded_file_url;
          body["course_video_id"] = video_id;
        } else {
          // if fileKeyName undefined, process as lesson video
          body["course_sections_arr"][sectionIndex]["lessons"][lessonIndex] = {
            ...body["course_sections_arr"][sectionIndex]["lessons"][
              lessonIndex
            ],
            ["uploaded_file_url"]: uploaded_file_url,
            ["lesson_video_id"]: video_id,
          };
        }
      }
    }

    // for edit when course video highlight not updated
    if (
      !body["course_video_highlight_url"] &&
      course_id &&
      !courseVideoHighlightToSave &&
      courseVideoHighlightToView &&
      courseVideoHighlightToView["url"]
    ) {
      body["course_video_highlight_url"] = courseVideoHighlightToView["url"];
      body["course_video_id"] = courseVideoHighlightToView["course_video_id"];
    }

    // save or edit course process
    const result = course_id
      ? await adminEditCourse(body)
      : await adminCreateNewCourse(body);

    if (result && result.data) {
      // clear reducer state of accordion inputs
      handleSaveInputCourseForm(null, true);

      // show notification success
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: course_id
          ? "Successfully updated the course"
          : "Successfully published the course",
        showConfirmButton: false,
        timer: 3000,
      });

      // go back to previous page
      navigate(-1);
    }
  };

  const handleCheckAllAccordionInputs = () => {
    let isValid = false;
    // check all inputs accordion 1
    isValid = checkAccordionInputValidation(2);
    if (isValid) {
      // check all inputs accordion 2
      isValid = checkAccordionInputValidation(3);
      if (isValid) {
        // check all inputs accordion 3
        isValid = checkAccordionInputValidation(4);
        if (isValid) {
          // check all inputs accordion 4
          isValid = checkAccordionInputValidation(5);
          if (isValid) {
            if (
              filesToUploadToServer &&
              Array.isArray(filesToUploadToServer) &&
              filesToUploadToServer.length <= 0
            ) {
              handleGetAllFileVideosToUpload();
            } else {
              let allFileNotYetUploaded = handleCheckAllFileIsUploaded(
                filesToUploadToServer
              );
              if (allFileNotYetUploaded) {
                // after all input valid, go to next step
                handleGetAllFileVideosToUpload();
              }
            }
          } else {
            // open accordion 4
            setIsOpenAccordion({
              ...isOpenAccordion,
              accordion_4: true,
            });
          }
        } else {
          // open accordion 3
          setIsOpenAccordion({
            ...isOpenAccordion,
            accordion_3: true,
          });
        }
      } else {
        // open accordion 2
        setIsOpenAccordion({
          ...isOpenAccordion,
          accordion_2: true,
        });
      }
    } else {
      // open accordion 1
      setIsOpenAccordion({
        ...isOpenAccordion,
        accordion_1: true,
      });
    }
  };

  const handleGetAllFileVideosToUpload = () => {
    const finalArrFileVideo = [];
    let isFilesStateExist = false;
    // first check file upload to server state, if it's already exist
    if (
      filesToUploadToServer &&
      Array.isArray(filesToUploadToServer) &&
      filesToUploadToServer.length > 0
    ) {
      isFilesStateExist = true;
      // use file upload to server state
      for (let f = 0; f < filesToUploadToServer.length; f++) {
        let file = filesToUploadToServer[f];
        if (file.isCanceled) {
          if (file.fileKeyName === "courseVideoHighlight") {
            file = {
              ...file,
              file: courseVideoHighlightToSave,
              fileName: courseVideoHighlightToSave.name,
              fileSize: Math.floor(
                courseVideoHighlightToSave.size / (1024 * 1024)
              ),
            };
          } else {
            // lesson video here
            for (let cs = 0; cs < courseSections.length; cs++) {
              let sectionIndex = cs;
              let { lessons } = courseSections[cs];
              if (lessons && Array.isArray(lessons) && lessons.length > 0) {
                for (let l = 0; l < lessons.length; l++) {
                  let lessonIndex = l;
                  const { content_type, content_value } = lessons[l];
                  if (
                    content_value &&
                    content_type &&
                    content_type === "video"
                  ) {
                    if (
                      lessonIndex === file.lessonIndex &&
                      sectionIndex === file.sectionIndex
                    ) {
                      file = {
                        ...file,
                        file: content_value.content_to_save,
                        fileName: content_value.content_to_save.name,
                        fileSize: Math.floor(
                          content_value.content_to_save.size / (1024 * 1024)
                        ),
                        filePercentageLoaded: 0,
                      };
                    }
                  }
                }
              }
            }
          }
        }
        finalArrFileVideo.push(file);
      }
    } else {
      // create new value for upload file to server state
      finalArrFileVideo.push({
        ...courseVideoHighlightToView,
        file: courseVideoHighlightToSave,
      });
      if (
        courseSections &&
        Array.isArray(courseSections) &&
        courseSections.length > 0
      ) {
        for (let cs = 0; cs < courseSections.length; cs++) {
          let { lessons } = courseSections[cs];
          if (lessons && Array.isArray(lessons) && lessons.length > 0) {
            for (let l = 0; l < lessons.length; l++) {
              const { content_type, content_value } = lessons[l];
              if (content_value && content_type && content_type === "video") {
                finalArrFileVideo.push({
                  ...content_value.content_to_view,
                  file: content_value.content_to_save,
                });
              }
            }
          }
        }
      }
    }
    handleShowUploadFileVideoProgressAndUploadVideoToServer(
      finalArrFileVideo,
      isFilesStateExist
    );
  };

  const handleShowUploadFileVideoProgressAndUploadVideoToServer = async (
    arrFiles,
    isFilesStateExist
  ) => {
    let finalArrFileToUploadToServer = [];

    if (isFilesStateExist) {
      for (let f = 0; f < arrFiles.length; f++) {
        let file = arrFiles[f];
        if (file.isCanceled) {
          let fileSizeInMb = Math.floor(file.file.size / (1024 * 1024));
          finalArrFileToUploadToServer.push({
            file: file.file,
            fileName: file.file.name,
            fileSize: fileSizeInMb,
            isUploaded: false,
            isCanceled: false,
            fileKeyName: file.fileKeyName || null,
            sectionIndex: file.sectionIndex
              ? file.sectionIndex
              : file.sectionIndex === 0
              ? 0
              : null,
            lessonIndex: file.lessonIndex
              ? file.lessonIndex
              : file.lessonIndex === 0
              ? 0
              : null,
          });
        } else {
          finalArrFileToUploadToServer.push(file);
        }
      }
    } else {
      // first set all video name and size to our file to upload to server state
      for (let f = 0; f < arrFiles.length; f++) {
        const file = arrFiles[f];
        let fileSizeInMb;
        if (file && file.file) {
          fileSizeInMb = Math.floor(file.file.size / (1024 * 1024));
          finalArrFileToUploadToServer.push({
            file: file && file.file,
            fileName: file && file.file && file.file.name,
            fileSize: fileSizeInMb || 0,
            isUploaded: course_id && file && !file.file ? true : false,
            isCanceled: false,
            fileKeyName: file.fileKeyName || null,
            sectionIndex: file.sectionIndex
              ? file.sectionIndex
              : file.sectionIndex === 0
              ? 0
              : null,
            lessonIndex: file.lessonIndex
              ? file.lessonIndex
              : file.lessonIndex === 0
              ? 0
              : null,
          });
        }
      }
    }

    setFilesToUploadToServer(finalArrFileToUploadToServer);

    // open the upload file to server modal progress
    if (
      finalArrFileToUploadToServer &&
      Array.isArray(finalArrFileToUploadToServer) &&
      finalArrFileToUploadToServer.length > 0
    ) {
      setIsOpenUploadFileToServer(true);
    } else {
      if (course_id) {
        handleSaveCourse(finalArrFileToUploadToServer);
      }
    }
  };

  // const handleUploadAllFilesToServer = async () => {
  //   for (let l = 0; l < filesToUploadToServer.length; l++) {
  //     const { file } = filesToUploadToServer[l];
  //     await uploadVideo(
  //       file,
  //       "uploadVideoToServer",
  //       null,
  //       null,
  //       filesToUploadToServer,
  //       l
  //     );
  //   }
  // };

  const handleUploadFileToServer = (fileIdx) => {
    for (let l = 0; l < filesToUploadToServer.length; l++) {
      const { file } = filesToUploadToServer[l];
      if (fileIdx === l) {
        setLoadingUploadFile(true);
        uploadVideo(
          file,
          "uploadVideoToServer",
          null,
          null,
          filesToUploadToServer,
          fileIdx
        );
      }
    }
  };

  const handleCheckAllFileIsUploaded = (filesToUpload) => {
    let allFileNotYetUploaded = filesToUpload.some(
      (file) => file.isUploaded === false
    );

    // if all file is not uploaded to server
    if (allFileNotYetUploaded) {
      return allFileNotYetUploaded;
    } else {
      // close upload file to server modal and go to next step
      setIsOpenUploadFileToServer(false);
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "All files have been uploaded, saving your course...",
        showConfirmButton: false,
        timer: 3000,
      });
      handleSaveCourse(filesToUpload);
    }
  };

  const uploadNextChunk = async (
    chunk,
    file,
    filesToUploadToServerToView,
    fileToUploadToServerIndex,
    totalChunks,
    chunkNumber
  ) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("chunkNumber", chunkNumber);
    formData.append("totalChunks", totalChunks);
    formData.append("originalname", file.name);

    const result = await saveFileVideoToServer(formData);

    if (result && result.data) {
      if (
        result &&
        result.data &&
        result.data.file_url &&
        result.data.video_id
      ) {
        let counter = 0;
        let finalFilesToUploadToServerToView = filesToUploadToServerToView;
        finalFilesToUploadToServerToView = finalFilesToUploadToServerToView.map(
          (file, idx) => {
            if (fileToUploadToServerIndex === idx) {
              file["isUploaded"] = true;
              file["uploaded_file_url"] = result.data.file_url;
              file["uploading"] = false;
              file["video_id"] = result.data.video_id;
            }
            return file;
          }
        );
        setFileUploadedToServerCount(counter + 1);
        forceUpdate();
        handleCheckAllFileIsUploaded(
          finalFilesToUploadToServerToView
          // fileToUploadToServerIndex === filesToUploadToServerToView.length - 1
        );
        setFilesToUploadToServer(finalFilesToUploadToServerToView);
        setLoadingUploadFile(false);
        forceUpdate();
      } else {
        return true;
      }
    }
  };

  const handleSaveFileVideoToServer = async (
    file,
    filesToUploadToServerToView,
    fileToUploadToServerIndex
  ) => {
    const chunkSize = 5 * 1024 * 1024; // 5MB (adjust based on your requirements)
    const totalChunks = Math.ceil(file.size / chunkSize);
    let chunkNumber = 0;

    for (let i = 0; i < totalChunks; i++) {
      const start = i * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);
      const result = await uploadNextChunk(
        chunk,
        file,
        filesToUploadToServerToView,
        fileToUploadToServerIndex,
        totalChunks,
        chunkNumber
      );
      if (result) {
        chunkNumber++;
      }
    }
  };

  const handleOpenAccordion = (accordion_number) => {
    let isValid = false;
    if (accordion_number !== 1) {
      isValid = checkAccordionInputValidation(accordion_number);
    }

    if (isValid || (!isValid && accordion_number === 1)) {
      setIsOpenAccordion({
        ...isOpenAccordion,
        [`accordion_${accordion_number}`]:
          !isOpenAccordion[`accordion_${accordion_number}`],
      });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: "Please re-check the input fields from the previous step.",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const fetchAllTags = async () => {
    setLoading(true);
    const response = await getAllTags();
    if (response && response.status === 200 && response.data) {
      const { data = [] } = response || {};
      const finalTags = [];
      data.forEach((el) => {
        const { tag_name, id } = el || {};
        finalTags.push({ id, label: tag_name, value: tag_name });
      });
      setTagOptions(finalTags);
      setLoading(false);
    }
  };

  const handleSaveInputCourseForm = (
    accordionKey,
    isClearInputFormValueFromReducerState
  ) => {
    let objPayload = {
      type: null,
      value: null,
    };
    let objValue;

    // saving form accordion 1
    if (accordionKey === 1) {
      if (
        courseType ||
        courseLevel ||
        courseLength ||
        courseCategory ||
        courseLanguage ||
        courseSubCategory
      ) {
        if (courseType) {
          objValue = {
            ...objValue,
            courseType,
          };
        }
        if (courseLevel) {
          objValue = {
            ...objValue,
            courseLevel,
          };
        }
        if (courseLength) {
          objValue = {
            ...objValue,
            courseLength,
          };
        }
        if (courseCategory) {
          objValue = {
            ...objValue,
            courseCategory,
          };
        }
        if (courseLanguage) {
          objValue = {
            ...objValue,
            courseLanguage,
          };
        }
        if (courseSubCategory) {
          objValue = {
            ...objValue,
            courseSubCategory,
          };
        }
        objPayload = {
          ...objPayload,
          type: "COURSE_FORM_ACCORDION_1_VALUE",
          value: objValue,
        };
      }
    } else if (accordionKey === 2) {
      // saving form accordion 2
      if (
        courseName ||
        courseSummary ||
        whatWillYouLearnInThisCourse ||
        targetAudience ||
        additionalDescription ||
        (tags && tags.length > 0) ||
        (courseImageToView && courseImageToSave) ||
        (courseVideoHighlightToView && courseVideoHighlightToSave)
      ) {
        if (courseName) {
          objValue = {
            ...objValue,
            courseName,
          };
        }
        if (courseSummary) {
          objValue = {
            ...objValue,
            courseSummary,
          };
        }
        if (whatWillYouLearnInThisCourse) {
          objValue = {
            ...objValue,
            whatWillYouLearnInThisCourse,
          };
        }
        if (targetAudience) {
          objValue = {
            ...objValue,
            targetAudience,
          };
        }
        if (additionalDescription) {
          objValue = {
            ...objValue,
            additionalDescription,
          };
        }
        if (tags && tags.length > 0) {
          objValue = {
            ...objValue,
            tags,
          };
        }
        if (courseImageToView) {
          objValue = {
            ...objValue,
            courseImageToView,
          };
        }
        if (courseImageToSave) {
          objValue = {
            ...objValue,
            courseImageToSave,
          };
        }
        if (courseVideoHighlightToView) {
          objValue = {
            ...objValue,
            courseVideoHighlightToView,
          };
        }
        if (courseVideoHighlightToSave) {
          objValue = {
            ...objValue,
            courseVideoHighlightToSave,
          };
        }
        objPayload = {
          ...objPayload,
          type: "COURSE_FORM_ACCORDION_2_VALUE",
          value: objValue,
        };
      }
    } else if (accordionKey === 3) {
      // saving form accordion 3
      objPayload = {
        ...objPayload,
        type: "COURSE_FORM_ACCORDION_3_VALUE",
        value: {
          courseSections,
          courseSectionCounter,
          totalCourseDuration,
          totalCourseSections,
          totalLessons,
          filesToUploadToServer,
          fileUploadedToServerCount,
        },
      };
    } else if (accordionKey === 4) {
      // saving form accordion 4
      objPayload = {
        ...objPayload,
        type: "COURSE_FORM_ACCORDION_4_VALUE",
        value: {
          assignInstructor,
        },
      };
    }

    if (objPayload && objPayload.type && objPayload.value) {
      dispatch(SET_ACCORDION_FORM(objPayload));
    } else {
      if (!accordionKey && isClearInputFormValueFromReducerState) {
        dispatch(
          SET_ACCORDION_FORM({
            type: "COURSE_FORM_ACCORDION_1_VALUE",
            value: null,
          })
        );
        dispatch(
          SET_ACCORDION_FORM({
            type: "COURSE_FORM_ACCORDION_2_VALUE",
            value: null,
          })
        );
        dispatch(
          SET_ACCORDION_FORM({
            type: "COURSE_FORM_ACCORDION_3_VALUE",
            value: null,
          })
        );
        dispatch(
          SET_ACCORDION_FORM({
            type: "COURSE_FORM_ACCORDION_4_VALUE",
            value: null,
          })
        );
      }
    }
  };

  const handleFetchSavedFormValue = (
    courseFormAccordion1Value,
    courseFormAccordion2Value,
    courseFormAccordion3Value,
    courseFormAccordion4Value
  ) => {
    if (courseFormAccordion1Value) {
      for (const key in courseFormAccordion1Value) {
        let value = courseFormAccordion1Value[key];
        if (value) {
          if (key === "courseType") setCourseType(value);
          if (key === "courseLevel") setCourseLevel(value);
          if (key === "courseLength") setCourseLength(value);
          if (key === "courseCategory") setCourseCategory(value);
          if (key === "courseLanguage") setCourseLanguage(value);
          if (key === "courseSubCategory") setCourseSubCategory(value);
        }
      }
    }
    if (courseFormAccordion2Value) {
      for (const key in courseFormAccordion2Value) {
        let value = courseFormAccordion2Value[key];
        if (value) {
          if (key === "courseName") setCourseName(value);
          if (key === "courseSummary") setCourseSummary(value);
          if (key === "courseImageToSave") setCourseImageToSave(value);
          if (key === "courseImageToView") setCourseImageToView(value);
          if (key === "courseVideoHighlightToSave")
            setCourseVideoHighlightToSave(value);
          if (key === "courseVideoHighlightToView")
            setCourseVideoHighlightToView(value);
          if (key === "whatWillYouLearnInThisCourse")
            setWhatWillYouLearnInThisCourse(value);
          if (key === "additionalDescription") setAdditionalDescription(value);
          if (key === "targetAudience") setTargetAudience(value);
          if (key === "tags") setTags(value);
        }
      }
    }
    if (courseFormAccordion3Value) {
      for (const key in courseFormAccordion3Value) {
        let value = courseFormAccordion3Value[key];
        if (value) {
          if (key === "courseSections") setCourseSections(value);
          if (key === "courseSectionCounter") setCourseSectionCounter(value);
          if (key === "filesToUploadToServer") setFilesToUploadToServer(value);
          if (key === "totalCourseDuration") setTotalCourseDuration(value);
          if (key === "totalCourseSections") setTotalCourseSections(value);
          if (key === "totalLessons") setTotalLessons(value);
          if (key === "fileUploadedToServerCount")
            setFileUploadedToServerCount(value);
        }
      }
    }
    if (courseFormAccordion4Value) {
      for (const key in courseFormAccordion4Value) {
        let value = courseFormAccordion4Value[key];
        if (value) {
          if (key === "assignInstructor") setAssignInstructor(value);
        }
      }
    }
    forceUpdate();
  };

  const handleFetchCourseDetail = async (course_id) => {
    const result = await getDetailCourse(course_id);
    if (result && result.data) {
      const {
        // accordion 1
        course_type,
        course_level,
        course_length,
        course_category,
        course_language,
        course_sub_category,
        course_video_id,

        // accordion 2
        course_name,
        course_summary,
        course_image_url,
        course_video_highlight_url,
        what_you_will_learn,
        target_audience,
        additional_description,
        course_tags,

        // accordion 3
        course_sections,

        // accordion 4
        assigned_instructor,
      } = result.data;

      let objFormAccordion1Value;
      let objFormAccordion2Value;
      let objFormAccordion3Value;
      let objFormAccordion4Value;

      objFormAccordion1Value = {
        courseType: course_type,
        courseLevel: course_level,
        courseLength: course_length,
        courseCategory: course_category,
        courseLanguage: course_language,
        courseSubCategory: course_sub_category,
      };

      let arrTagsValue = course_tags;
      arrTagsValue =
        arrTagsValue && Array.isArray(arrTagsValue) && arrTagsValue.length > 0
          ? arrTagsValue.map((course_tag) => {
              const { tags: { id, tag_name } = {} } = course_tag || {};
              return { label: tag_name, value: tag_name, id };
            })
          : [];

      objFormAccordion2Value = {
        courseName: course_name,
        courseSummary: course_summary,
        courseImageToSave: null,
        courseImageToView: {
          fileName: null,
          url: course_image_url,
        },
        courseVideoHighlightToSave: null,
        courseVideoHighlightToView: {
          fileKeyName: null,
          url: course_video_highlight_url,
          course_video_id,
        },
        whatWillYouLearnInThisCourse: what_you_will_learn,
        targetAudience: target_audience,
        additionalDescription: additional_description,
        tags: arrTagsValue,
      };

      let finalTotalCourseDuration = 0;
      let finalTotalLessons = 0;

      let arrCourseSections = course_sections;
      arrCourseSections =
        arrCourseSections &&
        Array.isArray(arrCourseSections) &&
        arrCourseSections.length > 0
          ? arrCourseSections.map((each_course_section, sectionIndex) => {
              const {
                course_section_name,
                what_will_student_be_able_to_do_at_the_end,
                total_course_duration_in_seconds,
                lessons,
              } = each_course_section || {};
              finalTotalCourseDuration += total_course_duration_in_seconds;
              finalTotalLessons += lessons.length;
              let arrLesson = lessons;
              arrLesson =
                arrLesson && Array.isArray(arrLesson) && arrLesson.length > 0
                  ? arrLesson.map((each_lesson, lessonIndex) => {
                      const {
                        lesson_name,
                        content_type,
                        content_url,
                        additional_information,
                        duration_in_seconds,
                        lesson_video_id,
                      } = each_lesson || {};
                      return {
                        lesson_id: each_lesson.id,
                        is_open_accordion: true,
                        lesson: lesson_name,
                        content_type,
                        content_value: {
                          content_to_view: {
                            fileName: null,
                            url: content_url,
                            lesson_video_id,
                            sectionIndex,
                            lessonIndex,
                          },
                          content_to_save: null,
                        },
                        show_progress_bar: false,
                        additional_information,
                        duration_in_seconds,
                        invalid_lesson: false,
                        invalid_content_type: false,
                        invalid_additional_information: false,
                        invalid_content_value: false,
                        invalid_content_value_msg: "",
                      };
                    })
                  : [];
              return {
                course_section_id: each_course_section.id,
                course_section: course_section_name,
                what_will_students_be_able_to_do_at_the_end_of_this_section:
                  what_will_student_be_able_to_do_at_the_end,
                lessons: arrLesson,
                total_course_duration_in_seconds:
                  total_course_duration_in_seconds,
                invalid_course_section: false,
                invalid_what_will_students_be_able_to_do_at_the_end_of_this_section: false,
              };
            })
          : [];

      let finalCourseSectionCounter = [];
      for (let counter = 0; counter < arrCourseSections.length; counter++) {
        finalCourseSectionCounter.push(counter);
      }

      objFormAccordion3Value = {
        courseSections: arrCourseSections,
        courseSectionCounter: finalCourseSectionCounter,
        filesToUploadToServer: [],
        fileUploadedToServerCount: null,
        totalCourseDuration: getTimeFromSeconds(finalTotalCourseDuration),
        totalCourseSections: arrCourseSections.length,
        totalLessons: finalTotalLessons,
      };

      objFormAccordion4Value = {
        assignInstructor: {
          name: `${assigned_instructor.first_name} ${assigned_instructor.last_name}`,
          value: assigned_instructor.id,
        },
      };

      handleFetchSavedFormValue(
        objFormAccordion1Value,
        objFormAccordion2Value,
        objFormAccordion3Value,
        objFormAccordion4Value
      );
    }
  };

  useEffect(() => {
    if (!course_id) {
      setCourseSections([
        {
          course_section: "",
          what_will_students_be_able_to_do_at_the_end_of_this_section: "",
          lessons: [
            {
              is_open_accordion: true,
              lesson: "",
              content_type: "",
              content_value: {
                content_to_view: null,
                content_to_save: null,
              },
              show_progress_bar: false,
              additional_information: "",
              duration_in_seconds: 0,
              invalid_lesson: false,
              invalid_content_type: false,
              invalid_additional_information: false,
              invalid_content_value: false,
              invalid_content_value_msg: "",
            },
          ],
          total_course_duration_in_seconds: 0,
          invalid_course_section: false,
          invalid_what_will_students_be_able_to_do_at_the_end_of_this_section: false,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    handleFetchMentors();
    fetchAllTags();
  }, []);

  useEffect(() => {
    handleFetchSavedFormValue(
      courseFormAccordion1Value,
      courseFormAccordion2Value,
      courseFormAccordion3Value,
      courseFormAccordion4Value
    );
  }, [
    courseFormAccordion1Value,
    courseFormAccordion2Value,
    courseFormAccordion3Value,
    courseFormAccordion4Value,
  ]);

  useEffect(() => {
    if (course_id) {
      handleFetchCourseDetail(course_id);
    }
  }, [course_id]);

  return (
    <div>
      {isOpenUploadFileToServer ? (
        <UploadingFileToServerModal
          contents={filesToUploadToServer}
          // handleUploadAllFilesToServer={handleUploadAllFilesToServer}
          handleUploadFileToServer={handleUploadFileToServer}
          cancelUploadVideo={cancelUploadVideo}
          setIsOpenUploadFileToServer={setIsOpenUploadFileToServer}
          fileUploadedToServerCount={fileUploadedToServerCount}
          loadingUploadFile={loadingUploadFile}
        />
      ) : null}
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <InnerContainer>
          <Row>
            <BackNavigation onClick={() => navigate("/internal/courses")}>
              <img
                src={arrow_left_icon_dark}
                alt="arrow_left_icon_dark"
                height={15}
                width={15}
                style={{
                  objectFit: "scale-down",
                  objectPosition: "left bottom",
                }}
              />
              {`    Back to previous page`}
            </BackNavigation>
          </Row>
          <Col style={{ marginBottom: "20px" }}>
            <PageTitle>Add new course</PageTitle>
            <Col style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <div>
                <StatusComponent
                  disableClick={true}
                  status={session["status"]}
                  borderRadius="10px"
                />
              </div> */}
            </Col>
          </Col>
          {/* Accordion 1 */}
          <Collapsible
            open={isOpenAccordion["accordion_1"]}
            id={`accordion-item-1`}
            trigger={
              <CollapsibleTrigger onClick={() => handleOpenAccordion(1)}>
                STEP 1: BASIC INFORMATION
                <img
                  src={dropdown_green_icon}
                  alt="dropdown_green_icon"
                  width={15}
                  height={10}
                />
              </CollapsibleTrigger>
            }
          >
            <CollapsibleContent>
              <InputGroup>
                <Col>
                  <Row className="userFormRowMainContainerCourse">
                    <Col md={4}>
                      <div className="userFormInputGroup">
                        <Form.Group>
                          <InputLabel>Course type</InputLabel>
                          <Input
                            type="text"
                            name="courseType"
                            placeholder="Please fill in course type"
                            value={courseType}
                            onChange={({ target: { value } = {} }) =>
                              handleOnInputChange(value, setCourseType, "text")
                            }
                            style={{
                              borderColor:
                                invalidCourseType && !courseType
                                  ? "#EB4335"
                                  : undefined,
                            }}
                            maxLength={255}
                            autoComplete="off"
                          />
                          {invalidCourseType && !courseType ? (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="userFormInputGroup">
                        <Form.Group>
                          <InputLabel>Length</InputLabel>
                          <CustomDropdown
                            name={"courseLength"}
                            value={courseLength}
                            placeholder="Select course length"
                            options={courseLengthOptions}
                            action={(value) =>
                              handleOnInputChange(
                                value,
                                setCourseLength,
                                "dropdown"
                              )
                            }
                            selectedKey={"name"}
                            is_error={invalidCourseLength && !courseLength}
                          />
                          {invalidCourseLength && !courseLength ? (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="userFormInputGroup">
                        <Form.Group>
                          <InputLabel>Language</InputLabel>
                          <CustomDropdown
                            name={"courseLanguage"}
                            value={courseLanguage}
                            placeholder="Select language"
                            options={courseLanguageOptions}
                            action={(value) =>
                              handleOnInputChange(
                                value,
                                setCourseLanguage,
                                "dropdown"
                              )
                            }
                            selectedKey={"name"}
                            is_error={invalidCourseLanguage && !courseLanguage}
                          />
                          {invalidCourseLanguage && !courseLanguage ? (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="userFormRowMainContainerCourse">
                    <Col md={4}>
                      <div className="userFormInputGroup">
                        <Form.Group>
                          <InputLabel>Course level</InputLabel>
                          <CustomDropdown
                            name={"courseLevel"}
                            value={courseLevel}
                            placeholder="Select course level"
                            options={courseLevelOptions}
                            action={(value) =>
                              handleOnInputChange(
                                value,
                                setCourseLevel,
                                "dropdown"
                              )
                            }
                            selectedKey={"name"}
                            is_error={invalidCourseLevel && !courseLevel}
                          />
                          {invalidCourseLevel && !courseLevel ? (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="userFormInputGroup">
                        <Form.Group>
                          <InputLabel>Category</InputLabel>
                          <CustomDropdown
                            name={"courseCategory"}
                            value={courseCategory}
                            placeholder="Select category"
                            options={courseCategoryOptions}
                            action={(value) =>
                              handleOnInputChange(
                                value,
                                setCourseCategory,
                                "dropdown"
                              )
                            }
                            selectedKey={"name"}
                            is_error={invalidCourseCategory && !courseCategory}
                          />
                          {invalidCourseCategory && !courseCategory ? (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="userFormInputGroup">
                        <Form.Group>
                          <InputLabel>Sub-category</InputLabel>
                          <CustomDropdown
                            name={"courseSubCategory"}
                            value={courseSubCategory}
                            placeholder="Select sub-category"
                            options={courseSubCategoryOptions}
                            action={(value) =>
                              handleOnInputChange(
                                value,
                                setCourseSubCategory,
                                "dropdown"
                              )
                            }
                            selectedKey={"name"}
                            is_error={
                              invalidCourseSubCategory && !courseSubCategory
                            }
                          />
                          {invalidCourseSubCategory && !courseSubCategory ? (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              This field is required
                            </div>
                          ) : null}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <SaveInputContainer>
                    <SaveCourseButton
                      onClick={() => handleSaveInputCourseForm(1)}
                    >
                      <SaveCourseText>Save</SaveCourseText>
                    </SaveCourseButton>
                  </SaveInputContainer>
                </Col>
              </InputGroup>
            </CollapsibleContent>
          </Collapsible>
          {/* Accordion 1 */}

          {/* Accordion 2 */}
          <Collapsible
            open={isOpenAccordion["accordion_2"]}
            triggerDisabled={() =>
              checkAccordionInputValidation(2) ? false : true
            }
            trigger={
              <CollapsibleTrigger onClick={() => handleOpenAccordion(2)}>
                STEP 2: ABOUT THE COURSE
                <img
                  src={dropdown_green_icon}
                  alt="dropdown_green_icon"
                  width={15}
                  height={10}
                />
              </CollapsibleTrigger>
            }
          >
            <CollapsibleContent>
              <InputGroup>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <InputLabel>Course name</InputLabel>
                      <TextArea
                        rows={3}
                        id="courseName"
                        name="courseName"
                        autoComplete="off"
                        onChange={({ target: { value } = {} }) =>
                          handleOnInputChange(value, setCourseName, "textarea")
                        }
                        value={courseName}
                        is_error={invalidCourseName && !courseName}
                        type="text"
                        placeholder="Please fill in course name"
                      />
                      {invalidCourseName && !courseName ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <InputLabel>Course Summary</InputLabel>
                      <TextArea
                        rows={3}
                        id="courseSummary"
                        name="courseSummary"
                        value={courseSummary}
                        autoComplete="off"
                        is_error={invalidCourseSummary && !courseSummary}
                        type="text"
                        onChange={({ target: { value } = {} }) =>
                          handleOnInputChange(
                            value,
                            setCourseSummary,
                            "textarea"
                          )
                        }
                        placeholder="Please fill in summary of the course"
                      />
                      {invalidCourseSummary && !courseSummary ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <div className="labelForDescription">
                        <InputLabel>Course Image</InputLabel>
                        <div className="secondLabel">
                          This will be set as a cover image of the course
                        </div>
                      </div>
                      <div
                        className={
                          !courseImageToView
                            ? "inputImageContainer"
                            : "inputImageContainerNoPointer"
                        }
                        onClick={() =>
                          !courseImageToView
                            ? document
                                .querySelector(`.input-field-course-image`)
                                .click()
                            : null
                        }
                        style={{
                          border:
                            invalidCourseImage && invalidCourseImageMsg
                              ? "2px solid #EB4335"
                              : "2px solid #00bb99",
                        }}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          className={`input-field-course-image`}
                          hidden
                          onChange={({ target: { files } }) =>
                            handleOnInputChange(
                              files,
                              "courseImage",
                              "image/video"
                            )
                          }
                        />
                        <div className="imageToViewContainer">
                          <img
                            src={
                              courseImageToView && courseImageToView.url
                                ? courseImageToView.url
                                : upload_file_icon
                            }
                            style={
                              courseImageToView && courseImageToView.url
                                ? { maxWidth: 250, maxHeight: 175 }
                                : { maxWidth: 35, maxHeight: 35 }
                            }
                            alt="courseImage"
                          ></img>
                          {!courseImageFailedToUploadMsg &&
                          courseImageToView &&
                          courseImageToView.fileName ? (
                            <div className="imageFileNameFontStyle">
                              {courseImageToView.fileName}
                            </div>
                          ) : null}
                          {courseImageFailedToUploadMsg && (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              {courseImageFailedToUploadMsg}
                            </div>
                          )}
                        </div>
                        {courseImageToView && (
                          <div
                            className="btnChangeImage"
                            onClick={() =>
                              document
                                .querySelector(`.input-field-course-image`)
                                .click()
                            }
                          >
                            <img
                              src={upload_file_icon}
                              alt="upload_file_icon"
                              width={17}
                              height={17}
                            ></img>
                            <div className="changeImageLabel">
                              Replace image
                            </div>
                          </div>
                        )}
                        {courseImageToView ? null : (
                          <div className="labelImage">{"Click to upload "}</div>
                        )}
                        {courseImageToView ? null : (
                          <div className="placeholderImage">
                            {"Maximum file size 3 MB per image"}
                          </div>
                        )}
                        {courseImageToView ? null : (
                          <div
                            className="placeholderImage"
                            style={{ paddingTop: "3em" }}
                          >
                            {"Supported file type: .jpg, .gif, or .png"}
                          </div>
                        )}
                      </div>
                      {courseImageToView ? null : (
                        <div className="recommendedInputContainer">
                          <div className="recommendedInputBodyContainer">
                            <img src={info_icon} alt="recommended_info" />
                            <div>Recommended file size: 000 x 000px</div>
                          </div>
                        </div>
                      )}
                      {invalidCourseImage && invalidCourseImageMsg ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          {invalidCourseImageMsg}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <div className="labelForDescription">
                        <InputLabel>Course Video Highlight</InputLabel>
                        <div className="secondLabel">
                          This will be displayed as the video highlight of the
                          course
                        </div>
                      </div>
                      <div
                        className={
                          !courseVideoHighlightToView
                            ? "inputImageContainer"
                            : "inputImageContainerNoPointer"
                        }
                        onClick={() =>
                          !courseVideoHighlightToView
                            ? document
                                .querySelector(
                                  `.input-field-course-video-highlight`
                                )
                                .click()
                            : null
                        }
                        style={{
                          border:
                            invalidCourseVideoHighlight &&
                            invalidCourseVideoHighlightMsg
                              ? "2px solid #EB4335"
                              : "2px solid #00bb99",
                        }}
                      >
                        <input
                          id={`input-field-course-video-highlight`}
                          type="file"
                          accept="video/*"
                          className={`input-field-course-video-highlight`}
                          hidden
                          onChange={({ target: { files } }) =>
                            handleOnInputChange(
                              files,
                              "courseVideoHighlight",
                              "image/video"
                            )
                          }
                        />
                        <div className="imageToViewContainer">
                          {courseVideoHighlightToView &&
                          courseVideoHighlightToView.url ? (
                            <div>
                              <video
                                style={{ maxWidth: 450, maxHeight: 250 }}
                                controls={true}
                              >
                                <source
                                  src={courseVideoHighlightToView.url}
                                  type="video/mp4"
                                />
                              </video>
                            </div>
                          ) : showCourseVideoHighlightProgressBar ? (
                            <UploadingVideoProgressBar
                              videoToView={courseVideoHighlightToView}
                              cancelUploadVideo={cancelUploadVideo}
                            />
                          ) : (
                            <img
                              src={upload_file_icon}
                              alt="upload_file_icon"
                              style={{ maxWidth: 35, maxHeight: 35 }}
                            />
                          )}
                          {!courseVideoHighlightFailedToUploadMsg &&
                          courseVideoHighlightToView &&
                          courseVideoHighlightToView.url &&
                          courseVideoHighlightToView.fileName ? (
                            <div className="imageFileNameFontStyle">
                              {courseVideoHighlightToView.fileName}
                            </div>
                          ) : null}
                          {courseVideoHighlightFailedToUploadMsg && (
                            <div
                              style={{
                                color: "#EB4335",
                                fontSize: 12,
                                marginTop: "0.5em",
                              }}
                            >
                              {courseVideoHighlightFailedToUploadMsg}
                            </div>
                          )}
                        </div>
                        {courseVideoHighlightToView &&
                          courseVideoHighlightToView.url && (
                            <div
                              className="btnChangeImage"
                              onClick={() =>
                                document
                                  .querySelector(
                                    `.input-field-course-video-highlight`
                                  )
                                  .click()
                              }
                            >
                              <img
                                src={upload_file_icon}
                                alt="upload_file_icon"
                                width={17}
                                height={17}
                              ></img>
                              <div className="changeImageLabel">
                                Replace video
                              </div>
                            </div>
                          )}
                        {!courseVideoHighlightToView &&
                        !showCourseVideoHighlightProgressBar ? (
                          <div className="labelImage">{"Click to upload "}</div>
                        ) : null}
                        {!courseVideoHighlightToView &&
                        !showCourseVideoHighlightProgressBar ? (
                          <div className="placeholderImage">
                            Maximum file size 100 MB
                          </div>
                        ) : null}
                        {!courseVideoHighlightToView &&
                        !showCourseVideoHighlightProgressBar ? (
                          <div
                            className="placeholderImage"
                            style={{ paddingTop: "3em" }}
                          >
                            {"Supported file type: .mp4, .mov"}
                          </div>
                        ) : null}
                      </div>
                      {!courseVideoHighlightToView &&
                      !showCourseVideoHighlightProgressBar ? (
                        <div className="recommendedInputContainer">
                          <div className="recommendedInputBodyContainer">
                            <img src={info_icon} alt="recommended_info" />
                            <div>Recommended resolution is: 0000</div>
                          </div>
                        </div>
                      ) : null}
                      {invalidCourseVideoHighlight &&
                      invalidCourseVideoHighlightMsg ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          {invalidCourseVideoHighlightMsg}
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <InputLabel>
                        What will you learn in this course?
                      </InputLabel>
                      <TextEditor
                        onChange={(content) =>
                          handleOnInputChange(
                            content,
                            setWhatWillYouLearnInThisCourse,
                            "text-editor"
                          )
                        }
                        preloadValue={
                          whatWillYouLearnInThisCourse
                            ? whatWillYouLearnInThisCourse
                            : null
                        }
                      />
                      {invalidWhatWillYouLearnInThisCourse &&
                      !whatWillYouLearnInThisCourse ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <InputLabel>Target Audience</InputLabel>
                      <TextEditor
                        onChange={(content) =>
                          handleOnInputChange(
                            content,
                            setTargetAudience,
                            "text-editor"
                          )
                        }
                        preloadValue={targetAudience ? targetAudience : null}
                      />
                      {invalidTargetAudience && !targetAudience ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
                <Col>
                  <div className="userFormInputGroup">
                    <Form.Group>
                      <InputLabel>Additional Description</InputLabel>
                      <TextEditor
                        onChange={(content) =>
                          handleOnInputChange(
                            content,
                            setAdditionalDescription,
                            "text-editor"
                          )
                        }
                        preloadValue={
                          additionalDescription ? additionalDescription : null
                        }
                      />
                      {invalidAdditionalDescription &&
                      !additionalDescription ? (
                        <div
                          style={{
                            color: "#EB4335",
                            fontSize: 12,
                            marginTop: "0.5em",
                          }}
                        >
                          This field is required
                        </div>
                      ) : null}
                    </Form.Group>
                  </div>
                </Col>
              </InputGroup>
            </CollapsibleContent>
          </Collapsible>
          {isOpenAccordion["accordion_2"] ? (
            <Col>
              <div className="userFormInputGroup">
                <Form.Group>
                  <InputLabel>Tags</InputLabel>
                  <div style={{ paddingLeft: "1px", paddingRight: "1px" }}>
                    <DropdownMultipleValue
                      placeholder="Enter tags here..."
                      options={tagOptions}
                      selectStyle={{
                        borderRadius: "10px",
                        fontSize: "15px",
                        padding: "5px 5px",
                        border: `2px solid ${
                          invalidTags && tags.length <= 0 ? "#EB4335" : "00bb99"
                        }`,
                        marginBottom: "0.5em",
                      }}
                      optionStyle={{
                        backgroundColor: "#00bb99",
                        fontSize: "15px",
                        color: "#FFFFFF",
                      }}
                      createAble={true}
                      isMulti={true}
                      is_error={invalidTags && tags.length <= 0}
                      onChange={(value) =>
                        handleOnInputChange(value, setTags, "dropdown", true)
                      }
                      value={tags}
                    />
                    {invalidTags && tags.length <= 0 ? (
                      <div
                        style={{
                          color: "#EB4335",
                          fontSize: 12,
                          marginTop: "0.5em",
                        }}
                      >
                        This field is required
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
              </div>
              <div style={{ marginBottom: "1em" }}>
                <SaveInputContainer>
                  <SaveCourseButton
                    onClick={() => handleSaveInputCourseForm(2)}
                  >
                    <SaveCourseText>Save</SaveCourseText>
                  </SaveCourseButton>
                </SaveInputContainer>
              </div>
            </Col>
          ) : null}
          {/* Accordion 2 */}

          {/* Accordion 3 */}
          <Collapsible
            open={isOpenAccordion["accordion_3"]}
            triggerDisabled={() =>
              checkAccordionInputValidation(3) ? false : true
            }
            trigger={
              <CollapsibleTrigger onClick={() => handleOpenAccordion(3)}>
                STEP 3: COURSE CONTENT
                <img
                  src={dropdown_green_icon}
                  alt="dropdown_green_icon"
                  width={15}
                  height={10}
                />
              </CollapsibleTrigger>
            }
          >
            <CollapsibleContent>
              <InputGroup>
                <Col>
                  <div>
                    In this course content section, you can add your course
                    content. Please see more content upload guidelines{" "}
                    <a>here</a>
                  </div>
                </Col>
                {courseSectionCounter &&
                  Array.isArray(courseSectionCounter) &&
                  courseSectionCounter.length > 0 &&
                  courseSectionCounter.map((counter) => {
                    return (
                      <Col key={counter} style={{ paddingTop: "15px" }}>
                        <InputCourseSection
                          counter={counter}
                          firstInput={counter < 1 ? true : false}
                          courseSections={courseSections}
                          handleOnInputCourseSectionChange={
                            handleOnInputCourseSectionChange
                          }
                          handleAddNewLesson={handleAddNewLesson}
                          handleRemoveLesson={handleRemoveLesson}
                          cancelUploadVideo={cancelUploadVideo}
                          handleAddNewSection={handleAddNewSection}
                          isEditPage={course_id}
                        />
                      </Col>
                    );
                  })}
              </InputGroup>
              <SaveInputContainer>
                <SaveCourseButton onClick={() => handleSaveInputCourseForm(3)}>
                  <SaveCourseText>Save</SaveCourseText>
                </SaveCourseButton>
              </SaveInputContainer>
            </CollapsibleContent>
          </Collapsible>
          {/* Accordion 3 */}

          {/* Accordion 4 */}
          <Collapsible
            open={isOpenAccordion["accordion_4"]}
            triggerDisabled={() =>
              checkAccordionInputValidation(4) ? false : true
            }
            trigger={
              <CollapsibleTrigger onClick={() => handleOpenAccordion(4)}>
                STEP 4: PUBLISH YOUR COURSE
                <img
                  src={dropdown_green_icon}
                  alt="dropdown_green_icon"
                  width={15}
                  height={10}
                />
              </CollapsibleTrigger>
            }
          >
            <CollapsibleContent>
              <InputGroup>
                <Col>
                  <div>
                    Final check before you publish and submit course. Please do
                    review and click “Submit” to proceed.
                  </div>
                </Col>
              </InputGroup>
            </CollapsibleContent>
          </Collapsible>
          {isOpenAccordion["accordion_4"] ? (
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Col md={4}>
                <div className="userFormInputGroup">
                  <Form.Group>
                    <InputLabel>Please select course instructor</InputLabel>
                    <CustomDropdown
                      name={"assignInstructor"}
                      value={assignInstructor}
                      placeholder="Select Instructor"
                      options={mentorOptions}
                      action={(value) => {
                        handleOnInputChange(
                          value,
                          setAssignInstructor,
                          "dropdown"
                        );
                      }}
                      selectedKey={"name"}
                      label="assignInstructor"
                      is_error={invalidAssignInstructor && !assignInstructor}
                    />
                    {invalidAssignInstructor && !assignInstructor ? (
                      <div style={{ color: "#EB4335", fontSize: 12 }}>
                        This field is required
                      </div>
                    ) : null}
                  </Form.Group>
                </div>
              </Col>
              <div style={{ marginBottom: "1em" }}>
                <SaveInputContainer>
                  <SaveCourseButton
                    onClick={() => handleSaveInputCourseForm(4)}
                  >
                    <SaveCourseText>Save</SaveCourseText>
                  </SaveCourseButton>
                </SaveInputContainer>
              </div>
            </Row>
          ) : null}
          {/* Accordion 4 */}

          {/* course recap */}
          {isOpenAccordion["accordion_4"] ? (
            <div style={{ marginTop: "2em" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <PageTitle>{courseName}</PageTitle>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <div>{`${totalCourseSections} ${
                    totalCourseSections > 1 ? "courses" : "course"
                  }, ${totalLessons} ${
                    totalLessons > 1 ? "lessons" : "lesson"
                  }`}</div>
                </Col>
              </div>
              {totalCourseDuration ? (
                <div style={{ marginBottom: "20px" }}>
                  {`Duration to complete course : ${
                    totalCourseDuration.hours
                      ? `${
                          totalCourseDuration.hours > 1
                            ? `${totalCourseDuration.hours} hours`
                            : `${totalCourseDuration.hours} hour`
                        }`
                      : ``
                  } ${
                    totalCourseDuration.minutes
                      ? `${
                          totalCourseDuration.minutes > 1
                            ? `${totalCourseDuration.minutes} mins`
                            : `${totalCourseDuration.minutes} min`
                        }`
                      : ``
                  } ${
                    !totalCourseDuration.hours && totalCourseDuration.seconds
                      ? `${
                          totalCourseDuration.seconds > 1
                            ? `${totalCourseDuration.seconds} secs`
                            : `${totalCourseDuration.seconds} sec`
                        }`
                      : ``
                  }`}
                </div>
              ) : null}
              <div
                style={{
                  fontSize: "27px",
                  padding: "12px 0",
                  fontWeight: "bold",
                  color: "#007c7c",
                  margin: "5px 0px",
                }}
              >
                Course Details
              </div>
              <div style={{ padding: "10px 0" }}>
                <Col>
                  {courseSummary && (
                    <Col>
                      <Label>Course summary</Label>
                      <Text>{courseSummary}</Text>
                    </Col>
                  )}
                </Col>
                <Row>
                  <Col>
                    {courseType && (
                      <Col>
                        <Label>Course type</Label>
                        <Text>{courseType}</Text>
                      </Col>
                    )}
                    {courseLevel && (
                      <Col>
                        <Label>Course level</Label>
                        <Text>{courseLevel}</Text>
                      </Col>
                    )}
                    {courseLength && (
                      <Col>
                        <Label>Length</Label>
                        <Text>{courseLength}</Text>
                      </Col>
                    )}
                  </Col>
                  <Col>
                    {courseCategory && (
                      <Col>
                        <Label>Category</Label>
                        <Text>{courseCategory}</Text>
                      </Col>
                    )}
                    {courseLevel && (
                      <Col>
                        <Label>Language</Label>
                        <Text>{courseLanguage}</Text>
                      </Col>
                    )}
                    {courseSubCategory && (
                      <Col>
                        <Label>Sub-category</Label>
                        <Text>{courseSubCategory}</Text>
                      </Col>
                    )}
                  </Col>
                </Row>
              </div>
              {/* <Collapsible
                trigger={
                  <CollapsibleTriggerForCourseSummit>
                    Course Details
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={dropdown_green_icon}
                        alt="dropdown_green_icon"
                        width={15}
                        height={10}
                      />
                    </div>
                  </CollapsibleTriggerForCourseSummit>
                }
              >
                <CollapsibleContent>
                  <Col>
                    {courseSummary && (
                      <Col>
                        <Label>Course summary</Label>
                        <Text>{courseSummary}</Text>
                      </Col>
                    )}
                  </Col>
                  <Row>
                    <Col>
                      {courseType && (
                        <Col>
                          <Label>Course type</Label>
                          <Text>{courseType}</Text>
                        </Col>
                      )}
                      {courseLevel && (
                        <Col>
                          <Label>Course level</Label>
                          <Text>{courseLevel}</Text>
                        </Col>
                      )}
                      {courseLength && (
                        <Col>
                          <Label>Length</Label>
                          <Text>{courseLength}</Text>
                        </Col>
                      )}
                    </Col>
                    <Col>
                      {courseCategory && (
                        <Col>
                          <Label>Category</Label>
                          <Text>{courseCategory}</Text>
                        </Col>
                      )}
                      {courseLevel && (
                        <Col>
                          <Label>Language</Label>
                          <Text>{courseLanguage}</Text>
                        </Col>
                      )}
                      {courseSubCategory && (
                        <Col>
                          <Label>Sub-category</Label>
                          <Text>{courseSubCategory}</Text>
                        </Col>
                      )}
                    </Col>
                  </Row>
                </CollapsibleContent>
              </Collapsible> */}
              {courseSections &&
              Array.isArray(courseSections) &&
              courseSections.length > 0
                ? courseSections.map((courseSection, idx) => {
                    const { course_section, lessons } = courseSection || {};
                    return (
                      <Collapsible
                        key={idx}
                        trigger={
                          <CollapsibleTriggerForCourseSummit>
                            {course_section
                              ? course_section.charAt(0).toUpperCase() +
                                course_section.slice(1)
                              : ""}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <div style={{ fontSize: "13px" }}>{`${
                                lessons && lessons.length
                              } ${
                                lessons.length > 1 ? "lessons" : "lesson"
                              }`}</div>
                              <img
                                src={dropdown_green_icon}
                                alt="dropdown_green_icon"
                                width={15}
                                height={10}
                              />
                            </div>
                          </CollapsibleTriggerForCourseSummit>
                        }
                      >
                        <CollapsibleContent>
                          <div
                            style={{
                              display: "flex",
                              gap: "100px",
                              marginBottom: "10px",
                              flexWrap: "wrap",
                            }}
                          >
                            {lessons &&
                            Array.isArray(lessons) &&
                            lessons.length > 0
                              ? lessons.map((eachLesson, idx) => {
                                  return (
                                    <CourseRecap
                                      eachLesson={eachLesson}
                                      key={idx}
                                      isEditPage={course_id}
                                    />
                                  );
                                })
                              : null}
                          </div>
                        </CollapsibleContent>
                      </Collapsible>
                    );
                  })
                : null}
            </div>
          ) : null}
          {/* course summit */}
          <CourseBtnContainer>
            <CancelAddCourseButton
              onClick={() => navigate("/internal/courses")}
            >
              <CancelAddCourseText>Cancel</CancelAddCourseText>
            </CancelAddCourseButton>
            <SaveCourseButton
              onClick={() => handleCheckAllAccordionInputs()}
              // onClick={() => handleGetAllFileVideosToUpload()}
              // onClick={() => handleSaveCourse()}
            >
              <SaveCourseText>Save and next</SaveCourseText>
            </SaveCourseButton>
          </CourseBtnContainer>
        </InnerContainer>
      </OuterContainer>
    </div>
  );
};

export default CoursesForm;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const CollapsibleTrigger = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  font-size: 20px;
  padding: 12px 30px;
  font-weight: bold;
  color: #007c7c;
  margin: 5px -30px;
  user-select: none;
  :hover {
    background: #f3f3f3;
    opacity: 0.8;
  }
`;

const CollapsibleTriggerForCourseSummit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  padding: 12px 0;
  font-weight: bold;
  color: #007c7c;
  margin: 5px 0px;
  user-select: none;
  border-bottom: 1px solid #00bb99;
`;

const CollapsibleContent = styled.div`
  padding: 10px 0;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  :focus {
    outline: none;
    border-width: 3px;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const TextArea = styled.textarea`
  background: ${({ isFilled, disabled }) =>
    isFilled || disabled ? "#D7FFFF" : "#FFFFFF"};
  width: 100%;
  border: ${({ is_error }) =>
    is_error ? "2px solid #EB4335" : "2px solid #00BB99"};
  padding: 10px 15px;
  border-radius: 10px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;

const BackNavigation = styled.p`
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const PageTitle = styled.h1`
  text-transform: capitalize;
  font-size: 36px;
  color: #00bb99;
`;

const CourseBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 5em;
  padding-bottom: 20px;
  gap: 20px;
`;

const SaveCourseButton = styled.button`
  display: flex;
  background: #00bb99;
  border-radius: 7px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
`;

const CancelAddCourseButton = styled.button`
  display: flex;
  background: #f8f8f8;
  border-radius: 7px;
  border: 1px solid #000000;
  padding: 0.4em 1em;
  transition: 0.2s;
`;

const SaveCourseText = styled.span`
  color: #ffffff;
`;

const CancelAddCourseText = styled.span`
  color: #000000;
`;

const Label = styled.h5`
  font-size: 14px;
  color: #00bb99;
`;

const Text = styled.p`
  font-size: 18px;
  color: black;
`;

const SaveInputContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
