import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import GreenButton from "../components/button/GreenButton";

// lib
import fontSize from "../lib/styling/font_size";

// Style
import "../styles/headerComponent.css";

// Images & Icons
import search_icon from "../assets/icons/search_icon.png";
import notification_icon from "../assets/icons/notification_icon.png";
import account_round_icon from "../assets/icons/account_round_icon.png";

// Apis
import { logoutUser } from "../apis/authentications";
import {
  getAllUsersNotifications,
  updateUsersNotificationIsReadStatus,
} from "../apis/usersNotifications";

// reducers
import {
  SET_USERS_NOTIFICATIONS,
  SET_USER_DETAIL,
  SET_SEARCH_KEY,
} from "../store/actions";

// Cookies
import Cookies from "js-cookie";

// sweetalert
import Swal from "sweetalert2";

// components
import ModalNotification from "./ModalNotification";

function HeaderComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isInternal = useSelector((state) => state.isInternal);
  const userDetail = useSelector((state) => state.userDetail);
  const userRole = useSelector((state) => state.userRole);
  const usersNotifications = useSelector((state) => state.usersNotifications);
  const [isOpenNotificationPopUpModal, setIsOpenNotificationPopUpModal] =
    useState(false);

  async function handleSignout() {
    const result = await logoutUser();
    if (result && result.data && result.data.success) {
      Cookies.remove("ut");
      dispatch(SET_USER_DETAIL(null));
      navigate("/login");
    }
  }

  const handleOnClickAccountProfileIcon = (
    isInternal,
    userRole,
    userDetail
  ) => {
    if (!isInternal) {
      navigate("/profile");
    } else {
      if (userRole === "Mentor") {
        const { mentor } = userDetail;
        if (mentor && mentor.id) {
          navigate(`/internal/mentors/${mentor.id}`);
        } else {
          // kalau mentornya yang login belum terdaftar jadi mentor seharusnya gimana? - Handson
          return null;
        }
      } else {
        // kalo admin profile account icon ini buat apa? - Handson
        return null;
      }
    }
  };

  const handleOnClickBtnBookSession = (userDetail) => {
    if (userDetail && userDetail.id) {
      const { is_validated, is_new_user, user_details } = userDetail || {};
      if (is_validated) {
        if (
          !is_new_user &&
          user_details &&
          user_details.first_name &&
          user_details.last_name &&
          user_details.gender &&
          user_details.phone_number
        ) {
          navigate("/coaching/book");
        } else {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "error",
            title: "Please fill your profile first",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          position: "top-end",
          icon: "error",
          title: "Please verify your account first",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } else {
      Swal.fire({
        customClass: {
          popup: "mentor-popup",
        },
        position: "top-end",
        icon: "error",
        title: "Please login first",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const handleFetchUsersNotifications = async () => {
    const result = await getAllUsersNotifications();
    dispatch(SET_USERS_NOTIFICATIONS(result.data));
  };

  const handleOnClickNotificationBtn = async (isOpenNotificationPopUp) => {
    let isAllNotificationHaveNotBeenRead = usersNotifications.some(
      (usersNotification) => usersNotification.is_read === false
    );
    if (isOpenNotificationPopUp && isAllNotificationHaveNotBeenRead) {
      // update all user's notification into all read
      await updateUsersNotificationIsReadStatus();
    } else {
      await handleFetchUsersNotifications();
    }
    setIsOpenNotificationPopUpModal(!isOpenNotificationPopUp);
  };

  const handleOnSearch = (key) => {
    dispatch(SET_SEARCH_KEY(key));
  };

  useEffect(() => {
    handleFetchUsersNotifications();
  }, []);

  return (
    <div>
      <div className="headerComponentMainContainer">
        <div
          className="headerComponentSearchContainer"
          style={{
            border:
              userDetail && userDetail.is_admin === true
                ? "1px solid #00bb99"
                : "1px solid #2c65f7",
          }}
        >
          <img src={search_icon} alt="search_icon" />
          <input
            placeholder="Search..."
            onChange={({ target: { value } }) => handleOnSearch(value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            height: "40px",
          }}
        >
          {!userDetail ||
          (userDetail && userDetail.is_admin === true) ? null : (
            <GreenButton
              width="200px"
              height="auto"
              textSize={fontSize.title2}
              text="Book a session"
              action={() => handleOnClickBtnBookSession(userDetail)}
              cursor="pointer"
              bgcolor={
                userDetail && userDetail.is_admin === false ? "#2c65f7" : null
              }
            />
          )}
          <img
            className="headerComponentNotificationIcon"
            src={notification_icon}
            alt="notification_icon"
            onClick={() =>
              handleOnClickNotificationBtn(isOpenNotificationPopUpModal)
            }
          />
          <div style={{ textAlign: "center" }}>
            {/* temporary disable for internal because no content provided */}
            <div
              onClick={() =>
                handleOnClickAccountProfileIcon(
                  isInternal,
                  userRole,
                  userDetail
                )
              }
              style={{ cursor: "pointer" }}
            >
              <img
                src={account_round_icon}
                className="headerComponentAccountIcon"
                alt="account_round_icon"
              />
            </div>
            <div
              onClick={handleSignout}
              className="headerComponentAccountActionText"
              style={{ fontSize: fontSize.title4, cursor: "pointer" }}
            >
              Log out
            </div>
          </div>
        </div>
      </div>
      {isOpenNotificationPopUpModal ? (
        <ModalNotification
          usersNotifications={usersNotifications}
          navigate={navigate}
        />
      ) : null}
    </div>
  );
}

export default HeaderComponent;
