import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";

import styled from "styled-components";

// Axios
import axios from "axios";

// Sweetalert
import Swal from "sweetalert2";

import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// Components
import SpinnerComponent from "../SpinnerComponent";
import AlertComponent from "../AlertComponent";
import GreenButton from "../button/GreenButton";
import ReactDatePicker from "../input/DatePicker";
import RadioButton from "../input/RadioButton";
import CustomDropdown from "../input/CustomDropdown";
import DropdownSearch from "../input/DropdownSearch";
import Tooltip from "../tooltip/Tooltip";

// Images & Icons
import info_icon from "../../assets/icons/info_icon.png";
import success_icon from "../../assets/icons/success_icon.svg";
import danger_icon from "../../assets/icons/danger_icon.svg";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
// import router from "next/router";

// Lib
import fontSize from "../../lib/styling/font_size";

// Helpers
import getServerURL from "../../helpers/get-server-url";

// Styles
import "../../styles/userForm.css";

// Cookies
import Cookies from "js-cookie";

// Apis
import {
  logoutUser,
  updatePasswordFromProfile,
  updateUserDetail,
} from "../../apis/authentications";

// store
import { SET_USER_DETAIL } from "../../store/actions";

function UserForm({
  user,
  isNewUser,
  setIsNewUser,
  mutateUser,
  industries,
  countries,
  occupations,
  degrees,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    register,
    control,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      first_name: user?.user_details?.first_name,
      last_name: user?.user_details?.last_name,
      place_of_birth: user?.user_details?.place_of_birth,
      date_of_birth: user?.user_details?.date_of_birth,
      gender: user?.user_details?.gender,
      company_name: user?.user_details?.company_name,
      phone_number: user?.user_details?.phone_number,
      industry_id: user?.user_details?.industry_id,
      address: user?.user_details?.address,
      occupation_id: user?.user_details?.occupation_id,
      country_id: user?.user_details?.country_id,
      postal_code: user?.user_details?.postal_code,
      degree_id: user?.user_details?.degree_id,
      city: user?.user_details?.city,
      province: user?.user_details?.province,
      university: user?.user_details?.university,
    },
  });

  const handleError = (errors) => {};

  const formOptions = {
    first_name: {
      required: "First name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    last_name: {
      required: "Last name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    place_of_birth: {
      required: "Place of birth is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    date_of_birth: {
      required: "Date of birth is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    gender: {
      required: "Gender is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    company_name: {
      required: "Company name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    phone_number: {
      required: "Phone number is required",
      pattern: {
        value: /^\d+$/,
        message: "Invalid phone number",
      },
      maxLength: {
        value: 16,
        message: "Can't be longer than 16 characters",
      },
    },
    industry_id: {
      required: "Industry is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    address: {
      required: "Address is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    occupation_id: {
      required: "Role/position is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    country_id: {
      required: "Country is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    postal_code: {
      required: "Postal Code is required",
      pattern: {
        value: /^\d+$/,
        message: "Invalid postal code",
      },
      maxLength: {
        value: 5,
        message: "Can't be longer than 5 characters",
      },
    },
    degree_id: {
      required: "Highest degree is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    city: {
      required: "City is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    province: {
      required: "State/Province is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    university: {
      required: "University is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  const [isInitialValueEmpty, setIsInitialValueEmpty] = useState(
    user?.user_details === null ||
      user?.user_details === undefined ||
      Object.values(user?.user_details).some(
        (value) => value === null || value === undefined
      )
  );
  const [isEditingProfile, setIsEditingProfile] = useState(false);
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [isChangingEmail, setChangingEmail] = useState(false);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");
  const [isChangingPassword, setChangingPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isNewUser) {
      setIsEditingProfile(true);
    } else {
      setIsEditingProfile(false);
    }
  }, [isNewUser]);

  async function handleUpdateProfile(data) {
    setIsLoading(true);
    try {
      const res = await updateUserDetail(data);
      if (res && res.data) {
        setSuccess("Profile updated successfully");
        setIsEditingProfile(false);
        setIsNewUser(false);
      }
    } catch (error) {
      setError(error.response.data.message);
    }
    setIsLoading(false);
  }

  function showAlert(text, type) {
    if (type === "success") {
      setSuccess(text);
      setTimeout(() => {
        setSuccess("");
      }, 2000);
    } else {
      setError(text);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  }

  function checkRepeatPassword() {
    if (password && repeatPassword && password === repeatPassword) {
      return true;
    } else {
      return false;
    }
  }

  async function logout() {
    try {
      setIsLoading(true);
      const result = await logoutUser();
      if (result && result.data && result.data.success) {
        setIsLoading(false);
        Cookies.remove("ut");
        dispatch(SET_USER_DETAIL(null));
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      showAlert(error.response.data.message, "error");
    }
  }

  function handleUpdateEmail() {
    Swal.fire({
      title: "Change your email?",
      text: "Please confirm your password",
      input: "password",
      icon: "question",
      confirmButtonText: "Confirm",
      showLoaderOnConfirm: true,
      showCloseButton: true,
      preConfirm: (input) => {
        if (input) {
          return axios
            .post(`${getServerURL()}/users/login`, {
              email: user?.email,
              password: input,
            })
            .then(() => {
              return true;
            })
            .catch((err) => {
              Swal.showValidationMessage(err.response.data.message);
            });
        } else {
          Swal.showValidationMessage("Please input your password");
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Please wait",
          text: "We are processing your request",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            axios
              .post(`${getServerURL()}/users/update/email`, {
                prev_email: user?.email,
                new_email: newEmailAddress,
              })
              .then(() => {
                Swal.fire({
                  text: "You have successfully changed your email",
                  imageUrl: "assets/icons/email_password_update_success.png",
                  imageHeight: 185,
                  imageWidth: 255,
                  showCloseButton: true,
                }).then(() => {
                  logout();
                });
              })
              .catch((err) => {
                Swal.fire({
                  title: "Error",
                  text: err.response.data.message,
                  icon: "error",
                  showCloseButton: true,
                });
              });
          },
        });
      }
    });
  }

  function handleUpdatePassword() {
    if (checkRepeatPassword()) {
      Swal.fire({
        title: "Change your password?",
        text: "Please confirm your current password",
        input: "password",
        icon: "question",
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
        showCloseButton: true,
        preConfirm: (input) => {
          if (input) {
            return axios
              .post(`${getServerURL()}/users/login`, {
                email: user?.email,
                password: input,
              })
              .then(() => {
                return true;
              })
              .catch((err) => {
                Swal.showValidationMessage(err.response.data.message);
              });
          } else {
            Swal.showValidationMessage("Please input your password");
          }
        },
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Please wait",
            text: "We are processing your request",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              updatePasswordFromProfile({ password })
                .then(() => {
                  Swal.fire({
                    text: "You have successfully changed your password",
                    imageUrl: "assets/icons/email_password_update_success.png",
                    imageHeight: 185,
                    imageWidth: 255,
                    showCloseButton: true,
                  }).then(() => {
                    setPassword("");
                    setRepeatPassword("");
                    setChangingPassword(false);
                  });
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Error",
                    text: err.response.data.message,
                    icon: "error",
                  });
                });
            },
          });
        }
      });
    } else {
      showAlert("Password did not match", "error");
    }
  }

  return (
    <div>
      <SpinnerComponent isShown={isLoading} right="3vw" top="20vh" />
      <AlertComponent
        isShown={error ? true : false}
        variant="danger"
        text={error}
      />
      <AlertComponent
        isShown={success ? true : false}
        variant="success"
        text={success}
      />
      <div className="userFormHeaderContainer">
        <div
          style={{
            fontSize: fontSize.title1,
            color: user && user.is_admin === false ? "#2c65f7" : "#00BB99",
            fontWeight: "bold",
          }}
        >
          Hello, welcome back!
        </div>
        {!isEditingProfile && (
          <div>
            <div
              style={{
                display: "flex",
                gap: "0.5em",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "0.5em",
              }}
            >
              <img
                src={user && user.is_validated ? success_icon : danger_icon}
                className="alertComponentImage"
                alt="check_icon"
                height={20}
                width={20}
              />
              <div
                style={{
                  fontSize: fontSize.title3,
                  fontWeight: "500",
                }}
              >
                {user && user.is_validated ? "VERIFIED" : "NOT VERIFIED"}
              </div>
            </div>
            <GreenButton
              action={() => setIsEditingProfile(true)}
              width="130px"
              height="30px"
              textSize={fontSize.title3}
              text="Edit Profile"
              bgcolor="#2c65f7"
            />
          </div>
        )}
      </div>
      {isInitialValueEmpty && (
        <div
          className="useFormHeaderInitialValueEmptyContainer"
          style={{
            backgroundColor:
              user && user.is_admin === false ? "#e7eeff" : "#d7ffff",
          }}
        >
          <img src={info_icon} alt="info_icon" />
          <div
            style={{
              fontSize: fontSize.title3,
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            Thank you for joining Combined Clinics Sustainability! Before
            getting started, please complete your profile below.
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(handleUpdateProfile, handleError)}>
        <Row className="userFormRowMainContainer">
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  First Name
                </div>
                <input
                  id="first_name"
                  name="first_name"
                  autoComplete="off"
                  type="text"
                  placeholder="e.g: John"
                  {...register("first_name", formOptions.first_name)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("first_name")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.first_name
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    display: "flex",
                    textAlign: "start",
                    userSelect: "none",
                  }}
                >
                  {errors?.first_name && errors.first_name.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Last Name
                </div>
                <input
                  id="last_name"
                  name="last_name"
                  autoComplete="off"
                  type="text"
                  placeholder="e.g: Doe"
                  {...register("last_name", formOptions.last_name)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("last_name")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.last_name
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.last_name && errors.last_name.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Place of Birth
                </div>
                <input
                  id="place_of_birth"
                  name="place_of_birth"
                  autoComplete="off"
                  type="text"
                  placeholder="e.g: Jakarta"
                  {...register("place_of_birth", formOptions.place_of_birth)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("place_of_birth")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.place_of_birth
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.place_of_birth && errors.place_of_birth.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Date of Birth
                </div>
                <Controller
                  control={control}
                  name="date_of_birth"
                  rules={formOptions.date_of_birth}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      disabled={!isEditingProfile}
                      date={value}
                      handleDate={onChange}
                      format="dd/MM/yyyy"
                      fromprofile={true}
                      error={errors?.date_of_birth}
                      user_detail={user}
                    />
                  )}
                />
                <small className="text-danger" style={{ userSelect: "none" }}>
                  {errors?.date_of_birth && errors.date_of_birth.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Gender
                </div>
                <Controller
                  control={control}
                  name="gender"
                  rules={formOptions.gender}
                  render={({ field: { onChange, value } }) => (
                    <RadioButton
                      name="gender"
                      data={["male", "female"]}
                      state={value}
                      handleChange={onChange}
                      isFilled={Boolean(getValues("gender"))}
                      disabled={!isEditingProfile}
                      user_detail={user}
                    />
                  )}
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.gender && errors.gender.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Company Name
                </div>
                <input
                  id="company_name"
                  name="company_name"
                  autoComplete="off"
                  type="text"
                  placeholder="e.g: ABC Company"
                  {...register("company_name", formOptions.company_name)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("company_name")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.company_name
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.company_name && errors.company_name.message}
                </small>
              </Form.Group>
            </div>
          </Col>

          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Phone Number
                </div>
                <Controller
                  control={control}
                  name="phone_number"
                  rules={formOptions.phone_number}
                  render={({ field: { onChange, value } }) => (
                    <input
                      id="phone_number"
                      name="phone_number"
                      autoComplete="off"
                      value={value}
                      onChange={onChange}
                      type="text"
                      placeholder="e.g: 08xxxxx"
                      disabled={!isEditingProfile}
                      style={{
                        background:
                          Boolean(getValues("phone_number")) ||
                          !isEditingProfile
                            ? `${
                                user && user.is_admin === false
                                  ? "#e7eeff"
                                  : "#D7FFFF"
                              }`
                            : "#FFFFFF",
                        border:
                          errors && errors.phone_number
                            ? "2px solid #EB4335"
                            : `2px solid ${
                                user && user.is_admin === false
                                  ? "#2c65f7"
                                  : "#00BB99"
                              }`,
                      }}
                      className="userFormInputStyle"
                    />
                  )}
                />

                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.phone_number && errors.phone_number.message}
                </small>
              </Form.Group>
            </div>
          </Col>

          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Industry
                </div>
                <Controller
                  control={control}
                  name="industry_id"
                  rules={formOptions.industry_id}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      isError={errors?.industry_id}
                      id="industry_id"
                      name="industry_id"
                      autoComplete="off"
                      value={value}
                      placeholder="Select one related industry"
                      options={industries}
                      action={onChange}
                      isSelected={
                        Boolean(getValues("industry_id")) || !isEditingProfile
                      }
                      inputFilledBackgroundColor="#D7FFFF"
                      fromprofile={true}
                      disabled={!isEditingProfile}
                      user_detail={user}
                      selectedBackgroundColor={
                        user && user.is_admin === false ? "#e7eeff" : ""
                      }
                    />
                  )}
                />
                <small className="text-danger" style={{ userSelect: "none" }}>
                  {errors?.industry_id && errors.industry_id.message}
                </small>
              </Form.Group>
            </div>
          </Col>

          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Address
                </div>
                <TextArea
                  rows={5}
                  id="address"
                  name="address"
                  autoComplete="off"
                  error={errors?.address}
                  type="text"
                  placeholder="e.g: Sudirman street block 88 unit S"
                  isFilled={Boolean(getValues("address"))}
                  disabled={!isEditingProfile}
                  {...register("address", formOptions.address)}
                  user_detail={user}
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.address && errors.address.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Role / Position
                </div>
                <Controller
                  control={control}
                  name="occupation_id"
                  rules={formOptions.occupation_id}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      isError={errors?.occupation_id}
                      id="occupation_id"
                      name="occupation_id"
                      value={value}
                      placeholder="Select one role/position"
                      options={occupations}
                      action={onChange}
                      isSelected={
                        Boolean(getValues("occupation_id")) || !isEditingProfile
                      }
                      inputFilledBackgroundColor="#D7FFFF"
                      fromprofile={true}
                      disabled={!isEditingProfile}
                      user_detail={user}
                      selectedBackgroundColor={
                        user && user.is_admin === false ? "#e7eeff" : ""
                      }
                    />
                  )}
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.occupation_id && errors.occupation_id.message}
                </small>
              </Form.Group>
            </div>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Highest Degree
                </div>
                <Controller
                  control={control}
                  name="degree_id"
                  rules={formOptions.degree_id}
                  render={({ field: { onChange, value } }) => (
                    <CustomDropdown
                      isError={errors?.degree_id}
                      id="degree_id"
                      name="degree_id"
                      value={value}
                      placeholder="Choose your highest degree"
                      options={degrees}
                      action={onChange}
                      isSelected={
                        Boolean(getValues("degree_id")) || !isEditingProfile
                      }
                      inputFilledBackgroundColor="#D7FFFF"
                      fromprofile={true}
                      disabled={!isEditingProfile}
                      user_detail={user}
                      selectedBackgroundColor={
                        user && user.is_admin === false ? "#e7eeff" : ""
                      }
                    />
                  )}
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.degree_id && errors.degree_id.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Country
                </div>
                <Controller
                  control={control}
                  name="country_id"
                  rules={formOptions.country_id}
                  render={({ field: { onChange, value } }) => (
                    <DropdownSearch
                      name="country_id"
                      datalist={countries}
                      value={value}
                      onChange={onChange}
                      keyToShow="name"
                      keyToSend="id"
                      placeholder="Select your country"
                      disabled={!isEditingProfile}
                      error={errors?.country_id}
                      user_detail={user}
                    />
                  )}
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.country_id && errors.country_id.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  Postal Code
                </div>
                <input
                  id="postal_code"
                  name="postal_code"
                  autoComplete="off"
                  type="text"
                  placeholder="Input postal code"
                  {...register("postal_code", formOptions.postal_code)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("postal_code")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.postal_code
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.postal_code && errors.postal_code.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  University
                </div>
                <input
                  id="university"
                  name="university"
                  autoComplete="off"
                  type="text"
                  placeholder="Input university"
                  {...register("university", formOptions.university)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("university")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.university
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.university && errors.university.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  City
                </div>
                <input
                  id="city"
                  name="city"
                  autoComplete="off"
                  type="text"
                  placeholder="Input city"
                  {...register("city", formOptions.city)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("city")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.city
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.city && errors.city.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={3}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div
                  className="userFormInputLabel"
                  style={{
                    fontSize: fontSize.title2,
                  }}
                >
                  State/Province
                </div>
                <input
                  id="province"
                  name="province"
                  autoComplete="off"
                  type="text"
                  placeholder="Input state/province"
                  {...register("province", formOptions.province)}
                  disabled={!isEditingProfile}
                  style={{
                    background:
                      Boolean(getValues("province")) || !isEditingProfile
                        ? `${
                            user && user.is_admin === false
                              ? "#e7eeff"
                              : "#D7FFFF"
                          }`
                        : "#FFFFFF",
                    border:
                      errors && errors.province
                        ? "2px solid #EB4335"
                        : `2px solid ${
                            user && user.is_admin === false
                              ? "#2c65f7"
                              : "#00BB99"
                          }`,
                  }}
                  className="userFormInputStyle"
                />
                <small
                  className="text-danger"
                  style={{
                    userSelect: "none",
                    display: "flex",
                    textAlign: "start",
                  }}
                >
                  {errors?.province && errors.province.message}
                </small>
              </Form.Group>
            </div>
          </Col>
          <Col md={6}> </Col>
          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div className="userFormInputGroupFlexContainer">
                  <div
                    className="userFormInputLabel"
                    style={{
                      fontSize: fontSize.title2,
                    }}
                  >
                    {isChangingEmail ? "New email address" : "Email"}
                  </div>
                  {isChangingEmail ? (
                    <CancelText onClick={() => setChangingEmail(false)}>
                      Cancel
                    </CancelText>
                  ) : (
                    <InputAction
                      onClick={() => setChangingEmail(true)}
                      user_detail={user}
                    >
                      Change
                    </InputAction>
                  )}
                </div>
                {isChangingEmail ? (
                  <div className="userFormInputGroupFlexContainer">
                    <input
                      style={{
                        width: "60%",
                        background: "#FFFFFF",
                        border: `2px solid ${
                          user && user.is_admin === false
                            ? "#2c65f7"
                            : "#00BB99"
                        }`,
                      }}
                      value={newEmailAddress}
                      onChange={(e) => setNewEmailAddress(e.target.value)}
                      type="email"
                      placeholder="e.g: john@example.com"
                      className="userFormInputStyle"
                    />
                    {newEmailAddress !== "" ? (
                      <GreenButton
                        action={handleUpdateEmail}
                        width="130px"
                        height="30px"
                        textSize={fontSize.title3}
                        text="Update"
                        bgcolor={
                          newEmailAddress
                            ? `${
                                user && user.is_admin === false
                                  ? "#2c65f7"
                                  : "#00BB99"
                              }`
                            : "#AAAAAA"
                        }
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="userFormInputGroupFlexContainer">
                    <div
                      style={{
                        fontSize: fontSize.title3,
                        fontWeight: "400",
                      }}
                    >
                      Your email address is
                      <b> {user?.email}</b>
                    </div>
                  </div>
                )}
              </Form.Group>
            </div>
          </Col>
          <Col md={6}></Col>

          <Col md={6}>
            <div className="userFormInputGroup">
              <Form.Group>
                <div className="userFormInputGroupFlexContainer">
                  <div
                    className="userFormInputLabel"
                    style={{
                      fontSize: fontSize.title2,
                    }}
                  >
                    {isChangingPassword ? (
                      <>
                        <span>New password</span>
                        <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
                      </>
                    ) : (
                      <span>Password</span>
                    )}
                  </div>
                  {isChangingPassword ? (
                    <CancelText onClick={() => setChangingPassword(false)}>
                      Cancel
                    </CancelText>
                  ) : (
                    <InputAction
                      onClick={() => setChangingPassword(true)}
                      user_detail={user}
                    >
                      Change
                    </InputAction>
                  )}
                </div>
                {isChangingPassword ? (
                  <div>
                    <div
                      className="userFormInputGroupFlexContainer"
                      style={{ marginBottom: "10px" }}
                    >
                      <div
                        style={{
                          position: "relative",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <input
                          style={{
                            width: "100%",
                            background: "#FFFFFF",
                            border: `2px solid ${
                              user && user.is_admin === false
                                ? "#2c65f7"
                                : "#00BB99"
                            }`,
                          }}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          type={passwordType}
                          placeholder="New password"
                          className="userFormInputStyle"
                        />

                        <input
                          autoComplete="off"
                          style={{ display: "none" }}
                          id="show1"
                          type="checkbox"
                          checked={passwordType === "text"}
                          onChange={() =>
                            setPasswordType((prev) =>
                              prev === "password" ? "text" : "password"
                            )
                          }
                        />
                        <label
                          htmlFor="show1"
                          style={{
                            position: "absolute",
                            right: 0,
                            margin: "0 12px",
                            cursor: "pointer",
                          }}
                        >
                          {passwordType === "password" ? (
                            <AiFillEye size={25} />
                          ) : (
                            <AiFillEyeInvisible size={25} />
                          )}
                        </label>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          marginTop:
                            !repeatPassword || checkRepeatPassword()
                              ? null
                              : "1.3em",
                        }}
                      >
                        <div
                          style={{
                            position: "relative",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <input
                            style={{
                              width: "100%",
                              background: "#FFFFFF",
                              border: `2px solid ${
                                user && user.is_admin === false
                                  ? "#2c65f7"
                                  : "#00BB99"
                              }`,
                            }}
                            value={repeatPassword}
                            onChange={(e) => setRepeatPassword(e.target.value)}
                            type={repeatPasswordType}
                            placeholder="Repeat password"
                            className="userFormInputStyle"
                          />
                          <input
                            autoComplete="off"
                            style={{ display: "none" }}
                            id="show2"
                            type="checkbox"
                            checked={repeatPasswordType === "text"}
                            onChange={() =>
                              setRepeatPasswordType((prev) =>
                                prev === "password" ? "text" : "password"
                              )
                            }
                          />
                          <label
                            htmlFor="show2"
                            style={{
                              position: "absolute",
                              right: 0,
                              margin: "0 12px",
                              cursor: "pointer",
                            }}
                          >
                            {repeatPasswordType === "password" ? (
                              <AiFillEye size={25} />
                            ) : (
                              <AiFillEyeInvisible size={25} />
                            )}
                          </label>
                        </div>
                        <small
                          className="text-danger"
                          style={{
                            userSelect: "none",
                            display: "flex",
                            textAlign: "left",
                          }}
                        >
                          {!repeatPassword || checkRepeatPassword()
                            ? null
                            : "Password not match"}
                        </small>
                      </div>
                    </div>
                    <GreenButton
                      action={handleUpdatePassword}
                      width="130px"
                      height="30px"
                      textSize={fontSize.title3}
                      text="Update"
                      bgcolor={
                        password && repeatPassword
                          ? `${
                              user && user.is_admin === false
                                ? "#2c65f7"
                                : "#00BB99"
                            }`
                          : "#AAAAAA"
                      }
                    />
                  </div>
                ) : null}
              </Form.Group>
            </div>
          </Col>
          <Col md={6}></Col>
        </Row>

        {isEditingProfile && (
          <div className="userFormBtnMainContainer">
            <button
              type="reset"
              className="userFormBtnCancel"
              onClick={() => window.location.reload()}
            >
              Cancel
            </button>
            {user && user.is_admin === false ? (
              <button
                disabled={!isValid || isSubmitting}
                type="submit"
                className={
                  !isValid || isSubmitting
                    ? "userFormBtnSaveDisabledForStudent"
                    : "userFormBtnSaveForStudent"
                }
                style={{}}
              >
                Save
              </button>
            ) : (
              <button
                disabled={!isValid || isSubmitting}
                type="submit"
                className={
                  !isValid || isSubmitting
                    ? "userFormBtnSaveDisabled"
                    : "userFormBtnSave"
                }
                style={{}}
              >
                Save
              </button>
            )}
          </div>
        )}
      </form>
    </div>
  );
}

export default UserForm;

const TextArea = styled.textarea`
  background: ${({ isFilled, disabled, user_detail }) =>
    isFilled || disabled
      ? `${
          user_detail && user_detail.is_admin === false ? "#e7eeff" : "#D7FFFF"
        }`
      : "#FFFFFF"};
  width: 100%;
  border: ${({ error, user_detail }) =>
    error
      ? "2px solid #EB4335"
      : `2px solid ${
          user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00BB99"
        }`};
  padding: 10px 15px;
  border-radius: 10px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;

const CancelText = styled.div`
  font-size: ${fontSize.title4};
  text-align: center;
  color: #404040;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;

const InputAction = styled.div`
  font-size: ${fontSize.title4};
  color: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00bb99"};
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
    font-weight: bold;
  }
`;
