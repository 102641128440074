import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// date fns
import { format } from "date-fns";

// react-bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Apis
import { getAllTopics } from "../apis/topics";
import {
  getDetailInternalBooking,
  updateSession,
} from "../apis/internalApis/sessions";

// helpers
import getServerURL from "../helpers/get-server-url";

// sweetalert
import Swal from "sweetalert2";

// components
import {
  tabs,
  entreformInitialState,
} from "../components/coaching/book/bookingForm";
import SpinnerComponent from "../components/SpinnerComponent";
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import StatusComponent from "../components/StatusComponent";
import EntrepreneurshipForm from "../components/coaching/book/EntrepreneurshipForm";
import RouteChangeModal from "../components/RouteChangeModal";

const SessionDetail = () => {
  const { session_id } = useParams();

  const userDetail = useSelector((state) => state.userDetail);

  const [session, setSession] = useState(null);
  const [topics, setTopics] = useState([]);

  const [topicTitle, setTopicTitle] = useState("");
  const [isTopicEntrepreneurship, setIsTopicEntrepreneurship] = useState(false);

  const [form, setForm] = useState(entreformInitialState);
  const [entreTopic, setEntreTopic] = useState(null);
  const [formDisabled, setFormDisabled] = useState(true);
  const [isRouteChange, setIsRouteChange] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);

  function handleChange(e, key) {
    setFormChanged(true);
    if (e.target.value !== "") {
      setForm({
        ...form,
        [key]: { ...form[key], value: e.target.value, error: false },
      });
    } else {
      if (form[key].required) {
        setForm({
          ...form,
          [key]: { ...form[key], value: e.target.value, error: true },
        });
      } else {
        setForm({
          ...form,
          [key]: { ...form[key], value: e.target.value, error: false },
        });
      }
    }
  }

  function handleChangeDropdown(value, key) {
    setFormChanged(true);
    setForm({ ...form, [key]: { ...form[key], value: value, error: false } });
  }

  function isEntreFormAllFilled(form) {
    let isFilled = true;
    Object.keys(form).forEach((key) => {
      if (form[key].required && form[key].value === "") {
        isFilled = false;
      }
    });
    return isFilled;
  }

  async function handleOnClicKEdit(e, index, formData) {
    e.stopPropagation();
    if (formDisabled) {
      setFormChanged(false);
      setFormDisabled((prev) => !prev);
    } else {
      if (isFormChanged) {
        if (isEntreFormAllFilled(form)) {
          setLoading(true);
          setFormDisabled((prev) => !prev);

          const status = await updateForm();
          if (status === 200) {
            Swal.fire({
              customClass: {
                popup: "mentor-popup",
              },
              imageUrl:
                "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
              position: "top-end",
              text: "Successfully updated session",
              width: "20em",
              heightAuto: "0.5em",
              showConfirmButton: false,
              timer: 1500,
            });
            setLoading(false);
            window.location.reload();
          }
        }
      } else {
        setFormDisabled((prev) => !prev);
      }
    }
  }

  function handleCancelButtonClicked() {
    setIsRouteChange(true);
  }

  async function handleButtonPrimaryRouteChange() {
    try {
      setLoading(true);
      const res = await updateSession(session.id, {
        ...session,
        status: "Cancelled",
      });
      if (res && res.status === 200) {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "Successfully cancelled session",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: error.response.data.message.toString(),
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  function handleButtonSecondaryRouteChange() {
    setIsRouteChange(false);
  }

  function openDocument() {
    function checkPlatform() {
      const isMac = navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;
      if (isMac) return "/";
      return "\\";
    }
    const url = getServerURL();
    return window.open(
      url +
        session["document"]?.split(checkPlatform())?.[1] +
        checkPlatform() +
        session["document"]?.split(checkPlatform())?.[2]
    );
  }

  async function updateForm() {
    setLoading(true);
    let data = {
      form_data: {
        current_challenges: "",
      },
    };

    if (form.topic.value === entreTopic.id) {
      data.topic_id = form.topic.value;
      Object.keys(form).forEach((v) => {
        if (v !== "topic" && v !== "id") {
          data.form_data[v] = form[v].value;
        }
      });
      try {
        const { updatedAt, ...sessionToSubmit } = session;
        const response = await updateSession(session.id, {
          ...sessionToSubmit,
          ...data,
        });
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "Successfully updated session",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
        return response.status;
      } catch (error) {
        setLoading(false);
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: error && error.toString(),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  }

  const data = [
    {
      dataKey: "Booking ID",
      dataValue: session && session.booking_id ? session.booking_id : "-",
    },
    {
      dataKey: "Topic of interest",
      dataValue: topicTitle || "-",
    },
    {
      dataKey: "Session date/time",
      dataValue:
        session && session.scheduled_date
          ? format(new Date(session.scheduled_date), "dd/LL/yy HH:mm")
          : "-",
    },
    {
      dataKey: "Assigned mentor",
      dataValue:
        session && session.mentor
          ? `${session.mentor.first_name} ${session.mentor.last_name}`
          : "-",
    },
  ];

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      let finalTopics = result.data;
      setTopics(finalTopics);
      const finalTopicTitle = finalTopics.filter(
        (topic) => topic.id === session.topic_id
      )[0]?.title;
      setTopicTitle(finalTopicTitle);
      const isTopicEntrepreneurship =
        session.topic_id ===
        finalTopics.filter((topic) => topic.required_documents.length > 0)[0]
          ?.id;
      setIsTopicEntrepreneurship(isTopicEntrepreneurship);
      const topic = topics.filter(
        (topic) => topic.required_documents.length > 0
      );
      setEntreTopic(topic[0]);
    }
    setLoading(false);
  };

  const handleGetDetailSession = async (session_id) => {
    setLoading(true);
    const result = await getDetailInternalBooking(session_id);
    if (result && result.data) {
      const { data } = result || {};
      setSession(data.session);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (session_id) {
      handleGetDetailSession(session_id);
    }
  }, [session_id]);

  useEffect(() => {
    if (session && session.topic_id) {
      handleFetchTopics();
    }
  }, [session]);

  useEffect(() => {
    if (session && isTopicEntrepreneurship) {
      let updatedState = form;

      Object.keys(form).forEach((key) => {
        if (key === "topic") {
          if (session && session.topic_id) {
            updatedState[key].value = session.topic_id;
          }
        } else {
          if (session && session.form_data) {
            updatedState[key].value = session.form_data[key];
          }
        }
      });
      setForm(updatedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, isTopicEntrepreneurship]);

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      <OuterContainer>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        {session ? (
          <InnerContainer>
            <Row
              style={{
                marginBottom: 64,
              }}
            >
              <Col>
                <PageTitle user_detail={userDetail}>
                  Coaching Session Details
                </PageTitle>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <StatusComponent
                    status={session && session["status"]}
                    borderRadius="10px"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {data.map(({ dataKey, dataValue }, index) => (
                  <Row key={index}>
                    <Label user_detail={userDetail}>{dataKey}</Label>
                    <Text>{dataValue ? dataValue : "-"}</Text>
                  </Row>
                ))}
                {isTopicEntrepreneurship && (
                  <Row>
                    <Label>Document(s) uploaded</Label>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "1rem",
                      }}
                    >
                      <p
                        style={{
                          background: "#E5E5E5",
                          border: 0,
                          borderRadius: 10,
                          minWidth: 275,
                          padding: "0.5rem",
                          margin: 0,
                          fontSize: 14,
                        }}
                      >
                        {session["document"]?.split("/")[2]}
                      </p>
                      <button
                        style={{
                          background: "transparent",
                          border: 0,
                          padding: "0.5rem",
                          margin: 0,
                          fontSize: 12,
                        }}
                        onClick={openDocument}
                      >
                        View File
                      </button>
                    </div>
                  </Row>
                )}
                {
                  <Row>
                    <Label user_detail={userDetail}>External Link</Label>
                    <Text>
                      {session && session.external_link
                        ? session.external_link
                        : "-"}
                    </Text>
                  </Row>
                }
              </Col>
              <Col>
                {session && session.status === "Completed" ? (
                  <>
                    <Label user_detail={userDetail}>Feedback from Mentor</Label>
                    <Text>
                      {session && session.feedback ? (
                        session.feedback
                      ) : (
                        <span style={{ color: "#C4C4C4" }}>
                          Will be available soon
                        </span>
                      )}
                    </Text>
                  </>
                ) : (
                  isTopicEntrepreneurship && (
                    <div style={{ padding: "0 2rem" }}>
                      <EntrepreneurshipForm
                        tabs={tabs}
                        form={form}
                        handleChange={handleChange}
                        inputBackgroundColor="rgba(0, 187, 153, 0.1)"
                        isSessionPage={true}
                        handleChangeDropdown={handleChangeDropdown}
                        handleOnClicKEdit={handleOnClicKEdit}
                        formDisabled={formDisabled}
                        disableEditSaveButton={!isEntreFormAllFilled(form)}
                        isFormChanged={isFormChanged}
                      />
                    </div>
                  )
                )}
              </Col>
            </Row>
            <Buttons>
              <Button type="cancel" onClick={handleCancelButtonClicked}>
                Cancel booking
              </Button>
              {/* <Button type="reschedule">Request for reschedule</Button> */}
            </Buttons>
          </InnerContainer>
        ) : null}
      </OuterContainer>
      <RouteChangeModal
        show={isRouteChange}
        handleClose={handleButtonSecondaryRouteChange}
        body={[
          <p style={{ fontWeight: 700 }} key={0}>
            Are you sure you want to cancel this session?
          </p>,
        ]}
        closeButton={false}
        buttonPrimary="Yes"
        buttonSecondary="No"
        onPrimaryClick={handleButtonPrimaryRouteChange}
        onSecondaryClick={handleButtonSecondaryRouteChange}
      />
    </div>
  );
};

export default SessionDetail;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 5vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00bb99"};
`;

const Label = styled.h5`
  font-size: 14px;
  color: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00bb99"};
`;

const Text = styled.p`
  font-size: 18px;
  color: black;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
`;

const Button = styled.button`
  background: white;
  color: black;
  border: 1;
  border-color: ${({ type }) =>
    type === "reschedule" ? "#00BB99" : "transparent"};
  border-radius: 40px;
  border-style: solid;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
`;
