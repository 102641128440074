import React, { useState, useRef, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  SET_SCHEDULE_MODAL_TRUE,
  SET_APPROVAL_MODAL_TRUE,
  SET_DECLINE_MODAL_TRUE,
} from "../store/actions";
// import { SET_APPROVAL_MODAL_TRUE, SET_SCHEDULE_MODAL_TRUE } from 'store/slices/modal' // Redux - Method 3

// Images & Icons
import timer_icon from "../assets/icons/timer_icon.png";
import arrow_down_icon from "../assets/icons/arrow_down_icon.png";
import approved_icon from "../assets/icons/approved_icon.png";
import check_icon from "../assets/icons/check_icon.svg";
import pen_write_icon from "../assets/icons/pen_write_icon.svg";
import declined_icon from "../assets/icons/declined_icon.png";
import schedule_icon from "../assets/icons/schedule_icon.png";

// Components
import InternalDropdown from "./input/InternalDropdown";

function StatusComponent({
  status,
  border_radius,
  fontSize,
  onClickStatusRow,
  disableClick = false,
}) {
  const [button, setButton] = useState(false);
  const userRole = useSelector((state) => state.userRole);
  const isInternal = useSelector((state) => state.isInternal);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  // Open schedule modal
  function handleOnClickScheduleModal() {
    setButton(false);
    return dispatch(SET_SCHEDULE_MODAL_TRUE());
  }

  // Open approval modal
  function handleOnClickApprovalModal() {
    setButton(false);
    return dispatch(SET_APPROVAL_MODAL_TRUE());
  }

  // Open decline modal
  function handleDecline() {
    setButton(false);
    return dispatch(SET_DECLINE_MODAL_TRUE());
  }

  // Close dropdown onClick outside - 1

  const handleClickOutside = useCallback(
    (event) => {
      const { current: wrap } = wrapperRef;
      if (wrap && !wrap.contains(event.target)) {
        setButton(false);
        document.removeEventListener("mousedown", handleClickOutside);
      }
    },
    [wrapperRef]
  );

  // Dropdown handler
  function handleDropdown() {
    setButton(!button);
  }

  // Close drodpdown onClick outside - 2 -> BUG ON 17/05/2022: Can't onClick Approval
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return document.removeEventListener("mousedown", handleClickOutside);
  }, [wrapperRef, handleClickOutside]);

  switch (status) {
    case userRole === "Mentor" && "Waiting for Confirmation":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#AAAAAA"
        >
          Schedule Approval
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#007C7C"
        >
          Waiting for Confirmation
        </Status>
      );
    case "In review":
      return isInternal ? (
        <div>
          <StatusContainer disabled={disableClick} onClick={handleDropdown}>
            <Status
              onClick={onClickStatusRow}
              ref={wrapperRef}
              fontSize={fontSize}
              border_radius={border_radius}
              border={button ? "1px solid #00BB99" : "none"}
              justify_content="flex-start"
              font_color="#D96800"
              background="#FFF2DE"
            >
              <img
                src={timer_icon}
                alt="timer_icon"
                height={15}
                width={15}
                style={{
                  objectFit: "scale-down",
                }}
              />
              Awaiting approval
              <img
                src={arrow_down_icon}
                alt="arrow_down_icon"
                height={15}
                width={15}
                style={{
                  objectFit: "scale-down",
                  objectPosition: "right",
                }}
              />
            </Status>
          </StatusContainer>
          <InternalDropdown
            isOpen={button}
            approveHandler={handleOnClickApprovalModal}
            declineHandler={handleDecline}
            approveText="Approve"
            declineText="Decline"
          />
        </div>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#007C7C"
        >
          {status}
        </Status>
      );
    case "Waiting for Confirmation":
      return isInternal ? (
        <div>
          <StatusContainer disabled={disableClick} onClick={handleDropdown}>
            <Status
              onClick={onClickStatusRow}
              ref={wrapperRef}
              fontSize={fontSize}
              border_radius={border_radius}
              border={button ? "1px solid #00BB99" : "none"}
              justify_content="flex-start"
              font_color="#D96800"
              background="#FFF2DE"
            >
              <img
                src={timer_icon}
                alt="timer_icon"
                height={15}
                width={15}
                style={{
                  objectFit: "scale-down",
                }}
              />
              Schedule Approval
              <img
                src={arrow_down_icon}
                alt="arrow_down_icon"
                height={15}
                width={15}
                style={{
                  objectFit: "scale-down",
                  objectPosition: "right",
                }}
              />
            </Status>
          </StatusContainer>
          <InternalDropdown
            isOpen={button}
            approveHandler={handleOnClickScheduleModal}
            declineHandler={handleDecline}
            approveText="Approve"
            declineText="Decline"
          />
        </div>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#007C7C"
        >
          Waiting for Confirmation
        </Status>
      );
    case "Confirmed":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          justify_content="flex-start"
          background="#00BB99"
        >
          <img
            src={approved_icon}
            alt="approved_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Approved
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#34A853"
        >
          Confirmed
        </Status>
      );
    case "Completed":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          justify_content="flex-start"
          background="#34A853"
        >
          <img
            src={check_icon}
            alt="check_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Completed
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#34A853"
        >
          <img
            src={check_icon}
            alt="check_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Completed
        </Status>
      );

    case "Complete your data":
      return isInternal ? (
        <Status
          onClick={onClickStatusRow}
          ref={wrapperRef}
          fontSize={fontSize}
          border_radius={border_radius}
          border={button ? "1px solid #00BB99" : "none"}
          justify_content="flex-start"
          font_color="#D96800"
          background="#FFF2DE"
        >
          <img
            src={timer_icon}
            alt="timer_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Waiting completion
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#D96800"
        >
          Complete your data
          <img
            src={pen_write_icon}
            alt="pen_write_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
        </Status>
      );
    case "Cancelled":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          justify_content="flex-start"
          font_color="#A52D2D"
          background="#FFDFDC"
        >
          <img
            src={declined_icon}
            alt="declined_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Cancelled
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#AAAAAA"
        >
          Cancelled
        </Status>
      );
    case "Confirm your schedule":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          font_color="#0C9DD3"
          background="#C2EEFF"
        >
          <img
            src={schedule_icon}
            alt="schedule_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Waiting for schedule
        </Status>
      ) : (
        <Status
          onClick={onClickStatusRow}
          fontSize={fontSize}
          border_radius={border_radius}
          background="#34A853"
        >
          Confirm your schedule
        </Status>
      );
    case "Confirmed":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          justify_content="flex-start"
          background="#34A853"
        >
          <img
            src={check_icon}
            alt="check_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Approved
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#34A853"
        >
          Approved
        </Status>
      );
    case "Declined":
      return isInternal ? (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          justify_content="flex-start"
          font_color="#A52D2D"
          background="#FFDFDC"
        >
          <img
            src={declined_icon}
            alt="declined_icon"
            height={15}
            width={15}
            style={{
              objectFit: "scale-down",
            }}
          />
          Declined
        </Status>
      ) : (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#AAAAAA"
        >
          Declined
        </Status>
      );
    default:
      return (
        <Status
          fontSize={fontSize}
          border_radius={border_radius}
          background="#AAAAAA"
        >
          {status}
        </Status>
      );
  }
}

export default StatusComponent;

const StatusContainer = styled.button`
  border-radius: none;
  border: none;
  display: flex;
  background: none;
  padding: 0;
`;

const Status = styled.span`
  max-width: 190px;
  width: 100%;
  border-radius: ${({ border_radius }) => border_radius || "40px"};
  border: ${({ border }) => border || "none"};
  background: ${({ background }) => background || "#AAAAAA"};
  height: min-content;
  padding: 0.5rem 0.75rem;
  color: ${({ font_color }) => font_color || "white"};
  font-weight: 500;
  text-align: ${({ textAlign }) => textAlign || "center"};
  font-size: ${({ fontSize }) => fontSize || "18px"};
  display: flex;
  justify-content: ${({ justify_content }) => justify_content || "center"};
  align-items: center;
  gap: 0.5rem;
`;
