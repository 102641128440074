import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import styled from "styled-components";

import LoginRegisterContainer from "../components/LoginRegisterContainer";
import Tooltip from "../components/tooltip/Tooltip";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { PASSWORD_REGEX } from "../constants/index";
import linkExpiredIcon from "../assets/icons/link_expired_icon.svg";
import {
  resetPassword,
  verifyResetPasswordCode,
} from "../apis/authentications";

function ResetPassword() {
  const navigate = useNavigate();
  const { url_code } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm({ mode: "onChange" });

  const handleError = (errors) => {};

  const formOptions = {
    password: {
      required: "Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
    repeatPassword: {
      required: "Repeat Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isUrlCorrect, setUrlCorrect] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");

  function showAlert(text, type) {
    if (type === "success") {
      setSuccess(text);
    } else {
      setError(text);
    }
  }

  async function resetPasswordUrlVerification(reset_password_url) {
    try {
      const res = await verifyResetPasswordCode({
        url: `?code=${reset_password_url}`,
      });
      showAlert("Please input your new password", "success");
      setUrlCorrect(true);
    } catch (err) {
      showAlert(err.response.data.message, "error");
      setUrlCorrect(false);
    }
  }

  useEffect(() => {
    if (url_code) {
      resetPasswordUrlVerification(url_code);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [url_code]);

  async function handleResetPassword(data) {
    setIsLoading(true);
    if (
      data.password &&
      data.repeat_password &&
      data.password === data.repeat_password
    ) {
      try {
        const res = await resetPassword({
          url: `?code=${url_code}`,
          password: data.password,
        });
        if (res && res.data) {
          showAlert(
            "Reset password success, please wait while we redirect you...",
            "success"
          );
          navigate("/login");
        }
        setIsLoading(false);
      } catch (err) {
        showAlert(err.response.data.message, "error");
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
      showAlert("Password did not match", "error");
    }
  }

  function renderVerified() {
    return (
      <ResetPasswordForm
        onSubmit={handleSubmit(handleResetPassword, handleError)}
      >
        <Form.Group style={{ width: "80%", marginBottom: "15px" }}>
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              display: "inline-flex",
              alignItems: "center",
              gap: 5,
            }}
            htmlFor="password"
          >
            Password{" "}
            <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
          </Form.Label>
          <div
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              id="password"
              type={passwordType}
              name="password"
              placeholder="••••••••••••••"
              error={errors?.password}
              autoComplete="off"
              {...register("password", formOptions.password)}
            />
            <input
              autoComplete="off"
              style={{ display: "none" }}
              id="show1"
              type="checkbox"
              checked={passwordType === "text"}
              onChange={() =>
                setPasswordType((prev) =>
                  prev === "password" ? "text" : "password"
                )
              }
            />
            <label
              htmlFor="show1"
              style={{
                position: "absolute",
                right: 0,
                margin: "0 12px",
                cursor: "pointer",
              }}
            >
              {passwordType === "password" ? (
                <AiFillEye size={25} />
              ) : (
                <AiFillEyeInvisible size={25} />
              )}
            </label>
          </div>
          <small className="text-danger" style={{ userSelect: "none" }}>
            {errors?.password && (
              <>
                {errors.password.message}
                <br />
              </>
            )}
          </small>
        </Form.Group>
        <Form.Group style={{ width: "80%", marginBottom: "15px" }}>
          <Form.Label
            htmlFor="repeat_password"
            style={{
              fontWeight: "bold",
              color: "black",
              display: "inline-flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            Repeat Password{" "}
            <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" />
          </Form.Label>
          <div
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControl
              id="repeat_password"
              type={repeatPasswordType}
              name="repeat_password"
              placeholder="••••••••••••••"
              error={errors?.repeat_password}
              autoComplete="off"
              {...register("repeat_password", formOptions.repeatPassword)}
            />
            <input
              autoComplete="off"
              style={{ display: "none" }}
              id="show2"
              type="checkbox"
              checked={repeatPasswordType === "text"}
              onChange={() =>
                setRepeatPasswordType((prev) =>
                  prev === "password" ? "text" : "password"
                )
              }
            />
            <label
              htmlFor="show2"
              style={{
                position: "absolute",
                right: 0,
                margin: "0 12px",
                cursor: "pointer",
              }}
            >
              {repeatPasswordType === "password" ? (
                <AiFillEye size={25} />
              ) : (
                <AiFillEyeInvisible size={25} />
              )}
            </label>
          </div>
          <small className="text-danger" style={{ userSelect: "none" }}>
            {errors?.repeat_password && (
              <>
                {errors.repeat_password.message}
                <br />
              </>
            )}
          </small>
        </Form.Group>
        <SubmitButton
          disabled={isLoading || !isDirty || !isValid}
          type="submit"
        >
          Submit
        </SubmitButton>
      </ResetPasswordForm>
    );
  }
  function renderErrorVerifying() {
    if (error.toLowerCase() === "code expired") {
      return (
        <>
          <img
            src={linkExpiredIcon}
            alt="error_verifying_icon"
            height={130}
            width={130}
            objectFit="scale-down"
          />
          <Text>Change password link has expired</Text>
          <SmallText>The link has expired. Please request a new one.</SmallText>
          <ButtonPrimary onClick={() => navigate("/login")}>
            Try again
          </ButtonPrimary>
        </>
      );
    } else {
      return (
        <>
          <img
            src={linkExpiredIcon}
            alt="link_expired_icon"
            height={130}
            width={130}
            objectFit="scale-down"
          />
          <Text>Oops, we cannot verify your account</Text>
          <SmallText>Please re-check your verification link</SmallText>
        </>
      );
    }
  }

  return (
    <LoginRegisterContainer
      isLoading={isLoading}
      error={error}
      success={success}
    >
      <Container>
        {isUrlCorrect ? renderVerified() : renderErrorVerifying()}
      </Container>
    </LoginRegisterContainer>
  );
}

export default ResetPassword;

const Text = styled.h2`
  font-size: 36px;
  font-weight: bold;
  color: #00bb99;
  text-align: center;
  margin: 0;
  margin-top: 36px;
`;
const SmallText = styled.p`
  margin: 0;
  margin-top: 14px;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding: 32px;
`;

const ResetPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  margin-top: 1rem;
`;

const FormControl = styled(Form.Control)`
  border: ${({ error }) => (error ? "2px solid #EB4335" : "2px solid #00BB99")};
  background-color: ${({ error }) => (error ? "#FFE1E1" : "transparent")};
  border-radius: 10px;
`;

const SubmitButton = styled.button`
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  width: 40%;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  :hover {
    background: #00bb99;
    opacity: 0.8;
  }
  min-width: 160px;
  min-height: 40px;
  padding: 0.5rem 1rem;
  :disabled {
    background: #00bb99;
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const ButtonPrimary = styled.button`
  margin-top: 44px;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  min-width: 160px;
  min-height: 40px;
  padding: 0.5rem 1rem;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  :hover {
    background: #00bb99;
    cursor: ${({ disabled }) => !disabled && "pointer"};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.8)};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 80%;
  height: 100%;
  margin: auto;
`;
