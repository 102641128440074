import { useEffect, useRef, useState } from "react"
import styled from "styled-components"

function QuickFilterDropdown({ // e.g: const [state, handleState] = useState(null);
    options,
    placeholder,
    state,
    handleState,
    isDisabled
 }) {

    const [dropdownOptions, setDropdownOptions] = useState(null);
    const [display, setDisplay] = useState(false);
    const [totalOptions, setTotalOptions] = useState(0)

    const wrapperRef = useRef(null);

    // Close dropdown function
    const handleClickOutside = event => {
        const { current: wrap } = wrapperRef
        if (wrap && !wrap.contains(event.target)) {
            return setDisplay(false)
        }
    }

    // On change filter 
    const onChangeFilter = (event) => {
        handleState(event.target.value)
        setDisplay(true)
        if (event.target.value === '') {
            setDisplay(false)
        }
    }

    // On click item - READ THIS: use onMouseDown! Not onClick!
    const handleClickItem = (e, value) => {
        handleState(value)
        setDisplay(false)
    }

    // Close dropdown
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [])

    // Rearrange options 
    useEffect(() => {
        const newOptions = []
        options.map(value => {
            return newOptions.push({
                name: value.name
            })
        })
        return setDropdownOptions(newOptions)
    }, [options, state])

    // Check total number of options
    useEffect(() => {
        const options = dropdownOptions?.filter(({ name }) => name.indexOf(state) > -1)
        setTotalOptions(options?.length)
    }, [state])

    return (
        <>
            <DropdownInput
                value={state}
                ref={wrapperRef}
                placeholder={placeholder}
                onChange={onChangeFilter}
                disabled={isDisabled}
            />
            {
                display &&
                <DropdownItemContainer>
                    {
                        totalOptions > 0 ?
                        dropdownOptions.filter(({ name }) => name.indexOf(state) > -1 ).map((value, index) => {
                            return (
                                <DropdownItem onMouseDown={(event) => handleClickItem(event, value.name)} key={index}>
                                    {value.name}
                                </DropdownItem>
                            )
                        })
                        :
                        <DropdownItem background='transparent'>
                            data not found, try another keyword...
                        </DropdownItem>
                    }
                </DropdownItemContainer>
            }
        </>
    )
}

export default QuickFilterDropdown

const DropdownInput = styled.input`
    border: 2px solid #00BB99;
    border-radius: 10px;
    height: 50px;
    padding: 0.5em;
    width: 100%;
    &:disabled {
        opacity: .5;
        background: rgba(0, 187, 153, 0.1);
    }

`

const DropdownItemContainer = styled.div`
    padding-top: 1em;
    margin: 0.1em 0;
    z-index: 99999;
    width: 100%;
    position: absolute;
    background-color: #FAFAFA;
    border: 1px solid rgba(0,0,0,.15);
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    max-width: 25%;
`

const DropdownItem = styled.p`
    cursor: pointer;
    padding: 0.3em 1em;
    margin-bottom: 0.2em;
    &:hover {
        background: ${({ background }) => background ? background : 'rgba(223, 227, 228, 0.5);'};
    }
`