import Axios from "../../helpers/axios";

export const getAllStudentCourses = () => {
  return Axios.get(`/courses/all/student-courses`);
};

export const addCourseToStudentCourse = (course_id) => {
  return Axios.post(`/courses/${course_id}/student-courses/add`);
};

export const deleteStudentCourse = (student_course_id) => {
  return Axios.delete(`/courses/${student_course_id}/student-courses/delete`);
};

export const updateStudentLastActivityStudentCourse = (student_course_id) => {
  return Axios.get(
    `/courses/${student_course_id}/student-courses/update-student-course-last-acitivity`
  );
};

export const updateLessonLastWatchedVideoInSecond = (
  course_id,
  course_section_id,
  lesson_id,
  body
) => {
  return Axios.post(
    `/courses/${course_id}/course-sections/${course_section_id}/lessons/${lesson_id}/update-lesson-last-watched-video-in-second`,
    body
  );
};

export const getOneStudentCourseLessonVideo = (
  course_id,
  course_section_id,
  lesson_id
) => {
  return Axios.get(
    `/courses/${course_id}/course-sections/${course_section_id}/lessons/${lesson_id}/get-one-student-course-lesson-video`
  );
};

export const updateStudentCourseLessonVideoIsFinishWatching = (
  course_id,
  course_section_id,
  lesson_id,
  body
) => {
  return Axios.patch(
    `/courses/${course_id}/course-sections/${course_section_id}/lessons/${lesson_id}/update-student-course-lesson-video-is_finish_watching`,
    body
  );
};
