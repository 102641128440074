import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import Form from "react-bootstrap/Form";
import styled from "styled-components";

import LoginRegisterContainer from "../components/LoginRegisterContainer";

import { EMAIL_REGEX } from "../constants/index";

import mailBigIcon from "../assets/icons/mail_big_icon.svg";

import { useForm } from "react-hook-form";
import { forgetPasswordRequest } from "../apis/authentications";

function ForgotPassword() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const handleError = (errors) => {};

  const formOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address",
      },
    },
  };

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSentLink, setSentLink] = useState(false);
  const [email, setEmail] = useState("");

  function showAlert(text, type) {
    if (type === "success") {
      setSuccess(text);
    } else {
      setError(text);
      setTimeout(() => {
        setError("");
      }, 2000);
    }
  }

  async function handleForgotPassword(data) {
    setEmail(data.email);
    setIsLoading(true);
    try {
      const res = await forgetPasswordRequest({ email: data.email });
      showAlert("We have sent you a link to reset your password", "success");
      setSentLink(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showAlert(err.response.data.message, "error");
    }
  }

  function renderSentLink() {
    return (
      <>
        <img
          src={mailBigIcon}
          alt="google_logo"
          height={220}
          width={210}
          objectFit="scale-down"
        />
        <Text>Email sent</Text>
        <SmallText>Check your inbox to reset password</SmallText>
        <p
          style={{
            width: "80%",
            textAlign: "center",
            marginBottom: "48px",
            fontWeight: 500,
          }}
        >
          an email has been sent to {email} with a link to reset your password.
          If you have not received your email after few minutes, please do check
          your spam folder.
        </p>
        <SubmitButton onClick={() => navigate("/login")}>
          Okay, got it
        </SubmitButton>
      </>
    );
  }

  function renderEmailInput() {
    return (
      <>
        <h1 style={{ fontSize: 22, textAlign: "center", marginBottom: "2rem" }}>
          Forgot your Password?
        </h1>
        <ForgotPasswordForm
          onSubmit={handleSubmit(handleForgotPassword, handleError)}
        >
          <Form.Group style={{ width: "80%" }}>
            <Form.Label>Email address</Form.Label>
            <FormControl
              id="email"
              type="email"
              name="email"
              placeholder="yourname@mail.com"
              error={errors?.email}
              {...register("email", formOptions.email)}
            />
            <small className="text-danger">
              {errors?.email && errors.email.message}
            </small>
          </Form.Group>
          <div style={{ width: "80%" }}>
            <p>
              Enter the email address you used when you joined Combined Clinics
              Sustainability and we&apos;ll send you instructions to reset your
              password.
            </p>
            <p>
              For security reasons, we do NOT store your password. So rest
              assured that we will never send your password via email.
            </p>
          </div>
          <SubmitButton
            disabled={isLoading || !isDirty || !isValid || isSubmitting}
            variant="primary"
            type="submit"
          >
            Send reset instructions
          </SubmitButton>
        </ForgotPasswordForm>
      </>
    );
  }

  return (
    <LoginRegisterContainer
      isLoading={isLoading}
      error={error}
      success={success}
    >
      {isSentLink ? renderSentLink() : renderEmailInput()}
    </LoginRegisterContainer>
  );
}

export default ForgotPassword;

const Text = styled.h1`
  font-size: 36px;
  text-align: center;
  margin-bottom: 20px;
  color: #00bb99;
  font-weight: bold;
  margin-bottom: 20px;
`;
const SmallText = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const ForgotPasswordForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  margin-top: 1rem;
`;

const FormControl = styled(Form.Control)`
  border: ${({ error }) => (error ? "2px solid #EB4335" : "2px solid #00BB99")};
  background-color: ${({ error }) => (error ? "#FFE1E1" : "transparent")};
  border-radius: 10px;
`;

const SubmitButton = styled.button`
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: #00bb99;
  border: #00bb99;
  border-radius: 40px;
  filter: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15));
  :hover {
    background: #00bb99;
    cursor: ${({ disabled }) => !disabled && "pointer"};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 0.8)};
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  min-width: 160px;
  min-height: 40px;
  padding: 0.5rem 1rem;
`;
