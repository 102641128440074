import React, { useState } from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";

import fontSize from "../../../lib/styling/font_size";
import FileUpload from "../../FileUploadComponent";

function EntrepreneurshipFileUpload({ document, key, files, setFiles }) {
  return (
    <InputGroup>
      <Form.Group encType="multipart/form-data">
        <InputLabel>
          Upload {document.name}{" "}
          <span
            style={{ fontSize: 12, color: "#AAAAAA", fontWeight: "normal" }}
          >
            (.zip, .doc, .docx, .pdf, .rar)
          </span>
        </InputLabel>
        <FileUpload
          files={files}
          setFiles={setFiles}
          uploadKey={document.name}
        />
      </Form.Group>
    </InputGroup>
  );
}

export default EntrepreneurshipFileUpload;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.div`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;

const ErrorMessage = styled.p`
   fontSize: 12px,
   fontWeight: normal,
   color: #EB4335,
   padding: 0,
   margin: 0,
`;
