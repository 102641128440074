import Axios from "../../helpers/axios";

export const getAllUsersSessions = (userLoginId) => {
  return Axios.get(`/sessions/${userLoginId}/all`);
};

export const getDataBooking = (booking_id) => {
  return Axios.get(`/sessions/${booking_id}`);
};

export const updateOneSession = (booking_id, body) => {
  return Axios.patch(`/sessions/${booking_id}`, body);
};

export const createNewSession = (body) => {
  return Axios.post(`/sessions/create`, body);
};

export const uploadFileSession = (coachingSessionId, body) => {
  return Axios.patch(`/sessions/upload-document/${coachingSessionId}`, body);
};
