import date_icon from "../../../assets/icons/date_icon.svg";
import time_icon from "../../../assets/icons/time_icon.svg";

export const entreformInitialState = {
  topic: {
    value: "",
    error: false,
    name: "Topic",
    required: true,
  },
  about_business: {
    value: "",
    error: false,
    name: "About Business",
    required: true,
  },
  dream_and_vision: {
    value: "",
    error: false,
    name: "Dream and Vision",
    required: true,
  },
  number_of_employees: {
    value: "",
    error: false,
    name: "Number of Employees",
    required: true,
  },
  main_customers: {
    value: "",
    error: false,
    name: "Main Customers",
    required: true,
  },
  supplier_desc: {
    value: "",
    error: false,
    name: "Supplier Description",
    required: true,
  },
  production_size: {
    value: "",
    error: false,
    name: "Production Size",
    required: true,
  },
  your_motivation: {
    value: "",
    error: false,
    name: "Your Motivation",
    required: true,
  },
  business_goals: {
    value: "",
    error: false,
    name: "Business Goals",
    required: true,
  },
  other_info: {
    value: "",
    error: false,
    name: "Other Info",
    required: false,
  },
  main_competitor: {
    value: "",
    error: false,
    name: "Main Competitor",
    required: true,
  },
  competitor_advantage: {
    value: "",
    error: false,
    name: "Competitor Advantage",
    required: true,
  },
  your_advantage: {
    value: "",
    error: false,
    name: "Your Advantage",
    required: true,
  },
  your_disadvantage: {
    value: "",
    error: false,
    name: "Your Disadvantage",
    required: true,
  },
  internal_challenges: {
    value: "",
    error: false,
    name: "Internal Challenges",
    required: true,
  },
  external_challenges: {
    value: "",
    error: false,
    name: "External Challenges",
    required: true,
  },
  capital_resources: {
    value: "",
    error: false,
    name: "Capital Resources",
    required: true,
  },
  labor_resources: {
    value: "",
    error: false,
    name: "Labor Resources",
    required: true,
  },
  other_resources: {
    value: "",
    error: false,
    name: "Other Resources",
    required: false,
  },
  challenges_in_getting_resources: {
    value: "",
    error: false,
    name: "Challenges in Getting Resources",
    required: true,
  },
};

export const nonEntreformInitialState = {
  topic: {
    value: "",
    error: false,
    name: "Topic",
    required: true,
  },
  scheduled_date: {
    value: "",
    error: false,
    name: "Date",
    required: true,
  },
  time: {
    value: "",
    error: false,
    name: "Time",
    required: true,
  },
  current_challenges: {
    value: "",
    error: false,
    name: "Current Challenges",
    required: true,
  },
  timeslot_id: null,
};

export const tabs = [
  {
    header: "About your business",
    disable: false,
    body: [
      {
        label: "About my business",
        type: "text",
        placeholder: "e.g: ABC company is an eco sportswear",
        element: "input",
        value: "about_business",
      },
      {
        label: "Explain your dreams and vision",
        type: "text",
        placeholder: "e.g: Becoming the pioneer of eco sportswear in SEA",
        element: "input",
        value: "dream_and_vision",
      },
      {
        label: "Number of employees",
        type: "dropdown",
        placeholder: "Select number of employees",
        element: "input",
        value: "number_of_employees",
        options: [
          { name: "1-10 employees" },
          { name: "11-50 employees" },
          { name: "51-200 employees" },
          { name: "201-500 employees" },
          { name: "> 500 employees" },
        ],
      },
      {
        label: "Main Customers (Target Market)",
        type: "text",
        placeholder: "e.g: People who loves to work out",
        element: "input",
        value: "main_customers",
      },
      {
        label: "Explain the supplier of your business",
        type: "text",
        placeholder: "e.g: Penjahit near Karawang",
        element: "input",
        value: "supplier_desc",
      },
      {
        label: "Production Size",
        type: "text",
        placeholder: "e.g: 20 models per month",
        element: "input",
        value: "production_size",
      },
      {
        label: "What are your motivation?",
        type: "text",
        placeholder: "e.g: financially stable and independent",
        element: "input",
        value: "your_motivation",
      },
      {
        label: "What are your business goals?",
        type: "text",
        placeholder: "e.g: empowering people to be physically active",
        element: "input",
        value: "business_goals",
      },
      {
        label: "Other information (please state)",
        type: "text",
        placeholder: "e.g: .....",
        element: "input",
        value: "other_info",
      },
    ],
  },
  {
    header: "Competitor's Profile",
    disable: true,
    body: [
      {
        label: "Who is your main competitor?",
        type: "text",
        placeholder: "e.g: ABC company",
        element: "input",
        value: "main_competitor",
      },
      {
        label: "What is your competitor's advantage",
        type: "textarea",
        placeholder: "e.g: capital",
        element: "input",
        value: "competitor_advantage",
        rows: "7",
      },
      {
        label:
          "What is your product/service advantage againts your competitor's?",
        type: "textarea",
        placeholder: "e.g: strong build quality",
        element: "input",
        value: "your_advantage",
        rows: "7",
      },
      {
        label:
          "What is your product/service disadvantage againts your competitor's?",
        type: "textarea",
        placeholder: "e.g: slow delivery time",
        element: "input",
        value: "your_disadvantage",
        rows: "7",
      },
    ],
  },
  {
    header: "Business Challenges",
    disable: true,
    body: [
      {
        label: "Challenges from internal factors",
        type: "textarea",
        placeholder: "e.g: communication",
        element: "input",
        value: "internal_challenges",
        rows: "7",
      },
      {
        label: "Challenges from external factors",
        type: "textarea",
        placeholder: "e.g: low demand",
        element: "input",
        value: "external_challenges",
        rows: "7",
      },
      {
        label: "Resources you need to grow your business",
        type: "yesno",
        content: [
          {
            label: "Capital",
            value: "capital_resources",
          },
          {
            label: "Labor",
            value: "labor_resources",
          },
          {
            label: "Others (please state)",
            value: "other_resources",
            type: "yesnoother",
            placeholder: "e.g:...",
          },
        ],
      },
      {
        label: "Explain challenges that you face in getting those resources",
        type: "textarea",
        placeholder: "e.g: low brand awareness",
        element: "input",
        value: "challenges_in_getting_resources",
        rows: "7",
      },
    ],
  },
];

export const nonEntreForm = [
  {
    label: "Select Date",
    type: "datetime",
    placeholder: "DD-MM-YYYY",
    value: "date",
    icon: date_icon,
  },
  {
    label: "Select Time",
    type: "datetime",
    placeholder: "Add your preferred time",
    value: "time",
    icon: time_icon,
  },
  {
    label: "What are your current challenges?",
    type: "textarea",
    placeholder: "Please describe your current challenges",
    value: "current_challenges",
    rows: "7",
  },
];
