import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import UserForm from "../components/profile/UserForm";
import SpinnerComponent from "../components/SpinnerComponent";

// Apis
import {
  getAllCountries,
  getAllDegrees,
  getAllIndustries,
  getAllOccupations,
} from "../apis/general";

const Profile = () => {
  const userDetail = useSelector((state) => state.userDetail);

  const [isLoading, setLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [countries, setCountries] = useState(null);
  const [occupations, setOccupations] = useState(null);
  const [degrees, setDegrees] = useState(null);

  const handleFetchIndustries = async () => {
    setLoading(true);
    const { data: industries } = await getAllIndustries();
    if (industries) {
      setIndustries(industries);
    }
    setLoading(false);
  };

  const handleFetchCountries = async () => {
    setLoading(true);
    const { data: countries } = await getAllCountries();
    if (countries) {
      setCountries(countries);
    }
    setLoading(false);
  };

  const handleFetchOccupations = async () => {
    setLoading(true);
    const { data: occupations } = await getAllOccupations();
    if (occupations) {
      setOccupations(occupations);
    }
    setLoading(false);
  };

  const handleFetchDegrees = async () => {
    setLoading(true);
    const { data: degrees } = await getAllDegrees();
    if (degrees) {
      setDegrees(degrees);
    }
    setLoading(false);
  };

  const handleChangeIsNewUserState = (propIsNewUser) => {
    setIsNewUser(propIsNewUser);
  };

  useEffect(() => {
    handleFetchIndustries();
    handleFetchCountries();
    handleFetchOccupations();
    handleFetchDegrees();
  }, []);

  useEffect(() => {
    if (userDetail) {
      handleChangeIsNewUserState(userDetail.isNewUser);
    }
  }, [userDetail]);

  return (
    <div>
      <SidebarComponent />
      <div style={{ paddingLeft: "350px" }}>
        <HeaderComponent />
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <div style={{ padding: "130px 3vw 30px 3vw" }}>
          {userDetail && industries && countries && occupations && degrees && (
            <UserForm
              user={userDetail}
              isNewUser={isNewUser}
              setIsNewUser={setIsNewUser}
              industries={industries}
              countries={countries}
              occupations={occupations}
              degrees={degrees}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
