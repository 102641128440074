import React, { useState } from "react";
import styled from "styled-components";
import FeedbackModalComponent from "./FeedbackModalComponent";
import StatusComponent from "./StatusComponent";
import { dateFormat, humanize } from "../utils/common";

// Images & Icons
import eye_icon from "../assets/icons/eye_icon.svg";
import empty_session_1_icon from "../assets/icons/empty_session_1_icon.svg";

// Styles
import "../styles/tableComponent.css";

function TableComponent({
  tableData,
  topics,
  onClickRow,
  setTableID,
  user_detail,
}) {
  const data = tableData.map((d) => {
    topics.map((topic) => {
      d.topic_id === topic.id && (d.topic_id = topic.title);
    });
    delete d.user_id;
    delete d.createdAt;
    return d;
  });

  const tableHead =
    tableData.length &&
    Object.keys(tableData[0]).map((key) => {
      let data = [];

      switch (key) {
        case "booking_id":
          data = "Booking ID";
          break;
        case "topic_id":
          data = "Description";
          break;
        case "scheduled_date":
          data = "Session date/time";
          break;
        case "feedback":
          data = "Feedback";
          break;
        case "assignedMentor":
          data = "Assigned mentor";
          break;
        default:
          data = key;
          break;
      }
      return humanize(data);
    });

  const [dataForFeedback, setDataForFeedback] = useState({});
  const [isFeedbackModalOpen, setFeedbackModalOpen] = useState(false);

  function handleOnClickFeedback(key, value, d) {
    setFeedbackModalOpen(true);
    setDataForFeedback(d);
  }

  function handleCloseFeedbackModal() {
    setFeedbackModalOpen(false);
  }

  const tableHeadData = [
    "Booking ID",
    "Description",
    "Session date/time",
    "Duration",
    "Assigned mentor",
    "Status",
  ];

  return (
    <div>
      <Table tableData={tableData}>
        <TableHead user_detail={user_detail}>
          <TableRow>
            {tableHead
              ? tableHead.map((head, index) => (
                  <TableHeadData key={index}>{head}</TableHeadData>
                ))
              : tableHeadData.map((head, index) => (
                  <TableHeadData key={index}>{head}</TableHeadData>
                ))}
          </TableRow>
        </TableHead>
        {data && Array.isArray(data) && data.length > 0 && (
          <TableBody>
            {data?.map((d, index) => (
              <tr key={index} type="body" className="tableComponentTrTag">
                {Object.entries(d).map(([key, value], ind) => (
                  <TableRowData
                    onClick={() => onClickRow(d, key, value)}
                    head={key}
                    key={ind}
                  >
                    <DataValueContainer head={key} table_value={value}>
                      {value ? (
                        key === "scheduled_date" ? (
                          <DataValue>{dateFormat(value)}</DataValue>
                        ) : key === "feedback" ? (
                          <div
                            onClick={() => handleOnClickFeedback(key, value, d)}
                            style={{
                              display: "flex",
                              gap: "0.25rem",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={eye_icon}
                              alt="eye_icon"
                              width={15}
                              height={15}
                            />
                            <div>View</div>
                          </div>
                        ) : key === "status" ? (
                          <StatusComponent
                            status={value}
                            border_radius="40px"
                            fontSize="14px"
                            onClickStatusRow={() => setTableID(d, key, value)}
                          />
                        ) : (
                          <DataValue>{value}</DataValue>
                        )
                      ) : (
                        <DataValue>Not available</DataValue>
                      )}
                    </DataValueContainer>
                  </TableRowData>
                ))}
              </tr>
            ))}
          </TableBody>
        )}
      </Table>
      {data && data.length <= 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <img
            src={empty_session_1_icon}
            alt="empty_session_1_icon"
            style={{
              objectFit: "scale-down",
              width: "300px",
              height: "auto",
            }}
          />
          <h4 style={{ fontSize: "22px" }}>
            You have not made any booking yet
          </h4>
        </div>
      )}
      <FeedbackModalComponent
        show={isFeedbackModalOpen}
        handleClose={handleCloseFeedbackModal}
        booking_id={dataForFeedback["booking_id"]}
        mentor_name={dataForFeedback["assignedMentor"]}
        topic={dataForFeedback["topic_id"]}
        body={[dataForFeedback["feedback"]]}
      />
    </div>
  );
}

export default TableComponent;

const Table = styled.table`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  // height: ${({ tableData }) => (tableData.length < 6 ? "75vh" : "")};
`;
const TableHead = styled.thead`
  color: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false ? "#2c65f7" : "#00bb99"};
  border-bottom: ${({ user_detail }) =>
    user_detail && user_detail.is_admin === false
      ? "1px solid #2c65f7"
      : "1px solid #00bb99"};
`;

const TableBody = styled.tbody``;

const TableRow = styled.tr`
  :hover {
    background-color: ${({ type }) => type === "body" && "#EEEEEE"};
  }
  cursor: pointer;
`;

const TableHeadData = styled.th`
  padding: 1.25rem 0.25rem;
`;

const TableRowData = styled.td`
  padding: 0.75rem 0.25rem;
`;

const DataValue = styled.div`
  transition: 0.3s ease;
  padding: 0.5em 0;
`;

const DataValueContainer = styled.div`
  display: flex;
  justify-content: ${({ head }) => head === "Status" && "center"};
  align-items: center;
  padding: ${({ head }) => head === "Status" && "0.5rem 0.75rem"};
  border-radius: ${({ head }) => head === "Status" && "50px"};
  color: ${({ head }) => head === "Status" && "white"};
  cursor: ${({ table_value }) => table_value === "View" && "pointer"};
  gap: ${({ table_value }) => table_value === "View" && "9px"};
`;
