import styled from "styled-components";
import React from "react";
import Modal from "react-bootstrap/Modal";

function FeedbackModalComponent({
  show,
  handleClose,
  title,
  subtitle,
  body,
  buttonPrimary,
  buttonSecondary,
  closeButton = true,
  onPrimaryClick,
  onSecondaryClick,
  bodyTextAlign = "center",
  topic,
  mentor_name,
  booking_id,
}) {
  return (
    <>
      <FeedbackModal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton={closeButton}>
          <ModalTitle>
            <div>
              <ModalTitleKey>Topic of interest</ModalTitleKey>
              <ModalTitleValue>{topic}</ModalTitleValue>
              <ModalTitleKey>Assigned Mentor</ModalTitleKey>
              <ModalTitleValue>{mentor_name}</ModalTitleValue>
            </div>
            <div>
              <ModalTitleKey>Booking ID</ModalTitleKey>
              <ModalTitleValue>{booking_id}</ModalTitleValue>
            </div>
          </ModalTitle>
        </Modal.Header>
        <ModalBody bodyTextAlign={bodyTextAlign}>
          <h5 style={{ marginBottom: 20 }}>Feedback</h5>
          {body.map((bod, index) => (
            <p key={index} style={{ fontSize: 18 }}>
              {bod}
            </p>
          ))}
        </ModalBody>
        <ModalFooter>
          {buttonSecondary && (
            <ButtonSecondary onClick={onSecondaryClick}>
              {buttonSecondary}
            </ButtonSecondary>
          )}
          {buttonPrimary && (
            <ButtonPrimary onClick={onPrimaryClick}>
              {buttonPrimary}
            </ButtonPrimary>
          )}
        </ModalFooter>
      </FeedbackModal>
    </>
  );
}

export default FeedbackModalComponent;

const FeedbackModal = styled(Modal)`
  .modal-title {
    font-size: 14px !important;
  }
`;

const ModalTitle = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 10px;
  padding: 0 20px;
`;

const ModalBody = styled(Modal.Body)`
  text-align: ${({ bodyTextAlign }) => bodyTextAlign};
  padding: 0 36px;
`;

const ModalFooter = styled(Modal.Footer)`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const ModalTitleKey = styled.h5`
  font-size: 14px !important;
  font-weight: bold;
  color: #00bb99;
`;

const ModalTitleValue = styled.p`
  font-size: 18px !important;
  font-weight: normal;
`;

const ButtonPrimary = styled.button`
  background-color: #00bb99;
  border-radius: 40px;
  border: none;
  font-weight: bold;
  :hover {
    background-color: #00bb99;
    opacity: 0.8;
  }
  padding: 0.5rem 1rem;
`;
const ButtonSecondary = styled.button`
  background-color: #aaaaaa;
  border-radius: 40px;
  border: none;
  font-weight: bold;
  :hover {
    background-color: #aaaaaa;
    opacity: 0.8;
  }
  padding: 0.5rem 1rem;
`;
