import React, { useEffect, useState } from "react";

// helpers
import getTimeFromSeconds from "../helpers/get-time-from-seconds";
import getPdfCoverImage from "../helpers/get-pdf-cover-image";

// components
import VideoPlayer from "./VideoPlayer";

const CourseRecapDetailCoursePage = ({
  eachLesson,
  isStudent,
  handleOnPauseVideo,
  course_id,
  course_section_id,
  handleOnVideoEnded,
}) => {
  const { lesson_name, duration_in_seconds, content_type, content_url } =
    eachLesson || {};

  const [articlePreview, setArticlePreview] = useState(null);

  let durationValue = getTimeFromSeconds(duration_in_seconds);

  const getArticlePreview = async (file, fileUrl) => {
    const preview = await getPdfCoverImage(file, fileUrl);
    await setArticlePreview(preview);
  };

  useEffect(() => {
    if (content_url && content_type && content_type === "article") {
      getArticlePreview(null, content_url);
    }
  }, [content_type && content_url]);

  return (
    <div className="showLessonImageContainer">
      {content_type && content_type === "article" && content_url ? (
        <div className="showLessonImageContainerImage">
          <div
            style={{
              backgroundColor: isStudent ? "#2c65f7" : "#00bb99",
              width: "500px",
              height: "350px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
            }}
          >
            <img
              src={articlePreview}
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                maxWidth: "500px",
                maxHeight: "350px",
                objectFit: "cover",
              }}
              alt="courseImage"
            />
          </div>
        </div>
      ) : content_type && content_type === "video" && content_url ? (
        <div className="showLessonImageContainerImage">
          <VideoPlayer
            videoSrc={content_url}
            lesson={eachLesson}
            course_id={course_id}
            course_section_id={course_section_id}
            handleOnPauseVideo={handleOnPauseVideo}
            last_video_watched_in_seconds={
              eachLesson && eachLesson["last_video_watched_in_seconds"]
            }
            isStudent={isStudent}
            is_finish_watching={eachLesson && eachLesson["is_finish_watching"]}
            duration_in_seconds={duration_in_seconds}
            handleOnVideoEnded={handleOnVideoEnded}
          />
        </div>
      ) : null}
      {!isStudent ||
      (isStudent && content_type && content_type === "article") ? (
        <div
          className="showLessonImageContainerLessonName"
          style={{ color: isStudent ? "#002b73" : "#007c7c" }}
        >
          {lesson_name
            ? lesson_name.charAt(0).toUpperCase() + lesson_name.slice(1)
            : ""}
        </div>
      ) : null}
      {!isStudent &&
      content_type &&
      content_type === "video" &&
      duration_in_seconds &&
      durationValue ? (
        <div
          className="showLessonImageContainerLessonDuration"
          style={{ color: isStudent ? "#002b73" : "#007c7c" }}
        >
          {`${
            durationValue.hours
              ? `${
                  durationValue.hours > 1
                    ? `${durationValue.hours} hours`
                    : `${durationValue.hours} hour`
                }`
              : ``
          } ${
            durationValue.minutes
              ? `${
                  durationValue.minutes > 1
                    ? `${durationValue.minutes} mins`
                    : `${durationValue.minutes} min`
                }`
              : ``
          } ${
            !durationValue.hours && durationValue.seconds
              ? `${
                  durationValue.seconds > 1
                    ? `${durationValue.seconds} secs`
                    : `${durationValue.seconds} sec`
                }`
              : ``
          }`}
        </div>
      ) : null}
    </div>
  );
};

export default CourseRecapDetailCoursePage;
