import Axios from "../../helpers/axios";

export const adminCreateNewCourse = (body) => {
  return Axios.post(`/courses/create`, body);
};

export const getAllStudentListWhoAddedCourse = (course_id) => {
  return Axios.get(`/courses/${course_id}/student-courses`);
};

export const deleteCourse = (course_id) => {
  return Axios.delete(`/courses/delete/${course_id}`);
};

export const adminEditCourse = (body) => {
  return Axios.put(`/courses/update/${body.course_id}`, body);
};
