import React from "react";
import styled from "styled-components";
import assigned_mentor_icon from "../assets/icons/assigned_mentor_icon.png";

function AlertGreenComponent({
  show = false,
  text = "Please insert your text alert here",
}) {
  return (
    <ModalSuccessAlert show={show}>
      <img
        src={assigned_mentor_icon}
        alt="assigned_mentor_icon"
        height={25}
        width={25}
        style={{ objectFit: "scale-down" }}
      />
      <ModalSuccessText>{text}</ModalSuccessText>
    </ModalSuccessAlert>
  );
}

export default AlertGreenComponent;

const ModalSuccessAlert = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  margin: 2em;
  font-weight: 700;
  padding: 1em;
  background: #89e9d7;
  text-align: left;
`;

const ModalSuccessText = styled.p`
  margin-left: 0.5em;
  margin-bottom: 0;
`;
