import { useState } from "react";
import styled from "styled-components";

function Toggler({
  isChecked,
  activeText = "Active",
  inactiveText = "Inactive",
}) {
  const [state, setState] = useState(isChecked);

  // Toggler handler, return true or false
  const handleToggle = () => setState(!state);

  return (
    <TogglerContainer checked={state}>
      <TogglerText checked={state}>
        {state ? activeText : inactiveText}
      </TogglerText>
      <TogglerWrapper onClick={handleToggle}>
        <Toggle
          onChange={() => setState(state)}
          id="toggle"
          type="checkbox"
          checked={state}
        />
        <TogglerLabel htmlFor="toggle" />
      </TogglerWrapper>
    </TogglerContainer>
  );
}

export default Toggler;

const TogglerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ checked }) =>
    checked ? "1px solid #00BB99" : "1px solid black"};
  border-radius: 40px;
  width: 100%;
  min-width: 110px;
  max-width: 120px;
  max-height: 35px;
`;

const TogglerWrapper = styled.div`
  position: relative;
  margin-right: 0.5em;
  margin-top: 0.5em;
`;

const TogglerText = styled.p`
  margin-bottom: 0;
  margin-left: 0.5em;
  transition: 0.2s;
  color: ${({ checked }) => (checked ? "#00BB99" : "black")};
`;

const TogglerLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 20px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;

const Toggle = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 35px;
  height: 20px;
  &:checked + ${TogglerLabel} {
    background: #00bb99;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin-left: 18px;
      transition: 0.2s;
    }
  }
`;
