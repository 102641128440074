import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Apis
import { getAllUsers } from "../apis/internalApis/users";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";

const InternalUserList = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);

  const handleFetchUserList = async () => {
    setLoading(true);
    const result = await getAllUsers();
    if (result && result.data) {
      setUsers(result.data);
    }
    setLoading(false);
  };

  const handleFormatFirstFetchPaginatedData = (users) => {
    let initialDataState = users
      .map(
        ({
          id,
          user_details,
          role,
          createdAt,
          email,
          is_validated,
          last_sign_in,
        }) => {
          let username = "";
          let roleName = "";
          if (user_details) {
            username = user_details.first_name + " " + user_details.last_name;
          }
          if (role && role.name) {
            roleName = role.name;
          }
          const date = new Date(createdAt).toLocaleString();
          return {
            id,
            username,
            roleName,
            email_address: email,
            registered_date: date,
            is_verified: is_validated,
            last_sign_in: last_sign_in
              ? new Date(last_sign_in).toLocaleString()
              : null,
          };
        }
      )
      .sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  // Change router to view mentor details
  function handleViewDetails(d) {
    navigate(`/internal/users/${d["id"]}`);
  }

  const initialDataState =
    users && users.length > 0
      ? users
          .map(
            ({
              id,
              user_details,
              role,
              createdAt,
              email,
              is_validated,
              last_sign_in,
            }) => {
              let username = "";
              let roleName = "";
              if (user_details) {
                username =
                  user_details.first_name + " " + user_details.last_name;
              }
              if (role && role.name) {
                roleName = role.name;
              }
              const date = new Date(createdAt).toLocaleString();
              return {
                id,
                username,
                roleName,
                email_address: email,
                registered_date: date,
                is_verified: is_validated,
                last_sign_in: last_sign_in
                  ? new Date(last_sign_in).toLocaleString()
                  : null,
              };
            }
          )
          .sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
      : [];

  useEffect(() => {
    handleFetchUserList();
  }, []);

  useEffect(() => {
    if (users && Array.isArray(users) && users.length > 0) {
      handleFormatFirstFetchPaginatedData(users);
    }
  }, [users]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <div style={{ paddingLeft: "350px" }}>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <div className="myBookingInnerContainer">
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            itemOffset={itemOffset + 1}
            endOffset={itemOffset + perPage}
            totalItems={initialDataState.length}
          >
            <TableInternalComponent
              tableData={paginatedData}
              table_name="Users"
              onClickRow={handleViewDetails}
            />
          </Pagination>
        </div>
      </div>
    </div>
  );
};

export default InternalUserList;
