import React from "react";

// Components
import LoginGuard from "./LoginGuard";
import AppContent from "../AppContent";

const DefaultLayout = () => {
  return (
    <LoginGuard>
      <AppContent />
    </LoginGuard>
  );
};

export default DefaultLayout;
