import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fontSize from "../../../lib/styling/font_size";
import Form from "react-bootstrap/Form";
import YesNoInput from "../../input/YesNoInput";
import CustomDropdown from "../../../components/input/CustomDropdown";
import Collapsible from "react-collapsible";
import { useSelector } from "react-redux";
import dropdown_green_icon from "../../../assets/icons/dropdown_green_icon.svg";

function EntrepreneurshipForm({
  tabs,
  form,
  handleChange,
  inputBackgroundColor,
  isSessionPage,
  updateData,
  handleChangeDropdown,
  formDisabled = false,
  handleOnClickAccordion = () => {},
  handleOnClicKEdit = () => {},
  disableEditSaveButton = false,
  isFormChanged = false,
}) {
  const [triggerDisable, setTriggerDisable] = useState([false, true, true]);
  const [tabOne, setTabOne] = useState([]);
  const [tabTwo, setTabTwo] = useState([]);
  const [tabThree, setTabThree] = useState([]);

  const [focusedInput, setFocusedInput] = useState(null);

  const isInternal = useSelector((state) => state.isInternal);

  function handleBlur() {}

  useEffect(() => {
    if (tabs) {
      const tempArray1 = [];
      const tempArray2 = [];
      const tempArray3 = [];
      tabs[0].body.forEach((item) => {
        form[item.value]?.required && tempArray1.push(form[item.value] || "");
      });
      setTabOne(tempArray1);
      tabs[1].body.forEach((item) => {
        form[item.value]?.required && tempArray2.push(form[item.value] || "");
      });
      setTabTwo(tempArray2);
      tabs[2].body.forEach((item) => {
        form[item.value]?.required && tempArray3.push(form[item.value] || "");
      });
      setTabThree(tempArray3);
    }
  }, [tabs, form]);

  useEffect(() => {
    if (!Object.values(tabOne).every((el) => Boolean(el.value) === true)) {
      setTriggerDisable([false, true, true]);
    } else {
      if (!Object.values(tabTwo).every((el) => Boolean(el.value) === true)) {
        setTriggerDisable([false, false, true]);
      } else {
        setTriggerDisable([false, false, false]);
      }
    }
  }, [form, tabOne, tabTwo]);

  return (
    <SessionFormTab>
      {tabs.map((tab, index) => (
        <Collapsible
          key={index}
          open={!isSessionPage && index === 0 ? true : false}
          id={`accordion-item-${index}`}
          triggerDisabled={!isSessionPage && triggerDisable[index]}
          trigger={
            <CollapsibleTrigger onClick={() => handleOnClickAccordion(index)}>
              {tab.header}
              {isSessionPage && !isInternal && (
                <EditSaveButton
                  disabled={disableEditSaveButton}
                  onClick={(e) => handleOnClicKEdit(e, index, form)}
                >
                  {isSessionPage && formDisabled
                    ? "Edit"
                    : isFormChanged
                    ? "Save"
                    : "Cancel"}
                </EditSaveButton>
              )}
              <img
                src={dropdown_green_icon}
                alt="dropdown_green_icon"
                width={15}
                height={10}
              />
            </CollapsibleTrigger>
          }
        >
          <CollapsibleContent>
            {tab.body?.map((bod, index) => {
              if (bod.type === "text" || bod.type === "number") {
                return (
                  <InputGroup key={index}>
                    <Form.Group>
                      <InputLabel
                        htmlFor={`entreform-${bod.label.toLowerCase()}`}
                      >
                        {bod.label}
                      </InputLabel>
                      <Input
                        id={`entreform-${bod.label.toLowerCase()}`}
                        type={bod.type}
                        placeholder={bod.placeholder}
                        value={form[`${bod.value}`]?.value || ""}
                        onChange={(e) => handleChange(e, `${bod.value}`)}
                        required
                        onBlur={handleBlur}
                        onFocus={() => setFocusedInput(bod.value)}
                        style={{
                          borderColor: form[bod.value]?.error
                            ? "#FF9494"
                            : undefined,
                          backgroundColor:
                            (isSessionPage &&
                              formDisabled &&
                              inputBackgroundColor) ||
                            undefined,
                          fontWeight:
                            focusedInput !== bod.value &&
                            form[`${bod.value}`].value
                              ? "bold"
                              : undefined,
                        }}
                        disabled={isSessionPage && formDisabled}
                        maxLength={255}
                        autoComplete="off"
                      />
                      <div style={{ color: "#EB4335", fontSize: 12 }}>
                        {form[`${bod.value}`]?.error &&
                          "This field is required"}
                      </div>
                    </Form.Group>
                  </InputGroup>
                );
              } else if (bod.type === "textarea") {
                return (
                  <InputGroup key={index}>
                    <Form.Group>
                      <InputLabel>{bod.label}</InputLabel>
                      <TextArea
                        rows={bod.rows}
                        placeholder={bod.placeholder}
                        value={form[`${bod.value}`]?.value || ""}
                        onChange={(e) => handleChange(e, `${bod.value}`)}
                        required
                        onBlur={handleBlur}
                        onFocus={() => setFocusedInput(bod.value)}
                        maxLength={255}
                        style={{
                          borderColor: form[bod.value]?.error
                            ? "#FF9494"
                            : undefined,
                          backgroundColor:
                            (isSessionPage &&
                              formDisabled &&
                              inputBackgroundColor) ||
                            undefined,
                          opacity: isSessionPage && formDisabled ? "0.5" : "1",
                          fontWeight:
                            focusedInput !== bod.value &&
                            form[`${bod.value}`].value
                              ? "bold"
                              : undefined,
                        }}
                        disabled={isSessionPage && formDisabled}
                        autoComplete="off"
                      />
                      <div style={{ color: "#EB4335", fontSize: 12 }}>
                        {form[`${bod.value}`]?.error &&
                          "This field is required"}
                      </div>
                    </Form.Group>
                  </InputGroup>
                );
              } else if (bod.type === "yesno") {
                return (
                  <InputGroup key={index}>
                    <Form.Group>
                      <InputLabel>{bod.label}</InputLabel>
                      {bod.content.map((content, index) => (
                        <div key={index}>
                          <YesNoInput
                            key={index}
                            name={content.value}
                            placeholder={content.label}
                            value={form[`${content.value}`]?.value || ""}
                            onChange={(e) =>
                              handleChange(e, `${content.value}`)
                            }
                            required
                            onBlur={handleBlur}
                            onFocus={() => setFocusedInput(content.value)}
                            focusedInput={focusedInput}
                            inputBackgroundColor={
                              (isSessionPage &&
                                formDisabled &&
                                inputBackgroundColor) ||
                              undefined
                            }
                            isError={form[`${content.value}`]?.error}
                            disabled={isSessionPage && formDisabled}
                          />
                          <div
                            style={{
                              color: "#EB4335",
                              fontSize: 12,
                              marginBottom: 5,
                            }}
                          >
                            {form[`${content.value}`]?.error &&
                              "This field is required"}
                          </div>
                        </div>
                      ))}
                    </Form.Group>
                  </InputGroup>
                );
              } else if (bod.type === "dropdown") {
                return (
                  <InputGroup key={index}>
                    <Form.Group>
                      <InputLabel>{bod.label}</InputLabel>
                      <CustomDropdown
                        name={bod.value}
                        value={form[`${bod.value}`]?.value || ""}
                        placeholder={bod.placeholder}
                        onSelect={updateData}
                        options={bod.options}
                        action={(value) =>
                          handleChangeDropdown(value, "number_of_employees")
                        }
                        selectedKey={"name"}
                        isError={form[`${bod.value}`]?.error}
                        inputBackgroundColor={
                          (isSessionPage &&
                            formDisabled &&
                            inputBackgroundColor) ||
                          undefined
                        }
                        disabled={isSessionPage && formDisabled}
                      />
                      <div style={{ color: "#EB4335", fontSize: 12 }}>
                        {form[`${bod.value}`]?.error &&
                          "This field is required"}
                      </div>
                    </Form.Group>
                  </InputGroup>
                );
              }
            })}
          </CollapsibleContent>
        </Collapsible>
      ))}
    </SessionFormTab>
  );
}

export default EntrepreneurshipForm;

const SessionFormTab = styled.div`
  margin: 16px 0;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const InputLabel = styled.label`
  margin-bottom: 5px;
  font-size: ${fontSize.title2};
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  :focus {
    outline: none;
    border-width: 3px;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const TextArea = styled.textarea`
  width: 100%;
  border: 2px solid #00bb99;
  padding: 10px 15px;
  border-radius: 10px;
  &:focus {
    outline: none;
    border-width: 3px;
  }
`;
const CollapsibleTrigger = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f3f3;
  font-size: 20px;
  padding: 12px 30px;
  font-weight: bold;
  color: #007c7c;
  margin: 5px -30px;
  user-select: none;
  :hover {
    background: #f3f3f3;
    opacity: 0.8;
  }
`;

const CollapsibleContent = styled.div`
  padding: 10px 0;
`;

const EditSaveButton = styled.button`
  border: none;
  background-color: transparent;
  margin-left: auto;
  margin-right: 8px;
  color: #00bb99;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 12px;
  user-select: none;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;
