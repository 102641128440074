import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Lottie
import Lottie from "react-lottie";
import loadingAnimatedIcon from "../static/loading-dots-blue.json";

// Images & Icons
import verified_icon from "../assets/icons/verified_icon.svg";
import link_expired_icon from "../assets/icons/link_expired_icon.svg";
// import error_verifying_icon from "../assets/icons/error_verifying_icon.svg";

// Components
import LoginRegisterContainer from "../components/LoginRegisterContainer";

// Styles
import "../styles/verifyAccountRegistered.css";

// Apis
import { verifyRegistrationCode } from "../apis/registrationCodes";

const VerifyAccountRegistered = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimatedIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const showAlert = (text, type) => {
    if (type === "success") {
      setSuccess(text);
    } else {
      setError(text);
    }
  };

  async function handleVerify() {
    setIsLoading(true);
    try {
      const body = { url: `${pathname}${search}` };
      const res = await verifyRegistrationCode(body);
      if (res && res.data) {
        await setIsLoading(false);
        showAlert("Verification success", "success");
      }
    } catch (error) {
      setIsLoading(false);
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        showAlert(error.response.data.message, "error");
      }
    }
  }

  const renderVerified = () => {
    return (
      <>
        <img src={verified_icon} alt="verified_icon" height={130} width={130} />
        <div className="verifyAccountRegisteredTextStyle">Verified</div>
        <div className="verifyAccountRegisteredSmallTextStyle">
          You have successfully verified your account
        </div>
        <button
          onClick={() => navigate("/login")}
          className="verifyAccountRegisteredGetStartedBtn"
        >
          Get started
        </button>
      </>
    );
  };

  const renderErrorVerifying = () => {
    if (error && error.toLowerCase() === "code expired") {
      return (
        <>
          <img
            src={link_expired_icon}
            alt="link_expired_icon"
            height={130}
            width={130}
          />
          <div className="verifyAccountRegisteredTextStyle">
            Email verification link has expired
          </div>
          <div className="verifyAccountRegisteredSmallTextStyle">
            Your email verification link has expired. Please try to resend by
            registering your account.
          </div>
          <button
            className="verifyAccountRegisteredGetStartedBtn"
            onClick={() => navigate("/register")}
          >
            Try again
          </button>
        </>
      );
    } else {
      return (
        <>
          <img
            src={link_expired_icon}
            alt="error_verifying_icon"
            height={130}
            width={130}
          />
          <div className="verifyAccountRegisteredTextStyle">
            Oops, we cannot verify your account
          </div>
          <div className="verifyAccountRegisteredSmallTextStyle">
            Please re-check your verification link
          </div>
        </>
      );
    }
  };

  const renderVerifying = () => {
    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={500}
          width={500}
          style={{
            cursor: "default",
            margin: "-100px auto",
          }}
          isClickToPauseDisabled={true}
        />
        <div className="verifyAccountRegisteredLoadingText">
          Loading, please kindly wait.....
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (pathname && search) {
      handleVerify();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [pathname, search]);

  return (
    <LoginRegisterContainer
      isLoading={isLoading}
      error={error}
      success={success}
    >
      {!isLoading
        ? success
          ? renderVerified()
          : renderErrorVerifying()
        : renderVerifying()}
    </LoginRegisterContainer>
  );
};

export default VerifyAccountRegistered;
