import React from "react";
import styled from "styled-components";
import add_new_mentor_icon from "../../assets/icons/add_new_mentor_icon.png";

function MentorButton({ handleButton, text, disabled }) {
  return (
    <Container>
      <Button onClick={handleButton} disabled={disabled}>
        <Text>{text}</Text>
        <img
          src={add_new_mentor_icon}
          alt="add_new_mentor_icon"
          style={{ maxWidth: "25px" }}
        />
      </Button>
    </Container>
  );
}

export default MentorButton;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  background: #e5e5e5;
  border-radius: 3px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  &:hover {
    border: ${({ disabled }) => (disabled ? "none" : "1px solid #00BB99")};
  }
`;

const Text = styled.span`
  margin-right: 0.5em;
`;

const Image = styled.img`
  max-width: 25px;
`;
