import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import LoginRegisterContainer from "../components/LoginRegisterContainer";

// Bootstrap
import Form from "react-bootstrap/Form";

// Styles
import "../styles/login.css";

// Icons & Images
import google_icon from "../assets/icons/google_icon.svg";

// Constants
import { EMAIL_REGEX, PASSWORD_REGEX } from "../constants";

// React Icons / ai
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Apis
import { loginUser, sendOtp, checkUserSession } from "../apis/authentications";

// sweetalert
import Swal from "sweetalert2";

// Cookies
import Cookies from "js-cookie";

// reducer
import {
  SET_IS_INTERNAL,
  SET_USER_DETAIL,
  SET_USER_ROLE,
} from "../store/actions";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const token = Cookies.get("ut");

  const innerWidth = window.innerWidth;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [captchaCode, setCaptchaCode] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm({ mode: "onChange" });
  const handleError = (errors) => {};

  const onReCAPTCHAChange = (captchaCode) => {
    if (!captchaCode) {
      return;
    }
    setCaptchaCode(captchaCode);
  };

  const handleSendOtp = async () => {
    const result = await sendOtp();
    if (result && result.data) {
      setIsLoading(false);
      navigate("/login-verification");
    }
  };

  async function handleLogin(data) {
    if (data.email && data.password && captchaCode) {
      setIsLoading(true);
      try {
        const res = await loginUser({
          email: data.email,
          password: data.password,
          captcha: captchaCode,
        });
        if (res && res.data && res.data.id) {
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Success login",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          handleSendOtp();
        }
      } catch (err) {
        err.response.data.message &&
          setError(err.response.data.message.toString().replace(",", ", "));
        recaptchaRef?.current?.reset();
        setCaptchaCode(null);
        if (err.response.data.is_not_validated) {
          Swal.fire({
            icon: "info",
            text: "Please wait a moment, your account is still in review by our admin",
          });
          // navigate("/register");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleGetUsersProfile = async () => {
    try {
      const result = await checkUserSession();
      if (result && result.data) {
        const { data: { is_admin, is_new_user, role } = {} } = result || {};
        dispatch(SET_USER_DETAIL(result.data));
        // Set user role
        dispatch(SET_USER_ROLE(role?.name));
        if (is_admin) {
          dispatch(SET_IS_INTERNAL(true));
          navigate("/internal/bookings");
        } else {
          dispatch(SET_IS_INTERNAL(false));
          if (is_new_user) {
            navigate("/profile");
          } else {
            navigate("/");
          }
        }
      } else {
        navigate("/login");
      }
    } catch (err) {
      navigate("/login");
    }
  };

  useEffect(() => {
    if (token) {
      handleGetUsersProfile();
    }
  }, [token]);

  const formOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address",
      },
    },
    password: {
      required: "Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
  };

  return (
    <LoginRegisterContainer
      isLoading={isLoading}
      error={error}
      success={success}
    >
      <Form
        onSubmit={handleSubmit(handleLogin, handleError)}
        className="loginFormMainContainer"
      >
        <div className="loginWelcomeText">Welcome back!</div>
        <button className="loginGoogleBtnContainer">
          <img src={google_icon} alt="google_logo" height={25} width={25} />
          Log in with Google
        </button>
        <div>or</div>
        <Form.Group
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Label
            style={{
              fontWeight: "bold",
              color: "black",
              textAlign: "left",
            }}
            htmlFor="email"
          >
            Email address
          </Form.Label>
          <Form.Control
            id="email"
            type="email"
            name="email"
            placeholder="yourname@mail.com"
            error={errors?.email}
            autoComplete="off"
            {...register("email", formOptions.email)}
            style={{
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: errors && errors.email ? "#EB4335" : "#00BB99",
              backgroundColor:
                errors && errors.email ? "#FFE1E1" : "transparent",
              borderRadius: "10px",
              [":focus"]: {
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: errors && errors.email ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.email ? "#FFE1E1" : "transparent",
              },
              display: "inline-block",
            }}
          />
          <small className="text-danger" style={{ userSelect: "none" }}>
            {errors?.email && errors.email.message}
          </small>
        </Form.Group>
        <Form.Group
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Form.Label
            htmlFor="password"
            style={{
              fontWeight: "bold",
              color: "black",
              padding: 0,
              display: "inline-flex",
              alignItems: "center",
              gap: 5,
            }}
          >
            Password
            {/* <Tooltip content="Your password must at least 8 characters, including uppercase, lowercase, numbers, and special characters" /> */}
          </Form.Label>
          <div
            style={{
              position: "relative",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Control
              id="password"
              type={passwordType}
              name="password"
              placeholder="••••••••••••••"
              error={errors?.password}
              autoComplete="off"
              {...register("password", formOptions.password)}
              style={{
                borderWidth: "2px",
                borderStyle: "solid",
                borderColor: errors && errors.password ? "#EB4335" : "#00BB99",
                backgroundColor:
                  errors && errors.password ? "#FFE1E1" : "transparent",
                borderRadius: "10px",
                [":focus"]: {
                  borderWidth: "2px",
                  borderStyle: "solid",
                  borderColor:
                    errors && errors.password ? "#EB4335" : "#00BB99",
                  backgroundColor:
                    errors && errors.password ? "#FFE1E1" : "transparent",
                },
                display: "inline-block",
              }}
            />
            <input
              autoComplete="off"
              style={{ display: "none" }}
              id="show1"
              type="checkbox"
              checked={passwordType === "text"}
              onChange={() =>
                setPasswordType((prev) =>
                  prev === "password" ? "text" : "password"
                )
              }
            />
            <label
              htmlFor="show1"
              style={{
                position: "absolute",
                right: 0,
                margin: "0 12px",
                cursor: "pointer",
              }}
            >
              {passwordType === "password" ? (
                <AiFillEye size={25} />
              ) : (
                <AiFillEyeInvisible size={25} />
              )}
            </label>
          </div>
          <small className="text-danger" style={{ userSelect: "none" }}>
            {errors?.password && (
              <>
                {errors.password.message}
                <br />
              </>
            )}
          </small>
        </Form.Group>
        <div style={{ width: "80%", textAlign: "right" }}>
          <div>
            <div
              style={{
                fontSize: 12,
                textDecoration: "underline",
                color: "black",
                userSelect: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("/forgot-password")}
            >
              Forgot your password?
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: innerWidth < 768 ? "110%" : "",
          }}
        >
          <div
            style={{
              transform: innerWidth < 768 ? "scale(0.8)" : "scale(1)",
              transformOrigin: "0 0",
              width: innerWidth < 768 ? "100%" : "",
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
              onChange={onReCAPTCHAChange}
            />
          </div>
        </div>
        <button
          disabled={
            isLoading || !isDirty || !isValid || isSubmitting || !captchaCode
          }
          type="submit"
          className="loginButton"
          style={{
            [":hover"]: {
              background: "#00BB99",
              cursor:
                isLoading ||
                !isDirty ||
                !isValid ||
                isSubmitting ||
                !captchaCode
                  ? ""
                  : "pointer",
              opacity:
                isLoading ||
                !isDirty ||
                !isValid ||
                isSubmitting ||
                !captchaCode
                  ? "0.5"
                  : "0.8",
            },
            opacity:
              isLoading || !isDirty || !isValid || isSubmitting || !captchaCode
                ? "0.5"
                : "1",
          }}
        >
          Log in
        </button>
        <div
          onClick={() => navigate("/register")}
          style={{ cursor: "pointer" }}
        >
          <a
            style={{
              fontSize: "12px",
              color: "black",
              [":hover"]: {
                color: "blue",
              },
            }}
          >
            Don&apos;t have an account?{" "}
            <span style={{ fontWeight: "bold" }}>Join now</span>
          </a>
        </div>
        <div
          style={{
            fontSize: 12,
            color: "black",
          }}
        >
          This site is protected by reCAPTCHA
        </div>
      </Form>
    </LoginRegisterContainer>
  );
};

export default Login;
