// Method 1
import {
  legacy_createStore as createStore,
  //  applyMiddleware
} from "redux";
import reducer from "./reducers";
// import redux from 'redux-thunk';

// let store = createStore(reducer, applyMiddleware(redux));
let store = createStore(reducer);
export default store;

// Method 3
// import { configureStore } from "@reduxjs/toolkit";
// import { modalSlicer } from "./slices/modal";

// export const store = configureStore({
//     reducer:{
//         modal: modalSlicer
//     }
// })

// Method 2
// import { useMemo } from "react";
// import { legacy_createStore as createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunkMiddleware from 'redux-thunk';
// import reducers from "./reducers";

// let store;

// function initStore(initialState) {
//     return createStore(
//         reducers,
//         initialState,
//         composeWithDevTools(applyMiddleware(thunkMiddleware))
//     )
// };

// export const initializeStore = (preloadedState) => {
//     let initialStore = store ?? initStore(preloadedState)

//     if (preloadedState && store) {
//         initialStore = initStore({
//             ...store.getState(),
//             ...preloadedState
//         })

//         // Reset store
//         store = undefined
//     }

//     if (typeof window === 'undefined') {
//         return initialStore;
//     }

//     // Create the store once in the client
//     if (!store) store = initialStore

//     return initialStore;
// };

// export function useStore(initialState) {
//     return useMemo(() => initializeStore(initialState), [initialState])
// }
