// Method 1
export const SET_SCHEDULE_MODAL_TRUE = () => {
  return { type: "SET_SCHEDULE_MODAL_TRUE", payload: true };
};

export const SET_SCHEDULE_MODAL_FALSE = () => {
  return { type: "SET_SCHEDULE_MODAL_FALSE", payload: false };
};

export const SET_APPROVAL_MODAL_TRUE = () => {
  return { type: "SET_APPROVAL_MODAL_TRUE", payload: true };
};

export const SET_APPROVAL_MODAL_FALSE = () => {
  return { type: "SET_APPROVAL_MODAL_FALSE", payload: false };
};

export const SET_INTERNAL_TABLE_VALUE = (payload) => {
  return { type: "SET_INTERNAL_TABLE_VALUE", payload: payload };
};

export const SET_DECLINE_MODAL_TRUE = () => {
  return { type: "SET_DECLINE_MODAL_TRUE", payload: true };
};

export const SET_DECLINE_MODAL_FALSE = () => {
  return { type: "SET_DECLINE_MODAL_FALSE", payload: false };
};

export const SET_USER_ROLE = (payload) => {
  return { type: "SET_USER_ROLE", payload: payload };
};

export const SET_IS_INTERNAL = (payload) => {
  return { type: "SET_IS_INTERNAL", payload: payload };
};

export const SET_USER_DETAIL = (payload) => {
  return { type: "SET_USER_DETAIL", payload: payload };
};

export const SET_USER_LOGIN_OTP_TOKEN = (payload) => {
  return { type: "SET_USER_LOGIN_OTP_TOKEN", payload: payload };
};

export const SET_OPEN_NOTIFICATION_POP_UP = (payload) => {
  return { type: "SET_OPEN_NOTIFICATION_POP_UP", payload: payload };
};

export const SET_USERS_NOTIFICATIONS = (payload) => {
  return { type: "SET_USERS_NOTIFICATIONS", payload: payload };
};

export const SET_SEARCH_KEY = (payload) => {
  return { type: "SET_SEARCH_KEY", payload: payload };
};

export const SET_ACCORDION_FORM = (payload) => {
  return { type: payload.type, payload: payload.value };
};

// Method 2
// import * as types from './types'

// export const schedule_modal_true = () => ({ type: types.SET_APPROVAL_MODAL_TRUE })

// export const schedule_modal_false = () => ({ type: types.SET_SCHEDULE_MODAL_FALSE })

// export const approval_modal_true = () => ({ type: types.SET_APPROVAL_MODAL_TRUE })

// export const approval_modal_false = () => ({ type: types.SET_APPROVAL_MODAL_FALSE })
