import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// images & icons
import add_new_mentor_icon from "../assets/icons/add_new_mentor_icon.png";
import sort_icon from "../assets/icons/sort_icon.svg";

// Apis
import { getAllTopics } from "../apis/topics";
import { deleteCoachingTopic } from "../apis/internalApis/topics";

// sweetalert
import Swal from "sweetalert2";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import Pagination from "../components/layout/Pagination";
import TableInternalComponent from "../components/TableInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import SortModal from "../components/SortModal";
import ModalComponent from "../components/ModalComponent";

const InternalTopics = () => {
  const navigate = useNavigate();

  const userDetail = useSelector((state) => state.userDetail);
  const searchKey = useSelector((state) => state.searchKey);

  const [isLoading, setLoading] = useState(false);
  const [topics, setTopics] = useState([]);

  const [savedTopicIdToDelete, setSavedTopicIdToDelete] = useState(null);
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);

  const [sortKey, setSortKey] = useState({ newest: false, oldest: false });
  const [isSortModalOpened, setSortModalOpened] = useState(false);

  const initialDataState =
    topics && topics.length > 0
      ? topics
          .map(({ id, title, is_active, createdAt }) => {
            const date = new Date(createdAt).toLocaleString();
            return {
              id,
              title,
              created_on: date,
              is_active,
            };
          })
          .sort(function (a, b) {
            return new Date(b.created_on) - new Date(a.created_on);
          })
      : [];

  const [data, setData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setLoading(false);
  };

  // Change router to view mentor details
  function handleViewDetails(d) {
    navigate(`/internal/topics/${d["id"]}`);
  }

  // Pagination - 1
  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  const handleFormatFirstFetchPaginatedData = (topics) => {
    let initialDataState = topics
      .map(({ id, title, is_active, createdAt }) => {
        const date = new Date(createdAt).toLocaleString();
        return {
          id,
          title,
          created_on: date,
          is_active,
        };
      })
      .sort(function (a, b) {
        return new Date(b.created_on) - new Date(a.created_on);
      });
    if (searchKey) {
      initialDataState = initialDataState.filter((data) => {
        const { title } = data || {};
        let lowercaseSearchkey = searchKey.toLowerCase();
        let titleSearch = title.toLowerCase();
        if (titleSearch.includes(lowercaseSearchkey)) {
          return data;
        }
      });
    }
    setData(initialDataState);
    const endOffset = itemOffset + perPage;
    setPaginatedData(initialDataState.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
  };

  const handleEditTopic = (d) => {
    const id = d["id"];
    navigate(`/internal/topics/edit/${id}`);
  };

  const onDeleteTopic = (d) => {
    const id = d["id"];
    setSavedTopicIdToDelete(id);
    setIsOpenModalDelete(true);
  };

  function handleAddTopic() {
    navigate(`/internal/topics/add`);
  }

  const handleOnDeleteTopic = async () => {
    setLoading(true);
    try {
      const response = await deleteCoachingTopic(savedTopicIdToDelete);
      if (response && response.data) {
        Swal.fire({
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: response.data.message,
          width: "20em",
          showConfirmButton: false,
          timer: 1500,
        });
        handleFetchTopics();
        setIsOpenModalDelete(false);
      }
    } catch (err) {
      setLoading(false);
      Swal.fire({
        customClass: { popup: "mentor-popup" },
        position: "top-end",
        icon: "error",
        title: err.response.data.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  function handleCancelDeleteTopic(e) {
    e.preventDefault();
    setIsOpenModalDelete(false);
    setSavedTopicIdToDelete(null);
  }

  function handleOnSort(type) {
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.created_on) - new Date(a.created_on);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.created_on) - new Date(b.created_on);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
  }

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFormatFirstFetchPaginatedData(topics);
    } else {
      handleFormatFirstFetchPaginatedData(topics, searchKey);
    }
  };

  useEffect(() => {
    handleFetchTopics();
  }, []);

  useEffect(() => {
    if (topics && Array.isArray(topics) && topics.length > 0) {
      handleFormatFirstFetchPaginatedData(topics);
    }
  }, [topics]);

  // Pagination - 2
  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <InnerContainer>
          <MentorTableHeader>
            <ButtonContainer>
              <FilterSortButton onClick={handleOnClickSortButton}>
                <img
                  src={sort_icon}
                  alt="sort_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Sort
              </FilterSortButton>
            </ButtonContainer>
            <AddTopicContainer>
              <AddTopicButton onClick={handleAddTopic}>
                <AddTopicText>Add new coaching topic</AddTopicText>
                <AddTopicImage
                  src={add_new_mentor_icon}
                  alt="add_new_mentor_icon"
                />
              </AddTopicButton>
            </AddTopicContainer>
          </MentorTableHeader>
          <SortModal
            show={isSortModalOpened.toString()}
            handleClose={() => {
              setSortModalOpened(false);
            }}
            handleOnSort={handleOnSort}
            sortKey={sortKey}
            user_detail={userDetail}
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            itemOffset={itemOffset + 1}
            endOffset={itemOffset + perPage}
            totalItems={initialDataState.length}
          >
            <TableInternalComponent
              tableData={paginatedData}
              topics={topics}
              table_name="Topics"
              onClickRow={handleViewDetails}
              onClickEdit={handleEditTopic}
              onClickDelete={onDeleteTopic}
              is_editable
              is_deleteable
            />
          </Pagination>
          <ModalComponent
            show={isOpenModalDelete}
            body={[
              <p style={{ fontWeight: "700" }} key={0}>
                Are you sure want to delete this topic?
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={() => handleOnDeleteTopic()}
            onSecondaryClick={(e) => handleCancelDeleteTopic(e)}
          />
        </InnerContainer>
      </OuterContainer>
    </div>
  );
};

export default InternalTopics;

const OuterContainer = styled.div`
  padding-left: 350px;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
  text-align: left;
`;

const MentorTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;

const AddTopicContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const AddTopicButton = styled.button`
  display: flex;
  background: #e5e5e5;
  border-radius: 3px;
  border: transparent;
  padding: 0.5em 1em;
  transition: 0.2s;
  &:hover {
    border: 1px solid #00bb99;
  }
`;

const AddTopicText = styled.span`
  margin-right: 0.5em;
`;

const AddTopicImage = styled.img`
  max-width: 25px;
`;
