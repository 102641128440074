import React from "react";

// Styles
import "../styles/LoginRegisterContainer.css";

// Icons & Images
import ccsLogoLight from "../assets/icons/ccs_logo_light.png";

// Bootstrap
import Card from "react-bootstrap/Card";

// Components
import AlertComponent from "./AlertComponent";
import SpinnerComponent from "./SpinnerComponent";

const LoginRegisterContainer = ({ children, isLoading, error, success }) => {
  return (
    <div className="loginRegisterContainer">
      <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
      <div className="ccsLogoLightMainContainer">
        <div className="ccsLogoLightContainer">
          <img
            src={ccsLogoLight}
            alt="css_logo"
          />
        </div>
      </div>

      <div className="loginRegisterAlertMainContainer">
        <Card
          style={{
            width: "55vw",
            minHeight: "75vh",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            padding: "32px 0",
          }}
        >
          <AlertComponent
            isShown={success || error ? true : false}
            variant={success ? "success" : "danger"}
            text={success ? success : error}
          />
          {children}
        </Card>
      </div>
    </div>
  );
};

export default LoginRegisterContainer;
