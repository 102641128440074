import Axios from "../helpers/axios";

export const registerUser = (body) => {
  return Axios.post(`/users/register`, body);
};

export const verifyRegisteredUser = (body) => {
  return Axios.post(`/users/verify`, body);
};

export const loginUser = (body) => {
  return Axios.post("/users/login", body);
};

export const sendOtp = () => {
  return Axios.post("/users/otp/send");
};

export const getOtpCountdown = () => {
  return Axios.post("/users/otp/countdown");
};

export const resendOtp = () => {
  return Axios.post("/users/otp/resend");
};

export const verifyOtp = (body) => {
  return Axios.post("/users/otp/verify", body);
};

export const checkUserSession = () => {
  return Axios.post("/users/check-session");
};

export const logoutUser = () => {
  return Axios.post("/users/signout");
};

export const updateUserDetail = (body) => {
  return Axios.post(`/users/update/details`, body);
};

export const forgetPasswordRequest = (body) => {
  return Axios.post(`/users/reset-password/send`, body);
};

export const verifyResetPasswordCode = (body) => {
  return Axios.post(`/users/reset-password/verify`, body);
};

export const resetPassword = (body) => {
  return Axios.post(`/users/reset-password/reset`, body);
};

export const updatePasswordFromProfile = (body) => {
  return Axios.post(`/users/update/password`, body);
};
