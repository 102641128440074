import axios from "axios";
import getServerURL from "./get-server-url";


const Axios = axios.create({
  baseURL: getServerURL(),
  withCredentials: true,
});

export default Axios;
