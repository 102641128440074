import React, { useEffect } from "react";
import { DayPicker } from "react-day-picker";

function ReactDayPicker({ selectedDate, onSelectedDate, scheduledDates }) {
  const scheduledStyle = {
    border: "1px solid #00BB99",
  };

  const scheduleDisabledStyle = {
    color: "#9C9D9F",
  };

  return (
    <>
      <DayPicker
        mode="single"
        selected={selectedDate}
        onSelect={onSelectedDate}
        modifiers={{
          schedule: scheduledDates
            .filter((value) => value.is_available)
            .map((value) => new Date(value.date)),
          scheduleDisabled: scheduledDates
            .filter((value) => !value.is_available)
            .map((value) => new Date(value.date)),
        }}
        modifiersStyles={{
          schedule: scheduledStyle,
          scheduleDisabled: scheduleDisabledStyle,
        }}
        styles={{
          button: {
            borderRadius: "20%",
            margin: "0.3em",
          },
          day_selected: {
            backgroundColor: "black",
          },
          month: {
            backgroundColor: "#FBFBFB",
            borderRadius: "20px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
          },
        }}
      />
    </>
  );
}

export default ReactDayPicker;
