import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

// user login pages
import userLoginRoutes from "../userLoginRoutes";

const AppContent = () => {
  return (
    <Suspense>
      <Routes>
        {userLoginRoutes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                exact={route.exact}
                name={route.name}
                element={<route.element />}
              />
            )
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default AppContent;
