import React, { useState } from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import dropdown_icon from "../../assets/icons/dropdown_icon.svg";

function DropdownSearch({
  name,
  value,
  onChange,
  placeholder,
  datalist,
  keyToShow = "name",
  keyToSend = "id",
  disabled = false,
  error = false,
  user_detail,
}) {
  const [selectedOption, setSelectedOption] = useState(
    datalist
      .filter((item) => item.id === value)
      .map((item) => {
        return { value: item[keyToSend], label: item[keyToShow] };
      })[0]
  );

  const handleChange = (option) => {
    setSelectedOption(option);
    onChange(option.value);
  };

  const options = datalist.map((item) => {
    return { value: item[keyToSend], label: item[keyToShow] };
  });

  const DropdownIndicator = (props) => {
    if (disabled) return null;
    return (
      <components.DropdownIndicator {...props}>
        <img src={dropdown_icon} alt="dropdown-icon" width={15} height={10} />
      </components.DropdownIndicator>
    );
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor:
        selectedOption || disabled
          ? `${
              user_detail && user_detail.is_admin === false
                ? "#e7eeff"
                : "#D7FFFF"
            }`
          : "transparent",
      border: error
        ? "2px solid #EB4335"
        : `2px solid ${
            user_detail && user_detail.is_admin === false
              ? "#2c65f7"
              : "#00BB99"
          } `,
      borderRadius: "10px",
      padding: "10px 15px",
      boxShadow: "none",

      "&:hover": {
        border: error
          ? "2px solid #EB4335"
          : `2px solid ${
              user_detail && user_detail.is_admin === false
                ? "#2c65f7"
                : "#00BB99"
            } `,
        borderRadius: "10px",
        boxShadow: "none",
      },
      "&:focus": {
        border: error ? "2px solid #EB4335" : "2px solid #00BB99",
        borderRadius: "10px",
        boxShadow: "none",
      },
      "&:active": {
        border: error ? "2px solid #EB4335" : "2px solid #00BB99",
        borderRadius: "10px",
        boxShadow: "none",
      },
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      fontSize: "inherit",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 0,
    }),
    singleValue: (base) => ({
      ...base,
      color: "black",
    }),
    placeholder: (base) => ({
      ...base,
      padding: 0,
      margin: 0,
      fontSize: "inherit",
      color: "#999",
    }),
  };

  return (
    <Container>
      <StyledSelect
        name={name}
        value={selectedOption}
        onChange={handleChange}
        placeholder={placeholder}
        options={options}
        components={{
          DropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        styles={customStyles}
        isDisabled={disabled}
      />
    </Container>
  );
}

export default DropdownSearch;

const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  text-align: left;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
