import { useState } from "react";

// React responsive
import { useMediaQuery } from "react-responsive";

// Styled components
import styled from "styled-components";

// React visibility sensor
import VisibilitySensor from "react-visibility-sensor";

// Helpers
import fontSize from "../../helpers/fontSize";

function SectionHeading({ title, subtitle, titleColor, subtitleColor }) {
  const smallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  const mediumScreen = useMediaQuery({ query: "(max-width: 1200px)" });

  const [isHeadingVisible, setHeadingVisible] = useState(false);

  return (
    <VisibilitySensor
      onChange={(isVisible) => setHeadingVisible(isVisible)}
      partialVisibility={true}
      active={!isHeadingVisible}
    >
      <HeadingContainer
        isHeadingVisible={isHeadingVisible}
        smallScreen={smallScreen}
        mediumScreen={mediumScreen}
      >
        <Title
          smallScreen={smallScreen}
          mediumScreen={mediumScreen}
          titleColor={titleColor}
        >
          {title}
        </Title>
        <Subtitle
          smallScreen={smallScreen}
          mediumScreen={mediumScreen}
          subtitleColor={subtitleColor}
        >
          {subtitle}
        </Subtitle>
      </HeadingContainer>
    </VisibilitySensor>
  );
}

export default SectionHeading;

const HeadingContainer = styled.div`
  text-align: center;
  opacity: ${({ isHeadingVisible }) => (isHeadingVisible ? 1 : 0)};
  transition: opacity 1s;
  margin-bottom: ${({ smallScreen, mediumScreen }) =>
    smallScreen ? "30px" : mediumScreen ? "30px" : "30px"};
`;

const Title = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) =>
    smallScreen ? "20px" : mediumScreen ? "25px" : fontSize.heading3Lg};
  margin-bottom: ${({ smallScreen, mediumScreen }) =>
    smallScreen ? "20px" : mediumScreen ? "25px" : "30px"};
  color: ${({ titleColor }) => titleColor || "black"};
  text-transform: uppercase;
  letter-spacing: 5px;
`;

const Subtitle = styled.div`
  font-size: ${({ smallScreen, mediumScreen }) =>
    smallScreen ? "20px" : mediumScreen ? "25px" : fontSize.heading3Lg};
  color: ${({ subtitleColor }) => subtitleColor || "black"};
  font-weight: 700;
`;
