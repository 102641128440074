import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import SortModal from "../components/SortModal";
import Pagination from "../components/layout/Pagination";
import TableComponent from "../components/TableComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import FilterModal from "../components/FilterModal";

// Styles
import "../styles/sessionHistory.css";

// Images & Icons
import empty_session_2_icon from "../assets/icons/empty_session_2_icon.svg";
import sort_icon from "../assets/icons/sort_icon.svg";
import filter_icon from "../assets/icons/filter_icon.svg";

// Apis
import { getAllUsersSessions } from "../apis/clientApis/sessions";
import { getAllTopics } from "../apis/topics";
import { getAllMentors } from "../apis/clientApis/mentors";

const SessionHistory = () => {
  const navigate = useNavigate();

  const userDetail = useSelector((state) => state.userDetail);
  const searchKey = useSelector((state) => state.searchKey);

  const [isLoading, setIsLoading] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [topics, setTopics] = useState([]);
  const [mentors, setMentors] = useState([]);

  const [perPage, setPerPage] = useState(15);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [isSortModalOpened, setSortModalOpened] = useState(false);
  const [isFilterModalOpened, setFilterModalOpened] = useState(false);
  const [isFilterChanged, setFilterChanged] = useState(false);

  const sortKeyInitialState = {
    newest: false,
    oldest: false,
  };

  const [sortKey, setSortKey] = useState(sortKeyInitialState);

  const filterKeyInitialState = {
    topic: [],
    dateTime: {},
    mentor: [],
  };

  const [filterKey, setFilterKey] = useState(filterKeyInitialState);

  const initialDataState = sessions
    .sort((a, b) => {
      return new Date(b.updatedAt) - new Date(a.updatedAt);
    })
    .map(
      ({
        booking_id,
        topic_id,
        scheduled_date,
        mentor,
        feedback,
        status,
        user_id,
        createdAt,
      }) => {
        const assignedMentor = mentor
          ? mentor?.first_name + " " + mentor?.last_name
          : "-";

        return {
          booking_id,
          topic_id,
          scheduled_date,
          assignedMentor,
          feedback,
          status,
          user_id,
          createdAt,
        };
      }
    )
    .filter((d) => d.status === "Completed");

  const [data, setData] = useState(initialDataState);
  const [paginatedData, setPaginatedData] = useState(data);

  function handlePageClick(e) {
    const newOffset = (e.selected * perPage) % data.length;
    setItemOffset(newOffset);
  }

  useEffect(() => {
    const endOffset = itemOffset + perPage;
    setPaginatedData(data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(data.length / perPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, itemOffset, perPage]);

  useEffect(() => {
    setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
  }, []);

  function handleOnClickSortButton() {
    setSortModalOpened(true);
  }

  function handleOnClickRow(d, key, value) {
    if (key !== "feedback") {
      if (key === "status" && value === "Complete your data") {
        navigate(`/coaching/book`);
      } else {
        navigate(`/session/${d["booking_id"]}`);
      }
    }
  }

  function handleOnSort(type) {
    setIsLoading(true);
    switch (type) {
      case "newest":
        const sortedData = initialDataState.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setData(sortedData);
        setSortKey((prev) => ({ ...prev, newest: true, oldest: false }));
        break;
      case "oldest":
        const sortedData2 = initialDataState.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setData(sortedData2);
        setSortKey((prev) => ({ ...prev, newest: false, oldest: true }));
        break;
      default:
        break;
    }
    setSortModalOpened(false);
    setIsLoading(false);
  }

  const handleFetchUsersSessions = async (userId, searchKey) => {
    setIsLoading(true);
    const result = await getAllUsersSessions(userId);
    if (result && result.data) {
      setSessions(result.data);
      let initialDataState = result.data
        .sort((a, b) => {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        })
        .map(
          ({
            booking_id,
            topic_id,
            scheduled_date,
            mentor,
            feedback,
            status,
            user_id,
            createdAt,
          }) => {
            const assignedMentor = mentor
              ? mentor?.first_name + " " + mentor?.last_name
              : "-";
            let finalTopicId = topics.filter((topic) => {
              if (topic_id === topic.id) {
                return topic;
              }
            });
            return {
              booking_id,
              topic_id:
                finalTopicId && finalTopicId[0] && finalTopicId[0].title
                  ? finalTopicId[0].title
                  : topic_id,
              scheduled_date,
              assignedMentor,
              feedback,
              status,
              user_id,
              createdAt,
            };
          }
        )
        .filter((d) => d.status === "Completed");
      if (searchKey) {
        initialDataState = initialDataState.filter((data) => {
          const { booking_id, assignedMentor, topic_id } = data || {};
          let lowercaseSearchkey = searchKey.toLowerCase();
          let bookingId = booking_id.toLowerCase();
          let mentorName = assignedMentor.toLowerCase();
          let topicName = topic_id.toLowerCase();
          if (
            bookingId.includes(lowercaseSearchkey) ||
            mentorName.includes(lowercaseSearchkey) ||
            topicName.includes(lowercaseSearchkey)
          ) {
            return data;
          }
        });
      }
      setData(initialDataState);
      const endOffset = itemOffset + perPage;
      setPaginatedData(initialDataState.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(data.length / perPage));
    }
    setIsLoading(false);
  };

  const handleFetchTopics = async () => {
    setIsLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      setTopics(result.data);
    }
    setIsLoading(false);
  };

  const handleFetchMentors = async () => {
    setIsLoading(true);
    const result = await getAllMentors();
    if (result && result.data) {
      setMentors(result.data);
    }
    setIsLoading(false);
  };

  function handleOnClickFilterButton() {
    setFilterModalOpened(true);
  }

  const mentorList = mentors.map((mentor) => {
    return mentor.first_name + " " + mentor.last_name;
  });

  function handleApplyFilter() {
    if (
      Object.keys(filterKey).some(
        (key) =>
          filterKey[key].length > 0 ||
          (filterKey[key].from && filterKey[key].to)
      )
    ) {
      const filteredData = initialDataState.filter((d) => {
        let isValid = true;
        if (filterKey.topic.length > 0) {
          isValid = filterKey.topic.includes(d.topic_id.toString());
        }
        if (filterKey.mentor.length > 0) {
          isValid = filterKey.mentor.includes(d.assignedMentor);
        }
        if (filterKey.dateTime.from && filterKey.dateTime.to) {
          isValid =
            new Date(d.scheduled_date) >= new Date(filterKey.dateTime.from) &&
            new Date(d.scheduled_date) <= new Date(filterKey.dateTime.to);
        }
        return isValid;
      });
      setData(filteredData);
    } else {
      setData(initialDataState);
    }
    setFilterChanged(false);
  }

  function handleOnFilter(type, name, value) {
    setFilterChanged(true);
    switch (type) {
      case "topic":
        if (value) {
          setFilterKey((prev) => ({ ...prev, topic: [...prev.topic, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            topic: prev.topic.filter((d) => d !== name),
          }));
        }
        break;
      case "date-time":
        if (value) {
          setFilterKey((prev) => ({ ...prev, dateTime: value }));
        }
        break;
      case "mentor":
        if (value) {
          setFilterKey((prev) => ({ ...prev, mentor: [...prev.mentor, name] }));
        } else {
          setFilterKey((prev) => ({
            ...prev,
            mentor: prev.mentor.filter((d) => d !== name),
          }));
        }
        break;
      case "reset":
        setFilterKey(filterKeyInitialState);
        break;
      case "apply-filter":
        handleApplyFilter();
        setFilterModalOpened(false);
        break;
      default:
        break;
    }
  }

  const handleFilterBySearchKey = (searchKey) => {
    if (!searchKey) {
      handleFetchUsersSessions(userDetail.id);
    } else {
      handleFetchUsersSessions(userDetail.id, searchKey);
    }
  };

  useEffect(() => {
    if (userDetail && userDetail.id) {
      handleFetchUsersSessions(userDetail.id);
      handleFetchTopics();
      handleFetchMentors();
    }
  }, [userDetail]);

  useEffect(() => {
    handleFilterBySearchKey(searchKey);
  }, [searchKey]);

  return (
    <div>
      <HeaderComponent />
      <SidebarComponent />
      {sessions && Array.isArray(sessions) && sessions.length > 0 ? (
        <div style={{ paddingLeft: "350px" }}>
          <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
          <div className="myBookingInnerContainer">
            <ButtonContainer>
              <FilterSortButton onClick={handleOnClickSortButton}>
                <img
                  src={sort_icon}
                  alt="sort_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Sort
              </FilterSortButton>
              <FilterSortButton onClick={handleOnClickFilterButton}>
                <img
                  src={filter_icon}
                  alt="filter_icon"
                  height={15}
                  width={15}
                  style={{ objectFit: "scale-down" }}
                />
                Filter
              </FilterSortButton>
            </ButtonContainer>
            <FilterModal
              show={isFilterModalOpened.toString()}
              handleClose={() => {
                setFilterModalOpened(false);
                setFilterChanged(false);
              }}
              topics={topics}
              mentorList={mentorList}
              filterKey={filterKey}
              handleOnFilter={handleOnFilter}
              dateTimeButtonList={["This Week", "This Month"]}
              isFilterChanged={isFilterChanged}
              user_detail={userDetail}
              filterTopic={true}
              filterDateTime={true}
              filterMentor={true}
            />
            <SortModal
              show={isSortModalOpened.toString()}
              handleClose={() => {
                setSortModalOpened(false);
              }}
              handleOnSort={handleOnSort}
              sortKey={sortKey}
              user_detail={userDetail}
            />
            <Pagination
              handlePageClick={handlePageClick}
              pageCount={pageCount}
              itemOffset={itemOffset + 1}
              endOffset={itemOffset + perPage}
              totalItems={initialDataState.length}
            >
              <TableComponent
                tableData={paginatedData}
                topics={topics}
                onClickRow={handleOnClickRow}
                user_detail={userDetail}
              />
            </Pagination>
          </div>
        </div>
      ) : (
        <div style={{ paddingLeft: "350px" }}>
          <div className="sessionHistoryInnerContainerEmptySession">
            <img src={empty_session_2_icon} alt="empty_session_2_icon" />
            <h4 style={{ fontSize: "22px" }}>
              You have not completed any session yet
            </h4>
            <p style={{ fontSize: "18px" }}>
              Only completed booking session will appear here
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SessionHistory;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FilterSortButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: black solid 1px;
  background-color: #f0f8ff;
  color: black;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  user-select: none;
  border-radius: 10px;
  :hover {
    filter: brightness(95%);
  }
  z-index: ${({ show }) => (show ? "2000" : "1")};
`;
