import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Date fns
import { format } from "date-fns";

// Apis
import {
  getDetailInternalBooking,
  updateSession,
} from "../apis/internalApis/sessions";
import { getAllTopics } from "../apis/topics";

// helpers
import getServerURL from "../helpers/get-server-url";

// Images and Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

// utils
import { dateFormat } from "../utils/common";

// sweetalert
import Swal from "sweetalert2";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import {
  entreformInitialState,
  tabs,
} from "../components/coaching/book/bookingForm";
import StatusComponent from "../components/StatusComponent";
import EntrepreneurshipForm from "../components/coaching/book/EntrepreneurshipForm";
import ModalComponent from "../components/ModalComponent";
import SpinnerComponent from "../components/SpinnerComponent";

const InternalBookingDetail = () => {
  const navigate = useNavigate();
  const { booking_id } = useParams();

  const userDetail = useSelector((state) => state.userDetail);

  const [isLoading, setLoading] = useState(false);
  const [session, setSession] = useState(null);
  const [topics, setTopics] = useState([]);
  const [topicTitle, setTopicTitle] = useState("");
  const [isTopicEntrepreneurship, setIsTopicEntrepreneurship] = useState(false);

  const [form, setForm] = useState(entreformInitialState);
  const [formDisabled, setFormDisabled] = useState(true);
  const [isFeedback, setIsFeedback] = useState(false);
  const [feedbackError, setFeedbackError] = useState(null);
  const [feedbackContent, setFeedbackContent] = useState(null);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  function handleChange(e, key) {
    if (e.target.value !== "") {
      setForm({
        ...form,
        [key]: { ...form[key], value: e.target.value, error: false },
      });
    } else {
      setForm({
        ...form,
        [key]: { ...form[key], value: e.target.value, error: true },
      });
    }
  }

  // Handle Dropdown
  function handleChangeDropdown(value, key) {
    setForm({ ...form, [key]: { ...form[key], value: value, error: false } });
  }

  // Handle back button
  function backToBookings() {
    return navigate(-1);
  }

  // Check platform to open document
  function openDocument() {
    function checkPlatform() {
      const isMac = navigator.userAgent.toUpperCase().indexOf("MAC") >= 0;
      if (isMac) return "/";
      return "\\";
    }
    const url = getServerURL();
    return window.open(
      url +
        session["document"]?.split(checkPlatform())?.[1] +
        checkPlatform() +
        session["document"]?.split(checkPlatform())?.[2]
    );
  }

  // Add time to compare with current date
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000).toLocaleString();
  }

  // Feedback handler
  function handleFeedback(event) {
    const feedbackText = event.target.value;
    const pattern = /^$/; // Must not empty or whitespace regex
    if (!pattern.test(feedbackText)) {
      setFeedbackContent(feedbackText);
      setIsFeedback(true);
      setFeedbackError(null);
    } else {
      setFeedbackError("Please insert the feedback");
      setIsFeedback(false);
    }
  }

  //   Update feedback
  async function updateFeedback() {
    setLoading(true);
    try {
      if (feedbackError === null) {
        const data = {
          feedback: feedbackContent,
          status: "Completed",
        };
        const response = await updateSession(session.id, data);
        if (response) {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Successfully update session",
            width: "20em",
            heightAuto: "0.5em",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.reload();
        } else {
          console.log("Response error");
        }
      } else {
        console.log("Please insert the feedback");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  // handle submit button
  function handleSubmit() {
    if (feedbackError === null && isFeedback === true) {
      setFeedbackModal(true);
    }
  }

  // close modal feedback
  function handleCloseModal() {
    setFeedbackModal(false);
  }

  const data = [
    {
      dataKey: "Booking ID",
      dataValue: booking_id ? booking_id : "-",
    },
    {
      dataKey: "Topic of interest",
      dataValue: topicTitle ? topicTitle : "-",
    },
    {
      dataKey: "Schedule session date/time",
      dataValue:
        session && session.scheduled_date
          ? format(new Date(session.scheduled_date), "dd/LL/yy HH:mm")
          : "-",
    },
    {
      dataKey: "Assigned mentor",
      dataValue:
        session && session.mentor
          ? `${session.mentor.first_name} ${session.mentor.last_name}`
          : "-",
    },
    {
      dataKey: "What are your current challenges?",
      dataValue:
        session && session.form_data
          ? session.form_data.current_challenges
          : "-",
    },
    {
      dataKey: "Online session link",
      dataValue:
        session && session.external_link ? (
          <a style={{ color: "#4285F4" }} href={session.external_link}>
            {session.external_link}
          </a>
        ) : (
          "-"
        ),
    },
    // {
    //   dataKey: `Passcode`,
    //   dataValue: "XXXX",
    // },
  ];

  const handleFetchDetailInternalBooking = async (booking_id) => {
    setLoading(true);
    const result = await getDetailInternalBooking(booking_id);
    if (result && result.data) {
      const { data } = result || {};
      setSession(data.session);
    }
    setLoading(false);
  };

  const handleFetchTopics = async () => {
    setLoading(true);
    const result = await getAllTopics();
    if (result && result.data) {
      let finalTopics = result.data;
      setTopics(finalTopics);
      const finalTopicTitle = finalTopics.filter(
        (topic) => topic.id === session.topic_id
      )[0]?.title;
      setTopicTitle(finalTopicTitle);
      const isTopicEntrepreneurship =
        session.topic_id ===
        finalTopics.filter((topic) => topic.required_documents.length > 0)[0]
          ?.id;
      setIsTopicEntrepreneurship(isTopicEntrepreneurship);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (booking_id) {
      handleFetchDetailInternalBooking(booking_id);
    }
  }, [booking_id]);

  useEffect(() => {
    if (session && session.topic_id) {
      handleFetchTopics();
    }
  }, [session]);

  // Feedback Textarea handler
  useEffect(() => {
    function isFeedbackSubmitted() {
      if (
        session &&
        session.feedback !== null &&
        new Date(session.scheduled_date).toLocaleDateString >=
          addMinutes(new Date(), 2880) &&
        userDetail &&
        userDetail.role &&
        userDetail.role.name !== "Mentor"
      ) {
        return setFeedbackSubmitted(true);
      } else {
        return setFeedbackSubmitted(false);
      }
    }
    isFeedbackSubmitted();
  }, [session, userDetail]);

  // Populate form data on detail
  useEffect(() => {
    if (isTopicEntrepreneurship) {
      let updatedState = form;

      Object.keys(form).forEach((key) => {
        if (key === "topic") {
          updatedState[key].value = session.topic_id;
        } else {
          updatedState[key].value = session.form_data[key];
        }
      });
      setForm(updatedState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, isTopicEntrepreneurship]);

  console.log(feedbackSubmitted, "feedbackSubmitted");
  console.log(session, "session");

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      {session ? (
        <OuterContainer>
          <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
          <InnerContainer>
            <Row>
              <BackNavigation onClick={backToBookings}>
                <img
                  src={arrow_left_icon_dark}
                  alt="arrow_left_icon_dark"
                  height={15}
                  width={15}
                  style={{
                    objectFit: "scale-down",
                    objectPosition: "left bottom",
                  }}
                />
                {`    Back`}
              </BackNavigation>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col>
                <PageTitle>Coaching Session Details</PageTitle>
              </Col>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <div>
                  <StatusComponent
                    disableClick={true}
                    status={session["status"]}
                    borderRadius="10px"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <UserInfoContainer>
                  <UserInfo>
                    <LabelUser>User name</LabelUser>
                    {session?.user?.user_details?.first_name &&
                    session?.user?.user_details?.last_name ? (
                      <TextUser>
                        {session.user.user_details.first_name}{" "}
                        {session.user.user_details.last_name}
                      </TextUser>
                    ) : (
                      <TextUser>Not available</TextUser>
                    )}
                    <LabelUser>Email address</LabelUser>
                    <TextUser>
                      {session.user ? session.user.email : "Not available"}
                    </TextUser>
                    <LabelUser>Booking created at</LabelUser>
                    <TextUser>{dateFormat(session.createdAt)}</TextUser>
                  </UserInfo>
                </UserInfoContainer>
                {data.map(({ dataKey, dataValue }, index) => (
                  <Row key={index}>
                    <Label>{dataKey}</Label>
                    <Text>{dataValue}</Text>
                  </Row>
                ))}
              </Col>
              <Col>
                <DropdownContainer>
                  {new Date(session.scheduled_date) <= new Date() &&
                  session.scheduled_date !== null ? (
                    <>
                      <MentorAlert>
                        <MentorTextAlert>
                          Mentor is required to write feedback maximum 2 x 24
                          hrs
                        </MentorTextAlert>
                      </MentorAlert>
                      <Label>Mentor feedback</Label>
                      <MentorTextArea
                        value={session.feedback}
                        onChange={handleFeedback}
                        disabled={feedbackSubmitted}
                        placeholder="Please input feedback here"
                      />
                      {feedbackSubmitted && (
                        <Text>
                          Feedback has been submitted on{" "}
                          {format(new Date(session.scheduled_date), "dd/LL/yyyy")}
                        </Text>
                      )}
                      <MentorErrorAlert>{feedbackError}</MentorErrorAlert>
                      {session &&
                        !session.feedback &&
                        userDetail &&
                        userDetail.role &&
                        userDetail.role.name === "Mentor" && ( // will be change to === Mentor
                          <MentorButtonContainer>
                            <MentorCancelButton>Cancel</MentorCancelButton>
                            <MentorSubmitButton
                              onClick={handleSubmit}
                              disabled={!isFeedback}
                            >
                              Submit
                            </MentorSubmitButton>
                          </MentorButtonContainer>
                        )}
                    </>
                  ) : (
                    isTopicEntrepreneurship && (
                      <div style={{ padding: "0 2rem" }}>
                        <EntrepreneurshipForm
                          tabs={tabs}
                          form={form}
                          handleChange={handleChange}
                          inputBackgroundColor="rgba(0, 187, 153, 0.1)"
                          isSessionPage={true}
                          handleChangeDropdown={handleChangeDropdown}
                          formDisabled={formDisabled}
                        />
                      </div>
                    )
                  )}
                  {isTopicEntrepreneurship && (
                    <Row>
                      <Label>Document(s) uploaded</Label>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "1rem",
                        }}
                      >
                        <p
                          style={{
                            background: "#E5E5E5",
                            border: 0,
                            borderRadius: 10,
                            minWidth: 275,
                            padding: "0.5rem",
                            margin: 0,
                            fontSize: 14,
                          }}
                        >
                          {session.document}
                        </p>
                        <button
                          style={{
                            background: "transparent",
                            border: 0,
                            padding: "0.5rem",
                            margin: 0,
                            fontSize: 12,
                          }}
                          onClick={openDocument}
                        >
                          View File
                        </button>
                      </div>
                    </Row>
                  )}
                </DropdownContainer>
              </Col>
            </Row>
          </InnerContainer>
          <ModalComponent
            show={feedbackModal}
            handleClose={handleCloseModal}
            body={[
              <p style={{ fontWeight: 700 }} key={0}>
                Please ensure the feedback is correct
              </p>,
              <p key={1}>
                You can’t make any changes once you have submitted the feedback.
              </p>,
            ]}
            buttonPrimary="Yes"
            buttonSecondary="No"
            onPrimaryClick={updateFeedback}
            onSecondaryClick={handleCloseModal}
          />
        </OuterContainer>
      ) : null}
    </div>
  );
};

export default InternalBookingDetail;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
`;

const Label = styled.h5`
  font-size: 14px;
  color: #00bb99;
`;

const Text = styled.p`
  font-size: 18px;
  color: black;
`;

const LabelUser = styled.h5`
  font-size: 14px;
  color: #00bb99;
  margin-left: 0.5em;
  margin-top: 1em;
`;

const TextUser = styled.p`
  font-size: 18px;
  color: black;
  margin-left: 0.5em;
`;

const UserInfoContainer = styled.div`
  min-width: 420px;
  max-width: 500px;
  background: #fff2de;
  border-radius: 10px;
  margin-bottom: 1em;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 200px;
`;

const BackNavigation = styled.p`
  padding: 0;
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  margin-left: 5em;
`;

const MentorAlert = styled.div`
  background: #00bb99;
  padding-left: 0.8em;
`;

const MentorTextAlert = styled.p`
  background: #f3f3f3;
  font-size: 12px;
  padding: 1em;
`;

const MentorTextArea = styled.textarea`
  padding: 0.5em;
  margin-bottom: 1em;
  width: 100%;
  min-height: 200px;
  max-height: 300px;
  border: 2px solid #00bb99;
  border-radius: 10px;
  :disabled {
    background: rgba(0, 187, 153, 0.1);
  }
`;

const MentorButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
`;

const MentorCancelButton = styled.p`
  cursor: pointer;
`;

const MentorSubmitButton = styled.button`
  margin-left: 1em;
  padding: 0.5em 1em;
  background: #00bb99;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  border: none;
  color: white;
  :disabled {
    background: #aaaaaa;
  }
`;

const MentorErrorAlert = styled.p`
  color: red;
  font-size: 12px;
`;
