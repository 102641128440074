import React from "react";
import ModalComponent from "./ModalComponent";

function RouteChangeModal({
  show,
  handleClose,
  title,
  subtitle,
  body,
  buttonPrimary,
  buttonSecondary,
  closeButton = true,
  onPrimaryClick,
  onSecondaryClick,
  bodyTextAlign = "center",
}) {
  return (
    <ModalComponent
      show={show}
      handleClose={handleClose}
      title={title}
      subtitle={subtitle}
      body={body}
      closeButton={closeButton}
      buttonPrimary={buttonPrimary}
      buttonSecondary={buttonSecondary}
      onPrimaryClick={onPrimaryClick}
      onSecondaryClick={onSecondaryClick}
      bodyTextAlign={bodyTextAlign}
      primaryColor={"#b91c1c"}
    />
  );
}

export default RouteChangeModal;
