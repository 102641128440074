import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

// axios
import axios from "axios";

// react hook form
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

// React Icons / ai
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

// Constants
import { EMAIL_REGEX, PASSWORD_REGEX } from "../constants/index";

// sweetalert
import Swal from "sweetalert2";

// components
import { industriesInitialState } from "../components/data/industry";
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import RadioButton from "../components/input/RadioButton";
import QuickFilterDropdown from "../components/input/QuickFilterDropdown";
import ReactDatePicker from "../components/input/DatePicker";
import TagsInput from "../components/input/TagsInput";
import ModalComponent from "../components/ModalComponent";
import SpinnerComponent from "../components/SpinnerComponent";

// Apis
import { createNewMentor } from "../apis/internalApis/mentors";

const AddNewMentor = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [infoName, setInfoName] = useState("personal");
  const [countries, setCountries] = useState([]);
  const [industries, setIndustries] = useState(industriesInitialState);
  const [genders, setGenders] = useState(["male", "female"]);
  const [skills, setSkills] = useState([]);
  const [assignedGender, setAssignedGender] = useState(null);
  const [assignedTitle, setAssignedTitle] = useState(null);
  const [assignedCountry, setAssignedCountry] = useState(null);
  const [assignedIndustry, setAssignedIndustry] = useState(null);
  const [assignedQualification, setAssignedQualification] = useState(null);
  const [assignedIdentification, setAssignedIdentification] = useState(null);
  const [errorSkills, setErrorSkills] = useState(null);
  const [errorGenders, setErrorGenders] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorCountry, setErrorCountry] = useState(null);
  const [errorIndustry, setErrorIndustry] = useState(null);
  const [errorQualification, setErrorQualification] = useState(null);
  const [errorIdentification, setErrorIdentification] = useState(null);
  const [errorDate, setErrorDate] = useState(null);
  const [date, setDate] = useState(null);
  const [isError, setIsError] = useState(false);
  const [isModal, setModal] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [repeatPasswordType, setRepeatPasswordType] = useState("password");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "all" });

  const formOptions = {
    email: {
      required: "Email is required",
      pattern: {
        value: EMAIL_REGEX,
        message: "Invalid email address",
      },
    },
    password: {
      required: "Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
    repeat_password: {
      required: "Password is required",

      pattern: {
        value: PASSWORD_REGEX,
        message:
          "Please enter your password at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      },
    },
    first_name: {
      required: "First name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    last_name: {
      required: "Last name is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
    phone_number: {
      required: "Phone number is required",
      pattern: {
        value: /^\d+$/,
        message: "Invalid phone number",
      },
      maxLength: {
        value: 16,
        message: "Can't be longer than 16 characters",
      },
    },
    gender: {
      required: "Gender is required",
      maxLength: {
        value: 255,
        message: "Can't be longer than 255 characters",
      },
    },
  };

  const mentorsTitle = [
    {
      name: "Mr",
    },
    {
      name: "Mrs",
    },
    {
      name: "Ms",
    },
    {
      name: "Miss",
    },
  ];

  const identifications = [
    {
      name: "KTP",
    },
    {
      name: "Passport",
    },
    {
      name: "Others",
    },
  ];

  const qualifications = [
    {
      name: "Diploma",
    },
    {
      name: "Bachelor's Degree",
    },
    {
      name: "Professional Degree",
    },
    {
      name: "Master's Degree",
    },
    {
      name: "Doctorate",
    },
  ];

  // Handle date change
  function handleDate(date) {
    const value = date;
    setDate(value);
    if (value === "") {
      setErrorDate("Please choose your date of birth");
    } else {
      setErrorDate(null);
    }
  }

  // Handle dropdown change
  function handleChangeDropdown(value, key) {
    if (key === "assignedTitle") {
      setAssignedTitle(value);
      if (!assignedTitle) {
        setErrorTitle("Please choose your title");
      } else {
        setErrorTitle(null);
      }
    }
    if (key === "assignedCountry") {
      setAssignedCountry(value);
      if (!assignedCountry) {
        setErrorCountry("Please choose your country");
      } else {
        setErrorCountry(null);
      }
    }
    if (key === "assignedIndustry") {
      setAssignedIndustry(value);
      if (!assignedIndustry) {
        setErrorIndustry("Please choose your industry");
      } else {
        setErrorIndustry(null);
      }
    }
    if (key === "assignedQualification") {
      setAssignedQualification(value);
      if (!assignedQualification) {
        setErrorQualification("Please choose your qualification");
      } else {
        setErrorQualification(null);
      }
    }
    if (key === "assignedIdentification") {
      setAssignedIdentification(value);
      if (!assignedIdentification) {
        setErrorIdentification("Please choose your identification");
      } else {
        setErrorIdentification(null);
      }
    }
    return;
  }

  // Back to mentors page
  function backToMentors() {
    return navigate(-1);
  }

  // Error handler from react-hook-form
  function ErrorHandler(name) {
    return (
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <ErrorInput>{message}</ErrorInput>}
      />
    );
  }

  // Handle gender
  function handleGender(e) {
    const value = e.target.value;
    if (value) {
      setAssignedGender(value);
      if (value === null) {
        setErrorGenders("Please choose your gender");
      } else {
        setErrorGenders(null);
      }
    }
  }

  // Handle cancel
  function cancelAdd(e) {
    e.preventDefault();
    setModal(true);
  }

  // Handle primary modal
  function handlePrimary(e) {
    e.preventDefault();
    navigate("/internal/mentors");
  }

  // Handle secondary modal
  function handleSecondary(e) {
    e.preventDefault();
    setModal(false);
  }

  // Submit add form
  async function addMentor(values) {
    setLoading(true);
    try {
      if (isError === false) {
        const body = {
          ...values,
          mentor_title: assignedTitle,
          gender: assignedGender,
          country: assignedCountry,
          date_of_birth: date,
          industry: assignedIndustry,
          identification_type: assignedIdentification,
          skills: skills,
          education_qualification: assignedQualification,
        };
        const response = await createNewMentor(body);
        if (response) {
          Swal.fire({
            imageUrl:
              "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
            position: "top-end",
            text: "Mentor has been added",
            width: "20em",
            showConfirmButton: false,
            timer: 1500,
          });
          setLoading(false);
          navigate(`/internal/mentors`);
        } else {
          setLoading(false);
          Swal.fire({
            customClass: {
              popup: "mentor-popup",
            },
            position: "top-end",
            icon: "error",
            title: "Please fill all required elements",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        if (!date) setErrorDate("Please choose your date of birth");
        if (!assignedCountry) setErrorCountry("Please choose your country");
        if (!assignedGender) setErrorGenders("Please select your gender");
        if (!assignedIdentification)
          setErrorIdentification("Please select your identification");
        if (!assignedIndustry) setErrorIndustry("Please select your industry");
        if (!assignedQualification)
          setErrorQualification("Please select your qualification");
        if (!assignedTitle) setErrorTitle("Please select your title");
        if (skills.length === 0) setErrorSkills("Please input your skills");
        setLoading(false);
        return;
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    } finally {
    }
  }

  // Custom error checker
  useEffect(() => {
    function errorChecker() {
      if (
        !assignedCountry ||
        !assignedGender ||
        !assignedIdentification ||
        !assignedIndustry ||
        !assignedQualification ||
        !assignedTitle ||
        !date ||
        !skills
      ) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    }
    errorChecker();
  }, [
    assignedCountry,
    assignedGender,
    assignedIdentification,
    assignedIndustry,
    assignedQualification,
    assignedTitle,
    date,
    skills,
  ]);

  // Get all countries
  useEffect(() => {
    async function getCountries() {
      setLoading(true);
      const response = await axios.get("https://restcountries.com/v3.1/all");
      const result = response.data
        .map((country) => {
          return {
            name: country.name.common,
          };
        })
        .sort((a, b) => {
          // Sort by country name
          let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase();
          if (fa < fb) return -1;
          if (fa > fb) return 1;
          return 0;
        });
      setCountries(result);
      setLoading(false);
    }
    getCountries();
  }, [assignedCountry]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <OuterContainer>
        <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
        <InnerContainer>
          <Row>
            <PageTitle>Add new mentor</PageTitle>
            <PageSubtitle>
              Fill in the fields below to add new mentor to Combined Clinics
              Sustainability
            </PageSubtitle>
          </Row>
          <MentorOptions>
            <MentorOptionSelect className={infoName} match="personal">
              <MentorOptionText>Personal Information</MentorOptionText>
              <MentorOptionLine className={infoName} match="personal" />
            </MentorOptionSelect>
            <MentorOptionSelect className={infoName} match="education">
              <MentorOptionText>Basic education information</MentorOptionText>
              <MentorOptionLine className={infoName} match="education" />
            </MentorOptionSelect>
            <MentorOptionSelect className={infoName} match="supporting">
              <MentorOptionText>Supporting data</MentorOptionText>
              <MentorOptionLine className={infoName} match="supporting" />
            </MentorOptionSelect>
            <MentorOptionSelect className={infoName} match="experiences">
              <MentorOptionText>Experiences</MentorOptionText>
              <MentorOptionLine className={infoName} match="experiences" />
            </MentorOptionSelect>
          </MentorOptions>
          <MentorInvisibleLine />
          <FormMentor onSubmit={handleSubmit(addMentor)}>
            <Row>
              <Col>
                <FlexRow>
                  <InputContainer>
                    <InputLabel>Email Address</InputLabel>
                    <InputMentor
                      placeholder="e.g: ccs@mail.com"
                      {...register("email", {
                        required: "Please input your email address",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message:
                            "email format is invalid, please try again..",
                        },
                      })}
                    />
                    {ErrorHandler("email")}
                  </InputContainer>
                  {/* <InputContainer>
                    <InputLabel>Title</InputLabel>
                    <CustomDropdown
                      value={assignedTitle}
                      placeholder="Select title &nbsp; &nbsp;"
                      options={mentorsTitle}
                      selectedKey="name"
                      action={(value) =>
                        handleChangeDropdown(value, "assignedTitle")
                      }
                    />
                    <ErrorInput>{errorTitle}</ErrorInput>
                  </InputContainer> */}
                  <InputContainer>
                    <InputLabel>Gender</InputLabel>
                    <RadioButton
                      name="gender"
                      data={genders}
                      state={assignedGender}
                      handleChange={handleGender}
                    />
                    <ErrorInput>{errorGenders}</ErrorInput>
                  </InputContainer>
                </FlexRow>
                <FlexRow>
                  <InputContainer>
                    <InputLabel>First Name</InputLabel>
                    <InputMentor
                      placeholder="Input first name here"
                      {...register("first_name", {
                        required: "Please input your first name",
                      })}
                    />
                    {ErrorHandler("first_name")}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Last Name</InputLabel>
                    <InputMentor
                      placeholder="Input last name here"
                      {...register("last_name", {
                        required: "Please input your last name",
                      })}
                    />
                    {ErrorHandler("last_name")}
                  </InputContainer>
                </FlexRow>
                <FlexRow>
                  <InputContainer>
                    <InputLabel>Password</InputLabel>
                    <InputMentor
                      placeholder="Input first name here"
                      {...register("first_name", {
                        required: "Please input your first name",
                      })}
                    />
                    {ErrorHandler("first_name")}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Repeat password</InputLabel>
                    <div
                      style={{
                        position: "relative",
                        // height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Form.Control
                        id="repeat_password"
                        type={repeatPasswordType}
                        name="repeat_password"
                        placeholder="••••••••••••••"
                        error={errors?.repeat_password}
                        autoComplete="off"
                        {...register(
                          "repeat_password",
                          formOptions.repeat_password
                        )}
                        style={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                          borderColor:
                            errors && errors.repeat_password
                              ? "#EB4335"
                              : "#00BB99",
                          backgroundColor:
                            errors && errors.repeat_password
                              ? "#FFE1E1"
                              : "transparent",
                          borderRadius: "10px",
                          [":focus"]: {
                            borderWidth: "2px",
                            borderStyle: "solid",
                            borderColor:
                              errors && errors.repeat_password
                                ? "#EB4335"
                                : "#00BB99",
                            backgroundColor:
                              errors && errors.repeat_password
                                ? "#FFE1E1"
                                : "transparent",
                          },
                          display: "inline-block",
                          height: "50px",
                        }}
                      />
                      <input
                        autoComplete="off"
                        style={{ display: "none" }}
                        id="show2"
                        type="checkbox"
                        checked={repeatPasswordType === "text"}
                        onChange={() =>
                          setRepeatPasswordType((prev) =>
                            prev === "password" ? "text" : "password"
                          )
                        }
                      />
                      <label
                        htmlFor="show2"
                        style={{
                          position: "absolute",
                          right: 0,
                          margin: "0 12px",
                          cursor: "pointer",
                        }}
                      >
                        {repeatPasswordType === "password" ? (
                          <AiFillEye size={25} />
                        ) : (
                          <AiFillEyeInvisible size={25} />
                        )}
                      </label>
                    </div>
                    {ErrorHandler("last_name")}
                  </InputContainer>
                </FlexRow>
                <InputContainer>
                  <InputLabel>Phone Number</InputLabel>
                  <InputMentor
                    placeholder="e.g: 08xxxxxxx"
                    {...register("phone_number", {
                      required: "Please input your phone number",
                    })}
                  />
                  {ErrorHandler("phone_number")}
                </InputContainer>
                {/* <InputContainer>
                  <InputLabel>Address</InputLabel>
                  <TextAreaMentor
                    placeholder="e.g: Sudirman street block 88 unit S, Central Jakarta"
                    {...register("address", {
                      required: "Please input your address",
                    })}
                  />
                  {ErrorHandler("address")}
                </InputContainer> */}
                {/* <FlexRow>
                  <InputContainer>
                    <InputLabel>Country</InputLabel>
                    <QuickFilterDropdown
                      options={countries}
                      placeholder="Input country"
                      state={assignedCountry}
                      handleState={setAssignedCountry}
                    />
                    <ErrorInput>{errorCountry}</ErrorInput>
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Postal Code</InputLabel>
                    <InputMentor
                      placeholder="Input postal code"
                      {...register("postal_code", {
                        required: "Please input your postal code",
                      })}
                    />
                    {ErrorHandler("postal_code")}
                  </InputContainer>
                </FlexRow> */}
                {/* <FlexRow>
                  <InputContainer>
                    <InputLabel>City</InputLabel>
                    <InputMentor
                      placeholder="Input city"
                      {...register("city", {
                        required: "Please input your city",
                      })}
                    />
                    {ErrorHandler("city")}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>State/Province</InputLabel>
                    <InputMentor
                      placeholder="Input state/province"
                      {...register("state_or_province", {
                        required: "Please input your state/province",
                      })}
                    />
                    {ErrorHandler("state_or_province")}
                  </InputContainer>
                </FlexRow> */}
              </Col>
              {/* <Col>
                <FlexRow>
                  <InputContainer>
                    <InputLabel>Place of Birth</InputLabel>
                    <InputMentor
                      placeholder="e.g: Jakarta"
                      {...register("place_of_birth", {
                        required: "Please input your place of birth",
                      })}
                    />
                    {ErrorHandler("place_of_birth")}
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Date of Birth</InputLabel>
                    <ReactDatePicker
                      date={date}
                      handleDate={(date) => handleDate(date)}
                    />
                    <ErrorInput>{errorDate}</ErrorInput>
                  </InputContainer>
                </FlexRow>
                <InputContainer>
                  <InputLabel>Company/Organization</InputLabel>
                  <InputMentor
                    placeholder="e.g: ABC company"
                    {...register("company", {
                      required: "Please input your company",
                    })}
                  />
                  {ErrorHandler("company")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Role/Position</InputLabel>
                  <InputMentor
                    placeholder="e.g: General manager"
                    {...register("role", {
                      required: "Please input your role/position",
                    })}
                  />
                  {ErrorHandler("role")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Industry</InputLabel>
                  <CustomDropdown
                    value={assignedIndustry}
                    placeholder="Select one related industry &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                    options={industries}
                    selectedKey="name"
                    action={(value) =>
                      handleChangeDropdown(value, "assignedIndustry")
                    }
                  />
                  <ErrorInput>{errorIndustry}</ErrorInput>
                </InputContainer>
                <InputContainer>
                  <InputLabel>Area of Interest / Skills</InputLabel>
                  <TagsInput tags={skills} handleTags={setSkills} />
                  <ErrorInput>{errorSkills}</ErrorInput>
                </InputContainer>
                <InputContainer>
                  <InputLabel>Identification type</InputLabel>
                  <CustomDropdown
                    value={assignedIdentification}
                    placeholder="Select type of your identity &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                    options={identifications}
                    selectedKey="name"
                    action={(value) =>
                      handleChangeDropdown(value, "assignedIdentification")
                    }
                  />
                  <ErrorInput>{errorIdentification}</ErrorInput>
                </InputContainer>
                <InputContainer>
                  <InputLabel>Identification (ID) Number</InputLabel>
                  <InputMentor
                    placeholder="Input your ID number"
                    {...register("identification_number", {
                      required: "Please input your role/position",
                    })}
                  />
                  {ErrorHandler("identification_number")}
                </InputContainer>
                <InputContainer>
                  <InputLabel>Educational Qualifications</InputLabel>
                  <CustomDropdown
                    value={assignedQualification}
                    placeholder="Select your last educational qualifications &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                    options={qualifications}
                    selectedKey="name"
                    action={(value) =>
                      handleChangeDropdown(value, "assignedQualification")
                    }
                  />
                  <ErrorInput>{errorQualification}</ErrorInput>
                </InputContainer>
              </Col> */}
            </Row>
            <Col>
              <ButtonHandlerContainer>
                <ButtonHandler onClick={(e) => cancelAdd(e)} color="#AAAAAA">
                  Cancel
                </ButtonHandler>
                <ButtonHandler type="submit" color="#00BB99">
                  Submit
                </ButtonHandler>
              </ButtonHandlerContainer>
            </Col>
            <ModalComponent
              show={isModal}
              body={[
                <p style={{ fontWeight: "700" }} key={0}>
                  Are you sure want to cancel?
                </p>,
              ]}
              buttonPrimary="Yes"
              buttonSecondary="No"
              onPrimaryClick={(e) => handlePrimary(e)}
              onSecondaryClick={(e) => handleSecondary(e)}
            />
          </FormMentor>
        </InnerContainer>
      </OuterContainer>
    </div>
  );
};

export default AddNewMentor;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
`;

const PageSubtitle = styled.p`
  margin-bottom: 2em;
`;

const MentorOptions = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 900px;
  font-weight: 700;
  color: #c4c4c4;
`;

const MentorOptionSelect = styled.div`
  color: ${({ className, match }) =>
    className === match ? "#007C7C" : "#C4C4C4"};
  /* flex-grow: 1; // Important */
`;

const MentorOptionLine = styled.hr`
  opacity: ${({ className, match }) => (className === match ? 1 : 0)};
  padding: 2px;
  margin-top: 0.5em;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: -20px;
  padding: 2px;
`;

const MentorOptionText = styled.p`
  text-align: center;
  padding: 0 1em 0;
  margin-bottom: 0;
  cursor: pointer;
  &:hover {
    color: #007c7c;
  }

  &:hover + ${MentorOptionLine} {
    opacity: 1;
    color: #007c7c;
    transition: 0.2s;
  }
`;

const FlexRow = styled.div`
  display: flex;
`;

const FormMentor = styled.form``;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-right: 2em;
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
`;

const TextAreaMentor = styled.textarea`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  min-height: 235px;
  width: 100%;
  padding: 0.5em;
`;

const ButtonHandlerContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 142px;
`;

const ButtonHandler = styled.button`
  font-size: 14px;
  padding: 0.3em 2em;
  color: white;
  border: none;
  background: ${({ color }) => (color ? color : "none")};
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.21);
  border-radius: 40px;
  margin-left: 1em;
`;

const ErrorInput = styled.p`
  font-size: 12px;
  color: red;
  margin-bottom: 0;
`;
