import React, { useEffect, useState } from "react";

import "../styles/UploadingFileToServerModal.css";

// components
import UploadingVideoProgressBar from "./UploadingVideoProgressBar";
import SpinnerComponent from "./SpinnerComponent";

// Bootstrap
import Spinner from "react-bootstrap/Spinner";

// images & icons
import close_icon_grey from "../assets/icons/close_icon_grey.png";

const UploadingFileToServerModal = (props) => {
  const {
    contents,
    handleUploadFileToServer,
    // handleUploadAllFilesToServer,
    cancelUploadVideo,
    setIsOpenUploadFileToServer,
    fileUploadedToServerCount,
    loadingUploadFile,
  } = props || {};

  const [totalContentSize, setTotalContentSize] = useState("");

  const handleGetAllContentFilesSize = (contents) => {
    let totalFileSize = 0;
    for (let c = 0; c < contents.length; c++) {
      const { file } = contents[c];
      totalFileSize += file.size;
    }
    totalFileSize = Math.floor(totalFileSize / (1024 * 1024));
    totalFileSize = `${totalFileSize} MB`;
    setTotalContentSize(totalFileSize);
  };

  useEffect(() => {
    if (contents && Array.isArray(contents) && contents.length > 0) {
      handleGetAllContentFilesSize(contents);
    }
  }, [contents]);

  return (
    <div className="fullscreenContainer">
      <div className="contentContainer">
        <div className="contentFullScreen">
          <div className="contentHeaderContainer">
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="contentTitle">
                {"Almost there. Let’s finalize to upload course contents."}
              </div>
            </div>
            {loadingUploadFile ? (
              <div style={{ marginRight: "0.75vw" }}>
                <Spinner animation="border" role="status" />
              </div>
            ) : (
              <div style={{ marginRight: "0.75vw" }}>
                <img
                  src={close_icon_grey}
                  alt="close modal"
                  onClick={() => setIsOpenUploadFileToServer(false)}
                  style={{ cursor: "pointer", width: "30px", height: "auto" }}
                />
              </div>
            )}
          </div>
          {contents && Array.isArray(contents) && contents.length > 0 ? (
            <div className="contentUploadingFileContainer">
              {contents.map((content, idx) => {
                return (
                  <div className="eachContentContainer" key={idx}>
                    <UploadingVideoProgressBar
                      videoToView={content}
                      cancelUploadVideo={cancelUploadVideo}
                      isModalUploadToServer={true}
                      handleUploadFileToServer={handleUploadFileToServer}
                      index={idx}
                      loadingUploadFile={loadingUploadFile}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // backgroundColor: "red",
            margin: "2em 3em",
            marginTop: "-1vh",
          }}
        >
          <div>
            <div style={{ fontWeight: "600" }}>
              Content(s) size : {totalContentSize}
            </div>
            <div style={{ fontWeight: "600" }}>
              {`${
                fileUploadedToServerCount ? fileUploadedToServerCount : "0"
              } of ${contents.length} 
              ${contents.length > 1 ? "videos" : "video"} uploaded 
              `}
            </div>
          </div>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              // marginTop: "-2vh",
            }}
          >
            <button
              onClick={() => handleUploadAllFilesToServer()}
              style={{
                display: "flex",
                background: "#00bb99",
                borderRadius: "7px",
                border: "transparent",
                transition: "0.2s",
                padding: "0.5em 1em",
              }}
            >
              <div style={{ color: "#ffffff" }}>Upload all</div>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default UploadingFileToServerModal;
