import React from "react";

// Bootstrap
import Spinner from "react-bootstrap/Spinner";

const SpinnerComponent = ({ isShown, left, right, top, bottom }) => {
  return (
    <div
      style={{
        display: isShown ? "block" : "none",
        position: "fixed",
        left,
        right,
        top,
        bottom,
        zIndex: 9999,
      }}
    >
      <Spinner animation="border" role="status" />
    </div>
  );
};

export default SpinnerComponent;
