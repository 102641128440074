// pdfjs worker
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const getPdfCoverImage = async (file, fileUrl) => {
  const pdf = await pdfjs.getDocument(
    file ? URL.createObjectURL(file) : fileUrl
  ).promise;

  // Get the first page of the PDF (cover page)
  const page = await pdf.getPage(1);

  // Render the first page as a canvas
  const viewport = page.getViewport({ scale: 1.0 });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  const renderContext = {
    canvasContext: context,
    viewport: viewport,
  };
  await page.render(renderContext).promise;

  // Convert the canvas to an image
  let coverImageURL = await canvas.toDataURL("image/png");

  return coverImageURL;
};

export default getPdfCoverImage;
