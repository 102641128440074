import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

// Font size helper
import fontSize from "../lib/styling/font_size";

// Styles
import "../styles/sidebarComponent.css";

// reducers
import { SET_SEARCH_KEY } from "../store/actions";

// Images & Icons
import ccs_logo_light from "../assets/icons/ccs_logo_light.png";
import home_nav_icon_white from "../assets/icons/home_nav_icon_white.svg";
import my_booking_nav_icon_white from "../assets/icons/my_booking_nav_icon_white.svg";
import session_history_nav_icon_white from "../assets/icons/session_history_nav_icon_white.svg";
import resources_nav_icon_white from "../assets/icons/resources_nav_icon_white.svg";
import subscription_nav_icon_white from "../assets/icons/subscription_nav_icon_white.svg";
import course_icon_white from "../assets/icons/course_icon_white.svg";

function SidebarComponent() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleNavigate = (url) => {
    dispatch(SET_SEARCH_KEY(""));
    navigate(url);
  };

  return (
    <div className="sidebarComponentMainContainer">
      <div
        style={{ cursor: "pointer", display: "flex" }}
        onClick={() => handleNavigate("/")}
      >
        <img
          src={ccs_logo_light}
          alt="ccs_logo_light"
          width="200px"
          height="100px"
          style={{ objectFit: "contain" }}
        />
      </div>
      <div
        className="studentPlatformSidebarComponentMainContainer"
        style={{ width: "195px" }}
      >
        <div>Student Platform</div>
      </div>
      <div style={{ padding: "30px 0" }}>
        <div
          onClick={() => handleNavigate("/")}
          className={
            location.pathname === "/"
              ? "sidebarStudentComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={home_nav_icon_white}
              alt="home_nav_icon_white"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div
              style={{
                fontWeight: location.pathname === "/" ? "bold" : "400",
                fontSize: fontSize.title2,
                color: "#FFFFFF",
              }}
            >
              Home
            </div>
          </div>
        </div>
        <div
          onClick={() => handleNavigate("/my-booking")}
          className={
            location.pathname.includes("/my-booking")
              ? "sidebarStudentComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={my_booking_nav_icon_white}
              alt="my_booking_nav_icon_white"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div
              style={{
                fontWeight: location.pathname.includes("/my-booking")
                  ? "bold"
                  : "400",
                fontSize: fontSize.title2,
                color: "#FFFFFF",
              }}
            >
              My Booking
            </div>
          </div>
        </div>
        <div
          onClick={() => handleNavigate("/session")}
          className={
            location.pathname.includes("/session")
              ? "sidebarStudentComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={session_history_nav_icon_white}
              alt="session_history_nav_icon_white"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div
              style={{
                fontWeight: location.pathname.includes("/session")
                  ? "bold"
                  : "400",
                fontSize: fontSize.title2,
                color: "#FFFFFF",
              }}
            >
              Session History
            </div>
          </div>
        </div>
        <div
          onClick={() => handleNavigate("/resources")}
          className={
            location.pathname.includes("/resources")
              ? "sidebarStudentComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={resources_nav_icon_white}
              alt="resources_nav_icon_white"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div
              style={{
                fontWeight: location.pathname.includes("/resources")
                  ? "bold"
                  : "400",
                fontSize: fontSize.title2,
                color: "#FFFFFF",
              }}
            >
              Resources/Library
            </div>
          </div>
        </div>
        <div
          onClick={() => handleNavigate("/subscription")}
          className={
            location.pathname.includes("/subscription")
              ? "sidebarStudentComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={subscription_nav_icon_white}
              alt="subscription_nav_icon_white"
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div
              style={{
                fontWeight: location.pathname.includes("/subscription")
                  ? "bold"
                  : "400",
                fontSize: fontSize.title2,
                color: "#FFFFFF",
              }}
            >
              Subscription
            </div>
          </div>
        </div>
        <div
          onClick={() => handleNavigate("/courses")}
          className={
            location.pathname.includes("/courses")
              ? "sidebarStudentComponentEachMenuMainContainerFalse"
              : "sidebarComponentEachMenuMainContainerTrue"
          }
        >
          <div className="sidebarComponentEachMenuContainer">
            <img
              src={course_icon_white}
              width="30px"
              height="30px"
              style={{ objectFit: "contain", marginRight: "15px" }}
            />
            <div
              style={{
                fontWeight: location.pathname.includes("/courses")
                  ? "bold"
                  : "400",
                fontSize: fontSize.title2,
                color: "#FFFFFF",
              }}
            >
              Courses
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarComponent;
