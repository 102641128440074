import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import styled from "styled-components";

// Apis
import { getUserDetail, updateUserRole } from "../apis/internalApis/users";
import { getAllRoles } from "../apis/internalApis/roles";

// react bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// sweetalert
import Swal from "sweetalert2";

// Images & Icons
import arrow_left_icon_dark from "../assets/icons/arrow_left_icon_dark.png";

// Components
import HeaderComponent from "../components/HeaderComponent";
import SidebarInteralComponent from "../components/SidebarInternalComponent";
import SpinnerComponent from "../components/SpinnerComponent";
import CustomDropdown from "../components/input/CustomDropdown";
import RadioButton from "../components/input/RadioButton";
import QuickFilterDropdown from "../components/input/QuickFilterDropdown";
import ReactDatePicker from "../components/input/DatePicker";
import ModalComponent from "../components/ModalComponent";

const InternalUserDetail = () => {
  const { user_id } = useParams();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);
  const [userDetail, setUserDetail] = useState(null);
  const [isModal, setModal] = useState(false);

  const [roleOptions, setRoleOptions] = useState([]);

  const [assignedRole, setAssignedRole] = useState(null);

  const { register } = useForm({ mode: "all" });

  const handleFetchUserDetail = async (user_id) => {
    setLoading(true);
    const result = await getUserDetail(user_id);
    if (result && result.data) {
      const userDetailData = result.data;
      setAssignedRole(userDetailData.role);
      setUserDetail(userDetailData);
    }
    setLoading(false);
  };

  // Handle change user role
  async function handleChangeUserRole() {
    setLoading(true);
    try {
      let finalRoleValue = roleOptions.filter(
        (roleOpt) => roleOpt.name === assignedRole
      );
      const response = await updateUserRole(user_id, {
        role_id: finalRoleValue[0].id,
        new_role_assigned: finalRoleValue[0].name,
      });
      if (response && response.data) {
        Swal.fire({
          customClass: {
            popup: "mentor-popup",
          },
          imageUrl:
            "https://cdn.discordapp.com/attachments/796711355876245534/985747738845118544/success_green_icon.png",
          position: "top-end",
          text: "User role has been changed",
          width: "20em",
          heightAuto: "0.5em",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Internal server error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
      setModal(false);
    }
  }

  // Handle dropdown change
  function handleChangeDropdown(value, key) {
    if (key === "assignedRole") {
      setAssignedRole(value);
      setModal(true);
    }
    return;
  }

  // Handle secondary modal button (No)
  function handleCancel(e) {
    setAssignedRole(userDetail.role.name);
    setModal(false);
  }

  const handleFetchRoleOptions = async () => {
    setLoading(true);
    const result = await getAllRoles();
    if (result && result.data) {
      setRoleOptions(result.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    handleFetchRoleOptions();
  }, []);

  useEffect(() => {
    if (user_id) {
      handleFetchUserDetail(user_id);
    }
  }, [user_id]);

  return (
    <div>
      <HeaderComponent />
      <SidebarInteralComponent />
      <SpinnerComponent isShown={isLoading} right="20px" top="20px" />
      {userDetail ? (
        <OuterContainer>
          <InnerContainer>
            <Row>
              <BackNavigation onClick={() => navigate("/internal/users")}>
                <img
                  src={arrow_left_icon_dark}
                  alt="arrow_left_icon_dark"
                  height={15}
                  width={15}
                  style={{
                    objectFit: "scale-down",
                    objectPosition: "left bottom",
                  }}
                />
                {`    Back`}
              </BackNavigation>
            </Row>
            <PageTitle>User details</PageTitle>
            <Row>
              <Col>
                <MentorProfileContainer>
                  <MentorProfilePicture
                    src="https://bit.ly/3ailCFB"
                    alt="mentor"
                  />
                  <MentorIdentification>
                    {userDetail && userDetail.user_details ? (
                      <MentorName>
                        {userDetail.user_details.first_name}{" "}
                        {userDetail.user_details.last_name}
                      </MentorName>
                    ) : null}
                    <MentorIdTitle>User ID</MentorIdTitle>
                    <MentorId>{userDetail.user_ccs_id}</MentorId>
                  </MentorIdentification>
                </MentorProfileContainer>
              </Col>
              <Col>
                <InputContainer>
                  <InputLabel>Role/Position</InputLabel>
                  <CustomDropdown
                    value={
                      (assignedRole && assignedRole.name) ||
                      (userDetail && userDetail.role && userDetail.role.name) ||
                      ""
                    }
                    placeholder="Choose one role/position"
                    options={roleOptions}
                    selectedKey="name"
                    action={(value) =>
                      handleChangeDropdown(value, "assignedRole")
                    }
                    inputWidth="250px"
                    itemWidth="250px"
                  />
                </InputContainer>
              </Col>
            </Row>
            <MentorInvisibleLine />
            <FormMentor>
              <Row>
                <Col>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>Gender</InputLabel>
                      <RadioButton
                        disabled={true}
                        name="gender"
                        data={["male", "female"]}
                        state={
                          (userDetail &&
                            userDetail.user_details &&
                            userDetail.user_details.gender) ||
                          ""
                        }
                      />
                    </InputContainer>
                  </FlexRow>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>First Name</InputLabel>
                      <InputMentor
                        disabled={true}
                        placeholder="Input first name here"
                        {...register("first_name", {
                          required: "Please input your first name",
                          value:
                            (userDetail &&
                              userDetail.user_details &&
                              userDetail.user_details.first_name) ||
                            "",
                        })}
                      />
                    </InputContainer>
                    <InputContainer>
                      <InputLabel>Last Name</InputLabel>
                      <InputMentor
                        disabled={true}
                        placeholder="Input last name here"
                        {...register("last_name", {
                          required: "Please input your last name",
                          value:
                            (userDetail &&
                              userDetail.user_details &&
                              userDetail.user_details.last_name) ||
                            "",
                        })}
                      />
                    </InputContainer>
                  </FlexRow>
                  <InputContainer>
                    <InputLabel>Email Address</InputLabel>
                    <InputMentor
                      disabled={true}
                      placeholder="e.g: ccs@mail.com"
                      {...register("email", {
                        required: "Please input your email address",
                        value: (userDetail && userDetail.email) || "",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message:
                            "email format is invalid, please try again..",
                        },
                      })}
                    />
                  </InputContainer>
                  <InputContainer>
                    <InputLabel>Phone Number</InputLabel>
                    <InputMentor
                      disabled={true}
                      {...register("phone_number", {
                        required: "Please input your phone number",
                        value:
                          (userDetail &&
                            userDetail.user_details &&
                            userDetail.user_details.phone_number) ||
                          "",
                      })}
                    />
                  </InputContainer>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>Place of Birth</InputLabel>
                      <InputMentor
                        disabled={true}
                        placeholder="Place of Birth"
                        {...register("place_of_birth", {
                          required: "Please input your place of birth",
                          value:
                            (userDetail &&
                              userDetail.user_details &&
                              userDetail.user_details.place_of_birth) ||
                            "",
                        })}
                      />
                    </InputContainer>
                    <InputContainer>
                      <InputLabel>Date of Birth</InputLabel>
                      <ReactDatePicker
                        disabled={true}
                        date={
                          (userDetail &&
                            userDetail.user_details &&
                            userDetail.user_details.date_of_birth &&
                            new Date(userDetail.user_details.date_of_birth)) ||
                          null
                        }
                      />
                    </InputContainer>
                  </FlexRow>
                  <InputContainer>
                    <InputLabel>Company/Organization</InputLabel>
                    <InputMentor
                      placeholder="Company / Organization"
                      disabled={true}
                      {...register("company_name", {
                        required: "Please input your company",
                        value:
                          (userDetail &&
                            userDetail.user_details &&
                            userDetail.user_details.company_name) ||
                          "",
                      })}
                    />
                  </InputContainer>
                </Col>
                <Col>
                  <InputContainer>
                    <InputLabel>Address</InputLabel>
                    <TextAreaMentor
                      placeholder="Address"
                      disabled={true}
                      {...register("address", {
                        required: "Please input your address",
                        value:
                          (userDetail &&
                            userDetail.user_details &&
                            userDetail.user_details.address) ||
                          "",
                      })}
                    />
                  </InputContainer>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>Country</InputLabel>
                      <QuickFilterDropdown
                        isDisabled={true}
                        options={[]}
                        placeholder="Country"
                        state={
                          (userDetail &&
                            userDetail.user_details &&
                            userDetail.user_details.country &&
                            userDetail.user_details.country.name) ||
                          ""
                        }
                      />
                    </InputContainer>
                    <InputContainer>
                      <InputLabel>State/Province</InputLabel>
                      <InputMentor
                        disabled={true}
                        placeholder="State / Province"
                        {...register("province", {
                          required: "Please input your state/province",
                          value:
                            (userDetail &&
                              userDetail.user_details &&
                              userDetail.user_details.province) ||
                            "",
                        })}
                      />
                    </InputContainer>
                  </FlexRow>
                  <FlexRow>
                    <InputContainer>
                      <InputLabel>City</InputLabel>
                      <InputMentor
                        disabled={true}
                        placeholder="City"
                        {...register("city", {
                          required: "Please input your city",
                          value:
                            (userDetail &&
                              userDetail.user_details &&
                              userDetail.user_details.city) ||
                            "",
                        })}
                      />
                    </InputContainer>
                    <InputContainer>
                      <InputLabel>Postal Code</InputLabel>
                      <InputMentor
                        disabled={true}
                        placeholder="Postal code"
                        {...register("postal_code", {
                          required: "Please input your postal code",
                          value:
                            (userDetail &&
                              userDetail.user_details &&
                              userDetail.user_details.postal_code) ||
                            "",
                        })}
                      />
                    </InputContainer>
                  </FlexRow>
                </Col>
              </Row>
              <ModalComponent
                show={isModal}
                body={[
                  <p style={{ fontWeight: "700" }} key={0}>
                    Do you want to save changes?
                  </p>,
                ]}
                buttonPrimary="Yes"
                buttonSecondary="No"
                onPrimaryClick={() => handleChangeUserRole()}
                onSecondaryClick={handleCancel}
                handleClose={() => setModal(false)}
              />
            </FormMentor>
          </InnerContainer>
        </OuterContainer>
      ) : null}
    </div>
  );
};

export default InternalUserDetail;

const OuterContainer = styled.div`
  padding-left: 350px;
  text-align: left;
`;

const InnerContainer = styled.div`
  padding: 130px 3vw 30px 3vw;
`;

const BackNavigation = styled.p`
  font-size: 16px;
  color: #000000;
  cursor: pointer;
`;

const PageTitle = styled.h1`
  font-size: 36px;
  color: #00bb99;
  margin-bottom: 1em;
`;

const MentorProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
`;

const MentorProfilePicture = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
`;

const MentorIdentification = styled.div`
  margin-left: 1em;
`;

const MentorName = styled.p`
  margin-bottom: 0.3em;
  font-weight: 700;
  font-size: 25px;
`;

const MentorIdTitle = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  color: #00bb99;
`;

const MentorId = styled.p`
  font-size: 14px;
  font-weight: 500;
`;

const MentorInvisibleLine = styled.hr`
  opacity: 0.2;
  margin-top: 0px;
  padding: 2px;
  min-width: 900px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? flexDirection : "row"};
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "normal"};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "none")};
  flex-wrap: ${({ flexWrap }) => (flexWrap ? flexWrap : "nowrap")};
`;

const FormMentor = styled.form``;

const InputContainer = styled.div`
  width: -webkit-fill-available; // Important
  margin-right: 2em;
  margin-top: 1em;
  flex-grow: 1; // Important
`;

const InputLabel = styled.p`
  font-weight: 700;
  margin-bottom: 0.3em;
`;

const InputMentor = styled.input`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  height: 50px;
  padding: 0.5em;
  width: 100%;
  &:disabled {
    opacity: 0.5;
    background: rgba(0, 187, 153, 0.1);
  }
`;

const TextAreaMentor = styled.textarea`
  /* background: rgba(0, 187, 153, 0.1); */
  border: 2px solid #00bb99;
  border-radius: 10px;
  min-height: 235px;
  width: 100%;
  padding: 0.5em;
  &:disabled {
    opacity: 0.5;
    background: rgba(0, 187, 153, 0.1);
  }
`;
